import { Component, TemplateRef } from '@angular/core';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent {
  cartDetails:any=[];
currentDate: any;
totalTicketsCost: any;
Enter_Code: any;
mailOTP: any;
data:any={};
walletDetails:any;
button ='Enable'
  isLoading: boolean=false
  form: any;
  workmail: any;
  disable: boolean = false;
  tickets: any;
  
  enableForm = new FormGroup({
    otp: new FormControl(null, [Validators.required, Validators.maxLength(6), Validators.pattern(/^((\\+91-?)|0)?[0-9]{6}$/)])
  });

constructor(private callApi:UserService,private router: Router,private dialog:MatDialog){}

ngOnInit(){
  this.getProfile()
  this.getWalletDet()
  this.getCartDetails()
}

keyPressNumbers(event: any) {
  var charCode = (event.which) ? event.which : event.keyCode;
  if ((charCode < 48 || charCode > 57)) {
    event.preventDefault();
    return false;
  } else {
    return true;
  }
}
getProfile(){
  this.callApi.getProfile().subscribe((res:any) => {
    if(res.success){
      this.data = res.data.userDetails[0]  
    }
    else{
      this.callApi.showError(res.message)
    }
  },
  (error) => {
    console.error("error in get user profile:", error);
    
  }
) 
}

getWalletDet(){
  let payload = {}
  this.callApi.getWallet(payload).subscribe((res:any) => {
    if(res.success){
      this.walletDetails = res.data.data      
    }
  })
}

getCoinDet(detail:any){
  let payload = {
    coin_id:detail._id
  }
  this.callApi.createWallet(payload).subscribe((res:any) => {
    console.log(res,"res")
  })
   
}
backEnable(type: any) {
  if (type == 'Google') {
    console.log(this.enableForm.value.otp);
    
    let payload={
      ...this.enableForm.value,
      status:"2"
    }
   
    this.callApi.checkTfa(payload).subscribe(async (res: any) => {
      if (res.success == true) {
        this.dialog.closeAll();
        this.callApi.showSuccess(await (res.message))
        this.getProfile();
      }
      else {
        this.callApi.showError(await (res.message))
      }
    }, err => {
      this.callApi.serverError();
    })
  }
  if (type == 'Email') {
    let payload = {
      status :"2",
      twofa_otp: this.mailOTP
    }
    this.callApi.disablemailauthentication(payload).subscribe(async (res: any) => {
      if (res.success == true) {
        this.dialog.closeAll();
        this.callApi.showSuccess(await (res.message))
        this.getProfile();
      }
      else {
        this.callApi.showError(await (res.message))
      }
    }, err => {
      this.callApi.serverError();
    })
  }
}
enable(templateRef: TemplateRef<any>, data: any) {
  if (data == 'google') {
    console.log(templateRef);
    
  this.workmail = 'Google'
  this.dialog.open(templateRef, {
    panelClass: 'disable',
    disableClose:true,
    width: '100%',
    height: 'auto',
    minWidth: 'auto',
    maxWidth: '30rem',
    data: data,
  })
}
if (data == 'Email') {
  this.callApi.emailAuthentication().subscribe(async (res: any) => {
    if (res.success == true) {
      this.callApi.showSuccess(await (res.message))
      this.callApi.stopSpinner();
    }
    else {
      this.callApi.showError(await (res.message))
      this.callApi.stopSpinner();
    }
  }, err => {
    this.callApi.serverError();
    this.callApi.stopSpinner();
  })
  this.workmail = 'Email'
  this.dialog.open(templateRef, {
    panelClass: 'disable',
    disableClose:true,
    height: 'auto',
    width: '35%',
    data: data
  })
}
}
getEmailOtp(){
  this.button ='Processing'
    this.isLoading = true
  this.callApi.emailAuthentication().subscribe( (res:any) => {
    if (res.success === true) {
      this.callApi.showSuccess (res.message)
      this.router.navigate(['/emailotp'])
      this.isLoading = false
      this.form.patchValue({email:res?.data?.email})
    }else{
      this.callApi.showError (res.message)
    }
  },err => {
    this.callApi.serverError();
  })
}

getCartDetails(){
this.callApi.getMyLotteryDet({}).subscribe((res:any) => {
  if(res.success){
    this.cartDetails = res.data.slice(0,3)
  }
})
}

}
