import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
// import { SelectService } from 'src/app/service/translate/select.service';
// import { UserService } from 'src/app/service/user.service';
import Swal from 'sweetalert2';
import translate from 'translate';
import { UserService } from '../services/user.service';
import { SelectService } from '../services/translate/select.service';

@Component({
  selector: 'app-crypto-withdraw',
  templateUrl: './crypto-withdraw.component.html',
  styleUrls: ['./crypto-withdraw.component.scss']
})
export class CryptoWithdrawComponent implements OnInit {
  @ViewChild('otpChangeContent') otpChangeContent: ElementRef | any;
  @ViewChild('GoogleAuthenticate') GoogleAuthenticate: ElementRef | any;
  @ViewChild('address_id') address_id: ElementRef | any;

  coindata: any;
  coinId: any = "";
  kyc: any;
  kyStatus: boolean = false;
  public translateControl: any;
  walletData: any;
  fiatId: boolean = false;
  currencyImg: any;
  balanceof: any;
  total: any;
  depositMin: any;
  value: any;
  form: any;
  submitted: boolean = false;
  Address!: string;
  Amount!: string;
  isSubmited: boolean = false;
  otp_form: any;
  user_tfa: any;
  twofaDialog: any;
  myform: any;
  percentSubmitted: boolean = false;
  Enter_2FA_code!: string;
  formData: any;
  coinGetData: any;
  searchText: String = '';
  search_que: any;
  timeCheck: any;
  loadingTrigger: boolean = false
  showOtp: boolean = true
  passwordCheck: boolean = false;
  otpSubmit: boolean = false
  loading: boolean = false
  details: any;
  showCoin: boolean = false
  tokenId: any;
  addressList: any;
  isIconClicked: boolean = false;
  add: string = '';
  tokenNetwork: any = '';
  tokenNetworkSlug: any = ""
  coinResp: any;
  tokenDetails: any;
  coin_type: any;
  isLoading = false;
  button = 'Submit';
  details_list: any;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private callApi: UserService,
    private dialog: MatDialog,
    public selectService: SelectService,
    private route: ActivatedRoute,) { }
  ngOnInit(): void {
    this.getProfile();
    this.route.queryParams.subscribe(async (queryParams: any) => {
      if (queryParams.id) {
        this.coinId = queryParams.id ? queryParams.id : this.coinId
        this.showCoin = true

        await this.getWalletDetailsWithId(this.coinId)
        await this.getAddressList(queryParams.id)
      }
    })

    this.callApi.tfaStatus().subscribe((res: any) => {
      this.user_tfa = res.data
    })
    this.wallet()
    this.createForm();

    if (this.coinResp) {
    }

    this.selectService.getSharedData().subscribe(data => {
      this.translateControl = data
      if (this.translateControl == 'es') {
        this.Address = 'DIRECCIÓN',
          this.Amount = 'Cantidad',
          this.Enter_2FA_code = 'Ingrese el código 2FA',
          this.search_que = "Que estás buscando?"
      } else if (this.translateControl == 'fr') {
        this.Address = 'Adresse',
          this.Amount = 'Montant',
          this.Enter_2FA_code = 'Entrez le code 2FA',
          this.search_que = "Que cherchez-vous?"
      } else {
        this.Address = 'Address',
          this.Amount = 'Amount',
          this.Enter_2FA_code = 'Enter 2FA code',
          this.search_que = "What are you searching for?"
      }
    })
  }
  coinlist(event: any) {
    this.coinId = event.target.value
    this.getWalletDetailsWithId(this.coinId)

  }
  wallet() {
    this.callApi.getTradeHistoryList().subscribe((res: any) => {
      this.coindata = res?.data?.coin
    })
  }
  filterData() {
    let payload = {
      coin_type: this.coin_type
    }
    this.callApi.getWallet2({ slug: this.searchText?.toUpperCase(), }, 1).subscribe((res: any) => {
      this.coindata = res?.data?.data
    })
  }


  otpValueCheck(event: any) {
    this.checkTimeOut()
  }
  checkTimeOut() {
    if (!this.otp?.value) {
      this.timeCheck = setTimeout(() => {
        this.loadingTrigger = false
        this.showOtp = false
        this.passwordCheck = false
        this.otp?.reset()
      }, 300000)
    } else {
      clearTimeout(this.timeCheck)
    }
  }
  getProfile() {
    this.callApi.startSpinner();
    this.callApi.getProfile().subscribe(async (res: any) => {
      this.callApi.stopSpinner();
      this.details = res?.data?.kycDetails
      this.kyc = res?.data?.userDeatils

      if (this.kyc?.kyc_verify == 2) {
        this.kyStatus = true
        // this.getWallet(1);
      }
      if (this.kyc?.kyc_verify == 0) {
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('Oops...', this.translateControl),
          text: await translate('Please submit Your Kyc!', this.translateControl),
          footer: `
          <html>  
          <style>
            .swal2-footer {
              border-top: 0;
              margin-top: 0;
            }
            </style>        
          <a href="kyc-user" class="link-warning fs-13">Submit your Kyc?</a>
          </html>`
        })

      }
      else if (this.kyc?.kyc_verify == 1) {
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('Please wait!', this.translateControl),
          text: await translate('Your KYC is waiting for admin approval.', this.translateControl),
          footer: `
          <html>
          <style>
            .swal2-footer {
              border-top: 0;
              margin-top: 0;
            }
            </style>
          <a href="kyc-user" class="link-warning fs-13">View Submitted KYC Details</a>
          </html>`,
        })
      }
      else if (this.kyc?.kyc_verify == 3) {
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('KYC Rejected!', this.translateControl),
          text: await translate('Your KYC has been Rejected. Please contact our helpdesk.', this.translateControl),
          footer: `
          <html>
          <style>
          .swal2-footer {
            border-top: 0;
            margin-top: 0;
          }
          </style>
          <a href="kyc-user" class="link-warning fs-13">Resubmit Kyc?</a>
          </html>`,
        })
      }

    }, async err => {
      this.callApi.stopSpinner();

      Swal.fire({
        icon: 'error',
        title: await translate('Oops...', this.translateControl),
        text: await translate('Some error occurred. Please try again later! For more info contact our helpdesk!', this.translateControl)
      }).then(() => {
        this.router.navigate(['/dashboard'])
      })
    })

  }
  getWalletDetailsWithId(coinId: any) {

    this.callApi.getWalletDetailsWithId({ coin_id: coinId }).subscribe((res: any) => {
      if (res.success == true) {
        this.walletData = res?.data
        this.coinResp = this.walletData?.walletDetails?.coin_id
        this.tokenDetails = this.walletData?.walletDetails?.wallet_details
        this.callApi.stopSpinner();
      }
      this.callApi.stopSpinner();
      if (res?.data?.fiatDeposit == true) {
        this.fiatId = true
        // this.getBankList();
      } else {
        this.callApi.stopSpinner();
        this.fiatId = false
      }
      this.details_list = res.data?.walletDetails?.coins[0]?.coin_type
      console.log("🚀 ~ CryptoWithdrawComponent ~ this.callApi.getWalletDetailsWithId ~   this.details:", this.details)
      this.currencyImg = res.data?.walletDetails?.coins[0]?.image
      this.balanceof = res.data?.walletDetails
      this.total = (this.balanceof?.balance + this.balanceof?.escrow_balance)
      // this.form.patchValue({ coin_id: this.balanceof?.coin_id })
      this.depositMin = res.data?.walletDetails?.coins[0]
      if (this.walletData?.walletDetails?.token) {
        this.value = this.walletData?.walletDetails?.wallet_details[0].wallet_address
      } else {
        this.value = res.data?.walletDetails?.wallet_address
      }
      this.callApi.stopSpinner();
    }, err => {
      this.callApi.stopSpinner();
    })
  }

  get f() { return this.form.controls; }
  createForm() {
    this.form = this.fb.group({
      coin_id: [],
      to_address: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      tokenNetwork: ['']
    })
  }

  submit() {
    this.button = 'Processing';
    this.isLoading = true;
    this.submitted = true
    this.formData = this.form?.value
    let payload = {
      amount: this.formData?.amount,
      coin_id: this.coinResp,
      to_address: this.formData?.to_address,
      tokenNetwork: this.formData?.tokenNetwork,
      tfa: 1,
      status: 3
    }

    this.callApi.withdrawReq(payload).subscribe(async (res: any) => {
      this.button = 'Submit';
      this.isLoading = false;
      if (res.success == true) {
        if (res?.data?.tfa == 1) {
          if (this.kyc.authentic_status == "1") {
            this.callApi.showSuccess(await translate(res.message, this.translateControl))
            this.loadingTrigger = true
            this.dialog.open(this.GoogleAuthenticate, {
              width: '100%',
              height: 'auto',
              minWidth: 'auto',
              maxWidth: '30rem',
              disableClose: true
            })
            this.passwordCheck = false
            this.loadingTrigger = false
            this.checkTimeOut()
          }

          else if (this.kyc.authentic_status == "2") {
            this.callApi.showSuccess(await translate(res.message, this.translateControl))
            this.loadingTrigger = true
            this.dialog.open(this.otpChangeContent, {
              width: '100%',
              height: 'auto',
              minWidth: 'auto',
              maxWidth: '30rem',
              disableClose: true
            })
            this.passwordCheck = false
            this.loadingTrigger = false
            this.checkTimeOut()
          }


        }
        else {
          this.callApi.showError(await translate(res.message, this.translateControl))

        }

      }
      else {
        this.callApi.showError(await translate(res.message, this.translateControl))
        this.button = 'Submit';
        this.isLoading = false;
        this.form.reset();

      }


    }, err => {
      this.callApi.serverError();
    })

  }
  emailAuthentication() {
    this.callApi.emailAuthentication().subscribe(async (res: any) => {
      this.callApi.showInfo(await translate(res?.message, this.translateControl))
    })
  }
  keyPressNumbers(event: any) {
    this.percentSubmitted = false;
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 46 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  get formVerify() { return this.otp_form.controls; }

  verifyOTP() {
    this.isSubmited = true;
    if (this.otp_form.valid) {
      let payload = {
        status: "3",
        ...this.otp_form.value
      }
      this.callApi.checkTfa(payload).subscribe(async (res: any) => {
        if (res.success) {
          this.twofaDialog.close();
          this.submitWithdraw();
        } else {
          this.callApi.showError(await translate(res.message, this.translateControl));
          this.isSubmited = false;

        }
      })
    }
  }

  otp = new FormControl('', [Validators.required, Validators.pattern(/^\w{0,6}$/)])

  userverifyOTP() {
    this.otpSubmit = true
    if (this.otp?.valid) {
      this.loadingTrigger = true
      let payload = {
        amount: this.formData?.amount,
        coin_id: this.coinResp,
        to_address: this.formData?.to_address,
        tokenNetwork: this.formData?.tokenNetwork,
        tfa: 1,
        otp: this.otp?.value,
      }
      this.callApi.withdrawReq(payload).subscribe(async (res: any) => {
        if (res?.success) {
          this.callApi.showSuccess(await translate(res?.message, this.translateControl))
          this.dialog.closeAll()
          this.showOtp = false
          this.otpSubmit = false
          this.otp?.reset()
          this.form.reset()

        } else {
          this.callApi.showError(await translate(res?.message, this.translateControl))

        }
      })
    }

  }


  userverifyAuthenticator() {
    this.isSubmited = true;
    if (this.otp.valid) {
      this.loadingTrigger = true
      let payload = {
        amount: this.formData?.amount,
        coin_id: this.coinResp,
        to_address: this.formData?.to_address,
        tokenNetwork: this.formData?.tokenNetwork,
        tfa: 1,
        otp: this.otp?.value,
        status: 3
      }
      this.callApi.withdrawReq(payload).subscribe(async (res: any) => {
        if (res?.success == true) {
          this.callApi.showSuccess(await translate(res?.message, this.translateControl))
          this.dialog.closeAll()
          this.showOtp = false
          this.otpSubmit = false
          this.otp?.reset()
          this.form.reset()


        } else {
          this.callApi.showError(await translate(res?.message, this.translateControl))
          this.isSubmited = false;

        }
      })
    }

  }


  emailTfaVerify() {
    this.isSubmited = true;
    if (this.otp_form.valid) {
      let payload = {
        twofa_otp: this.otp_form.get('otp').value,
        status: "3"
      }

      this.callApi.otpEmailVerification(payload).subscribe(async (res: any) => {
        if (res.success) {
          this.twofaDialog.close();
          // this.submitWithdraw();
        } else {
          this.callApi.showError(await translate(res.message, this.translateControl));
          this.isSubmited = false;
        }
      })
    }
  }
  submitWithdraw() {
    this.formData = this.form?.value
    let payload = {
      amount: this.formData?.amount,
      coin_id: this.coinId,
      to_address: this.formData?.to_address,
      tokenNetwork: this.formData?.tokenNetwork,
      tfa: 1
    }
    this.callApi.withdrawReq(payload).subscribe(async (res: any) => {
      this.callApi.stopSpinner();
      if (res.success == true) {
        this.callApi.showSuccess(await translate(res.message, this.translateControl))
        this.form.reset();
        this.submitted = false;
        this.dialog.closeAll();
      }
      else {
        this.callApi.showError(await translate(res.message, this.translateControl))
      }

    }, err => {
      this.callApi.serverError();
    })

  }
  logOut() {
    this.twofaDialog.close();
  }
  open2fa() {
    this.router.navigate(['/profile'])
  }
  openCryptoWithdraw(template: TemplateRef<any>) {
    this.dialog.open(template, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "30rem",
      maxHeight: "55vh"
    })
  }
  coinSelect(data: any, type: any) {
    if (type == 1) {
      this.coinGetData = data
      this.dialog.closeAll()
      this.getWalletDetailsWithId(data?._id)
    }

  }
  imageUrl(image: any) {
    return image.includes("https://firebee.sgp1.digitaloceanspaces.com/") ? image : `https://firebee.sgp1.digitaloceanspaces.com/${image}`;
  }

  openPopup(tempref: TemplateRef<any>) {
    this.dialog.open(tempref, {
      width: "100%",
      height: "auto",
      minWidth: 'auto',
      maxWidth: '45rem'
    });
    this.getAddressList("")
    // this.dialog.closeAll()

  }

  getAddress(token: any) {
    this.isIconClicked = true
    if (token) {
      this.add = token?.wallet_address
    }
    setTimeout(() => {
      this.address_id.nativeElement.click()
    }, 1000)

  }


  getAddressList(data: any) {
    let datas;
    if (this.details_list == 1) {
      datas = {
        coin_id: this.coinResp ? this.coinResp : data
      }
    }
    else {
      datas = {
        coin_id: this.coinResp ? this.coinResp : data,
        tokenNetwork: this.tokenNetworkSlug ? this.tokenNetworkSlug : this.tokenNetwork
      }
    }
    this.callApi.tokenAddressList(datas.coin_id, datas).subscribe((res: any) => {
      this.addressList = res?.data
    })


  }
  addwithdrawAddressBook() {
    this.dialog.closeAll()
    this.router.navigateByUrl('/withdraw-book')
  }

  token_changes(event: any) {
    this.tokenNetworkSlug = event.target.value
  }
}
