<div class="bg-orange login-bg">
    <div class="container">
        <div class="shadow bg-color">
            <div class="row align-items-center border border-white m-auto">
                <!-- Header Section -->
                <div class="col-md-5 text-center order-2 order-md-1 p-4">
                    <h1 class="text-center justify-content-center pb-3 mb-0 title">If you want <span class="text-theme-1">More
                            Luck,</span> take more chances</h1>
                    <img src="./assets/img/addus_logo_white.png" alt="logo" width="200" class="bg-filter ">
                    <p class="mt-4 mb-0">{{"business@firebeetechnoservices.com"}}</p>
                </div>

                <!-- Form Section -->
                <div class="col-md-7 p-0 order-1 order-md-2">
                    <form [formGroup]="loginForm" >
                        <div class="card border-0 p-3 bg-white">
                            <!-- <div class="d-none d-md-block text-end">
                                <a href="\index.html" target="_self">
                                    <i class="fa-solid fa-xmark" style="font-size: 30px;"></i>
                                </a>
                            </div> -->
                            <div class="card-body">
                                <h2 class="card-title text-start">Sign-in</h2>
                                <div class="text-start">
                                    <label>Email</label>
                                    <div>
                                        <input type="email" class="form-control p-2 mb-3" formControlName="email" id="email"
                                            name="email" placeholder="Email">
                                        <div
                                            *ngIf="isSubmitted && loginForm.controls.email.invalid">
                                            <p *ngIf="loginForm.controls.email?.errors?.required" class="text-danger">*
                                                 Email or Username Field is Required</p>
                                            <p *ngIf="loginForm.controls.email?.errors?.pattern" class="text-danger">
                                                Enter
                                                Valid Email</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="text-start mt-2">
                                    <label>Password</label>
                                    <div>
                                        <input type="password" class="form-control p-2 mb-3" formControlName="password"
                                            id="password" name="password" placeholder="Password">
                                        <div
                                            *ngIf="isSubmitted  && loginForm.controls.password.invalid">
                                            <p *ngIf="loginForm.controls.password?.errors?.required"
                                                class="text-danger">
                                                *Password field is Required</p>
                                            <p *ngIf="loginForm.controls.password?.errors?.pattern" class="text-danger">
                                                * Password must include at least one uppercase letter, one lowercase
                                                letter,
                                                one number, and one special character.</p>
                                        </div>
                                    </div>
                                    <div class="password__show">
                                        <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword" [class.hide]="!showPassword"></i>
                                        <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword" [class.hide]="showPassword"></i>
                                    </div>
                                </div> -->
                                
                                <div class="text-start mt-2">
                                    <label for="password">Password</label>
                                    <div class="icon-password">
                                        <input type="password"
                                               class="form-control p-2 mb-3"
                                               formControlName="password"
                                               id="password"
                                               name="password"
                                               [type]="showPassword ? 'text' : 'password'"
                                               placeholder="Password">
                                        <div *ngIf="isSubmitted && loginForm.controls.password.invalid">
                                            <p *ngIf="loginForm.controls.password?.errors?.required" class="text-danger">
                                                *Password field is required
                                            </p>
                                            <p *ngIf="loginForm.controls.password?.errors?.pattern" class="text-danger">
                                                *Password must include at least one uppercase letter, one lowercase letter,
                                                one number, and one special character.
                                            </p>
                                        </div>
                                        <div class="password__show">
                                            <i class="fa" 
                                               [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}" 
                                               (click)="showPassword = !showPassword" 
                                               aria-label="Toggle password visibility">
                                            </i>
                                        </div>

                                    </div>
                                  
                                </div>
                                

                                <div class="button text-center mt-4">
                                    <div>
                                        <button type="submit" class="submit btn btn-warning p-2"
                                            (click)="onSubmit()">Login</button>
                                    </div>
                                    <div class="mt-2">
                                        <p class="text-secondary-emphasis mb-0">Don't have an account? <span
                                                class="text-theme-1 fw-bold button_1" routerLink="/register">Sign
                                                Up</span></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


            </div>
        </div>
    </div>
</div>