import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SelectService } from '../services/translate/select.service';

@Component({
  selector: 'app-cryptodeposit',
  templateUrl: './cryptodeposit.component.html',
  styleUrls: ['./cryptodeposit.component.scss']
})
export class CryptodepositComponent implements OnInit {
  fiatId: boolean = false;
  coinId: any = "";
  walletData: any;
  currencyImg: any;
  balanceof: any;
  total: any;
  depositMin: any;
  value: any;
  copyTxt: any = 'Copy'
  public translateControl: any;
  coindata: any;
  searchText: String = '';
  coinGetData: any;
  showCoin: boolean = false
  coin_type: any;
  search: string = '';
  walletList: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private callApi: UserService,
    private dialog: MatDialog,
    public selectService: SelectService,
    private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.selectService.getSharedData().subscribe(data => {
      this.translateControl = data

    })
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams.id) {
        this.coinId = queryParams.id ? queryParams.id : this.coinId
        this.showCoin = true;
      }
    })
    this.getWalletDetailsWithId(this.coinId)
    this.wallet()
  }
  coinlist(event: any) {
    this.coinId = event.target.value
    this.getWalletDetailsWithId(this.coinId)
  }
  wallet() {
    this.callApi.getTradeHistoryList().subscribe((res: any) => {
      this.coindata = res?.data?.coin
    })
  }

  filterData() {
    let payload = {
      coin_type: this.coin_type
    }
    this.callApi.getWallet2({ slug: this.searchText?.toUpperCase(), }, 1).subscribe((res: any) => {
      this.coindata = res?.data?.data
    })
  }

  clearText() {
    this.search = ''
    // this.getWallet({}, 1);
  }


  getWalletDetailsWithId(data: any) {
    this.callApi.getWalletDetailsWithId({ coin_id: data }).subscribe((res: any) => {
      if (res.success == true) {
        this.walletData = res?.data
        this.callApi.stopSpinner();
      }
      this.callApi.stopSpinner();
      if (res?.data?.fiatDeposit == true) {
        this.fiatId = true
        // this.getBankList();
      } else {
        this.callApi.stopSpinner();
        this.fiatId = false
      }
      this.currencyImg = res.data?.walletDetails?.coins[0]?.image
      this.balanceof = res.data?.walletDetails
      this.total = (this.balanceof?.balance + this.balanceof?.escrow_balance)
      // this.form.patchValue({ coin_id: this.balanceof?.coin_id })
      this.depositMin = res.data?.walletDetails?.coins[0]
      if (this.walletData?.walletDetails?.token) {
        this.value = this.walletData?.walletDetails?.wallet_details[0].wallet_address
      } else {
        this.value = res.data?.walletDetails?.wallet_address
      }
      this.callApi.stopSpinner();
    }, err => {
      this.callApi.stopSpinner();
    })
  }

  copyText(val: string) {
    if (val) {
      this.copyTxt = 'Copied!';
      setTimeout(() => {
        this.copyTxt = 'Copy';
      }, 3000);
    }
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

  }
  openCryptoDeposit(template: TemplateRef<any>) {
    this.dialog.open(template, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "30rem",
      maxHeight: "55vh"
    })
  }
  coinSelect(data: any, type: any) {
    if (type == 1) {
      this.coinGetData = data
      this.dialog.closeAll()
      this.getWalletDetailsWithId(data?._id)
      this.createAddress(data?._id)
    }
  }
  createAddress(id: any) {
    this.callApi.startSpinner();
    this.callApi.createAddress({ coin_id: id }).subscribe((res: any) => {

      if (res.success == true) {
        this.getWalletDetailsWithId(id)
        this.callApi.stopSpinner();
      }
      else {
        this.callApi.stopSpinner();
      }
    }, err => {
      this.callApi.serverError();
    })
    // 0x51C325d420bDB6b75E9D432245E76141E2405478 - repeated address
  }
  imageUrl(image: any) {
    return image.includes("https://firebee.sgp1.digitaloceanspaces.com/") ? image : `https://firebee.sgp1.digitaloceanspaces.com/${image}`;
  }

}
