import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Router, RouterEvent } from '@angular/router';
import { UserService } from '../../services/user.service';

export function maxDateValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const today = new Date();
    const inputDate = new Date(control.value);
    if (control.value == null || inputDate <= today) {
      return null;
    }
    return { 'maxDate': { value: control.value } };
  };
}
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})

export class WelcomeComponent implements OnInit {
  LotteryForm!: FormGroup;
  ticketDrawDet: any;
  userDet: any;
  today: any
  isSubmitted: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    public callApi: UserService
  ) { }

  ngOnInit(): void {
    this.getActiveTicketDraw()
    this.LotteryForm = this.formBuilder.group({
      Date: ['', [Validators.required, maxDateValidator()]],
      ticketNumber: ['', Validators.required]
    });
    this.today = new Date();
  }

  get controls() {
    return this.LotteryForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    let form = this.LotteryForm.value
    if (this.LotteryForm.valid) {
      let payload = {
        searchDate: form.Date,
        searchNum: form.ticketNumber
      }
      this.callApi.getTicketDraw(payload).subscribe((res: any) => {
        if (res.success) {
          this.ticketDrawDet = res?.data
          this.LotteryForm.reset();
          this.isSubmitted = false;
        } else {
          this.callApi.showError(res.message)
        }
      })
    } else {
      let dateTiketNum = this.controls['Date']?.value && this.controls['ticketNumber']?.valid      
       if (!dateTiketNum) {
        this.callApi.showError('Kindly fill all mandatary fields')
      }else{
        this.callApi.showError(' The date must not exceed today.')
      }
    }
  }

  getUserDetail() {

  }

  getActiveTicketDraw() {
    this.callApi.getTicketDraw({}).subscribe((res: any) => {
      if (res.success) {
        this.ticketDrawDet = res?.data
      } else {
        this.callApi.showError(res.message)
      }
    })
  }

  buyNow(id: any) {
    // const formattedSaleDate = formatDate(new Date(saleDate),'YYYY-MM-DD','en-US')
    this.callApi.getUserDet().subscribe((res:any) => {
      if(res.success){
        let payload={id:id}
        this.callApi.getTickectDate(payload).subscribe((response:any) => {
          if(response.success){
            this.router.navigate(['/viewticket'], { queryParams: { id: id } })  
          }else{
            this.callApi.showError(response.message)
          }
         
        })      
      }else{
        this.callApi.showError(res.message)
        this.router.navigate(['/kyc-sub'])
      }

    })
  }


 
}