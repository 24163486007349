<section class="mt-4 mt-lg-0">
    <div class="container">
        <h2 class="text-theme-1 mt-3">Check your price and fill this form to claim your price</h2>
        <legend class="fw-bold mb-0">Ticket Details</legend>
        <div class="row py-3">
            <!-- <form [formGroup]="claimForm">
                <div class="d-flex">
                    <div class="col-md-6 col-12 m-2">
                        <fieldset>
                            <div class="mb-3">
                                <label for="exampleInputNumber" class="form-label">Type Your Order ID</label>
                                <input type="text" id="exampleInputNumber" class="form-control bg-white"
                                    placeholder="Order ID" formControlName="Order_ID">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Order_ID.touched || claimForm.controls.Order_ID.dirty && claimForm.controls.OrderID.invalid)">
                                    <p *ngIf="claimForm.controls.Order_ID?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="selectDate" class="form-label">Choose Your Draw Date</label>
                                <input type="date" placeholder="Draw Date" name="draw_date"
                                    class="form-control bg-white" id="draw_date" value="" max="2024-03-21" required="" formControlName="Date">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Date.touched || claimForm.controls.Date.dirty && claimForm.controls.Date.invalid)">
                                    <p *ngIf="claimForm.controls.Date?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledSelect" class="form-label">Choose Ticket</label>
                                <select id="disabledSelect" class="form-select bg-white" formControlName="Draw_date">
                                    <option>Wednesday Draw</option>
                                </select>
                                <div
                                    *ngIf="isSubmitted || (claimForm.controls.Draw_date.touched || claimForm.controls.Draw_date.dirty && claimForm.controls.Draw_date.invalid)">
                                    <p *ngIf="claimForm.controls.Draw_date?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">Enter Ticket Number</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="Enter Ticket Number" formControlName="Ticket_Num">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Ticket_Num.touched || claimForm.controls.Ticket_Num.dirty && claimForm.controls.Ticket_Num.invalid)">
                                    <p *ngIf="claimForm.controls.Ticket_Num?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">Mobile Number</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="Mobile Number" formControlName="phone_no" minlength="10">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.phone_no.touched || claimForm.controls.phone_no.dirty && claimForm.controls.phone_no.invalid)">
                                    <p *ngIf="claimForm.controls.phone_no?.errors?.required" class="text-danger">
                                        *Required</p>
                                        <p *ngIf="claimForm.controls.phone_no?.errors?.minlength" class="text-danger">
                                            maximum Length should be 10</p>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div class="col-md-6 col-12 m-2">
                        <fieldset>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">Account Number</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="Account Number" formControlName="Acc_No">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Acc_No.touched || claimForm.controls.Acc_No.dirty && claimForm.controls.Acc_No.invalid)">
                                    <p *ngIf="claimForm.controls.Acc_No?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">Branch</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="Branch" formControlName="Branch">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Branch.touched || claimForm.controls.Branch.dirty && claimForm.controls.Branch.invalid)">
                                    <p *ngIf="claimForm.controls.Branch?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">IFSC Code</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="IFSC" formControlName="Ifsc">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Ifsc.touched || claimForm.controls.Ifsc.dirty && claimForm.controls.Ifsc.invalid)">
                                    <p *ngIf="claimForm.controls.Ifsc?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">Account Holder's Name</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="Account Holder name" formControlName="Holder_Name">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Holder_Name.touched || claimForm.controls.Holder_Name.dirty && claimForm.controls.Holder_Name.invalid)">
                                    <p *ngIf="claimForm.controls.Holder_Name?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>
                            <div class="mb-3">
                                <label for="disabledTextInput" class="form-label">UPI ID</label>
                                <input type="text" id="number" name="number" class="form-control bg-white"
                                    placeholder="UPI ID" formControlName="Upi_No">
                                    <div
                                    *ngIf="isSubmitted || (claimForm.controls.Upi_No.touched || claimForm.controls.Upi_No.dirty && claimForm.controls.Upi_No.invalid)">
                                    <p *ngIf="claimForm.controls.Upi_No?.errors?.required" class="text-danger">
                                        *Required</p>
                                </div>
                            </div>

                        </fieldset>
                    </div>
                </div>
            </form> -->
            <form [formGroup]="claimForm">
                <div class="container-fluid">
                    <div class="row">
                        <!-- First Column -->
                        <div class="col-md-6 col-12 mb-3">
                            <fieldset>
                                <div class="mb-3">
                                    <label for="exampleInputNumber" class="form-label">Type Your Order ID</label>
                                    <input type="text" id="exampleInputNumber" class="form-control bg-white" placeholder="Order ID" formControlName="Order_ID">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Order_ID.touched || claimForm.controls.Order_ID.dirty && claimForm.controls.Order_ID.invalid)">
                                        <p *ngIf="claimForm.controls.Order_ID?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="draw_date" class="form-label">Choose Your Draw Date</label>
                                    <input type="date" id="draw_date" placeholder="Draw Date" class="form-control bg-white" formControlName="Date" max="2024-03-21" required>
                                    <div *ngIf="isSubmitted || (claimForm.controls.Date.touched || claimForm.controls.Date.dirty && claimForm.controls.Date.invalid)">
                                        <p *ngIf="claimForm.controls.Date?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="disabledSelect" class="form-label">Choose Ticket</label>
                                    <select id="disabledSelect" class="form-select bg-white" formControlName="Draw_date">
                                        <option>Wednesday Draw</option>
                                    </select>
                                    <div *ngIf="isSubmitted || (claimForm.controls.Draw_date.touched || claimForm.controls.Draw_date.dirty && claimForm.controls.Draw_date.invalid)">
                                        <p *ngIf="claimForm.controls.Draw_date?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="ticket_number" class="form-label">Enter Ticket Number</label>
                                    <input type="text" id="ticket_number" class="form-control bg-white" placeholder="Enter Ticket Number" formControlName="Ticket_Num">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Ticket_Num.touched || claimForm.controls.Ticket_Num.dirty && claimForm.controls.Ticket_Num.invalid)">
                                        <p *ngIf="claimForm.controls.Ticket_Num?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="phone_no" class="form-label">Mobile Number</label>
                                    <input type="text" id="phone_no" class="form-control bg-white" placeholder="Mobile Number" formControlName="phone_no" minlength="10">
                                    <div *ngIf="isSubmitted || (claimForm.controls.phone_no.touched || claimForm.controls.phone_no.dirty && claimForm.controls.phone_no.invalid)">
                                        <p *ngIf="claimForm.controls.phone_no?.errors?.required" class="text-danger">*Required</p>
                                        <p *ngIf="claimForm.controls.phone_no?.errors?.minlength" class="text-danger">Maximum Length should be 10</p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
            
                        <!-- Second Column -->
                        <div class="col-md-6 col-12 mb-3">
                            <fieldset>
                                <div class="mb-3">
                                    <label for="acc_no" class="form-label">Account Number</label>
                                    <input type="text" id="acc_no" class="form-control bg-white" placeholder="Account Number" formControlName="Acc_No">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Acc_No.touched || claimForm.controls.Acc_No.dirty && claimForm.controls.Acc_No.invalid)">
                                        <p *ngIf="claimForm.controls.Acc_No?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="branch" class="form-label">Branch</label>
                                    <input type="text" id="branch" class="form-control bg-white" placeholder="Branch" formControlName="Branch">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Branch.touched || claimForm.controls.Branch.dirty && claimForm.controls.Branch.invalid)">
                                        <p *ngIf="claimForm.controls.Branch?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="ifsc" class="form-label">IFSC Code</label>
                                    <input type="text" id="ifsc" class="form-control bg-white" placeholder="IFSC" formControlName="Ifsc">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Ifsc.touched || claimForm.controls.Ifsc.dirty && claimForm.controls.Ifsc.invalid)">
                                        <p *ngIf="claimForm.controls.Ifsc?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="holder_name" class="form-label">Account Holder's Name</label>
                                    <input type="text" id="holder_name" class="form-control bg-white" placeholder="Account Holder Name" formControlName="Holder_Name">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Holder_Name.touched || claimForm.controls.Holder_Name.dirty && claimForm.controls.Holder_Name.invalid)">
                                        <p *ngIf="claimForm.controls.Holder_Name?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <label for="upi_no" class="form-label">UPI ID</label>
                                    <input type="text" id="upi_no" class="form-control bg-white" placeholder="UPI ID" formControlName="Upi_No">
                                    <div *ngIf="isSubmitted || (claimForm.controls.Upi_No.touched || claimForm.controls.Upi_No.dirty && claimForm.controls.Upi_No.invalid)">
                                        <p *ngIf="claimForm.controls.Upi_No?.errors?.required" class="text-danger">*Required</p>
                                    </div>
                                </div>
                            </fieldset>
                        </div>
                    </div>
                </div>
            </form>
            
        </div>
        <div class="text-center">
            <button type="button" class="btn-1 btn btn-primary btn-lg border-0">Submit</button>
        </div>

    </div>

</section>