<section class="container wallet-sec">
    <h4>Wallet Balance</h4>
    <!-- <div class="row">
        <div class="col-lg-7">
            <div class="wallet-bg-img mt-4">
                <div class="mt-4">
                    <h6>{{'Total Balance' }}</h6>
                    <h3>{{coinValues?.totalBalance}} USD</h3>
                </div>
                <div class="mt-4">
                    <h6>{{'Estimated Value'}}</h6>
                    <h5>{{coinValues?.btcValue | tofixed}} BTC</h5>
                </div>
                <div class="text-end">
                    <a routerLink="/history" class="wallet-white-btn w-btn ms-3 text-decoration-none">'History'</a>
                </div>

            </div>
            <div class="row pt-3 px-0">
                <div class="col-md-6 ps-lg-0">
                    <div class="night-bg pnl-card rounded-2 h-100 d-flex align-items-center justify-content-around">
                        <div class="text-center">
                            <h4>{{'24H PNL'}}</h4>
                                <span class="fw-bold"
                                [ngClass]="day_pnl_percentage < 0 ? 'value_ticker text-danger' : 'value_ticker text-success'">&nbsp;&nbsp;
                                {{day_pnl_percentage.toFixed(2)? day_pnl_percentage.toFixed(2) :0
                                }}%</span>
                        </div>
                        <div class="text-center">
                            <h4>{{'30D PNL'}}</h4>
                            <span class="fw-bold"
                            [ngClass]="mnth_pnl_percentage < 0 ? 'value_ticker text-danger' : 'value_ticker text-success'">&nbsp;&nbsp;
                            {{mnth_pnl_percentage.toFixed(2)? mnth_pnl_percentage.toFixed(2):0
                            }}%</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 pe-lg-0 pt-3 pt-md-0">
                    <div class="night-bg pnl-card rounded-2 h-100">
                        <div class="text-center">
                            <h4>{{'Total Rewards'}}</h4>
                            <p class="mb-0">{{earn}} USD</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-5 ">
            <div class="chart-height night-bg mt-4 rounded-2">
                <div *ngIf="totalBalance == 0" class="d-flex my-auto wallet_card_body">
                    <p>{{'Account is empty, Please click'}} <a
                            class="user_deposit" routerLink="/wallet">HERE</a> {{'to Deposit'}}
                    </p>
                </div>
                <div class="chart-box" *ngIf="kyc?.kyc_verify==2 && totalBalance !== 0">
                    <div class="pt-4">
                    </div>
                </div>
                <ul
                    class="mt-3 mb-0 pb-1 text-align-items-center px-2 d-flex align-items-center justify-content-around flex-wrap">
                    <li *ngFor="let coins of coinPer.slice(0,5) , index as i"
                        class="d-flex align-items-center mb-2 ms-2">
                        <span [ngStyle]="{'background-color': color[i] }" class="colorcode"></span><span
                            class="ms-2">{{coins?.name}}</span> <span class="ms-2">{{(coins?.y / totalBalance *
                            100).toFixed(2) }}%</span>
                    </li>

                </ul>
            </div>
        </div>
    </div> -->
    <div class="row mt-5 justify-content-end">
        <div class="col-lg-6">
            <nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                    <button class="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home"
                        type="button" role="tab" aria-controls="nav-home" aria-selected="true">Coin View</button>
                    <!-- <button class="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile"
                        type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Wallet View</button> -->
                </div>
            </nav>
        </div>

        <div class="col-lg-6 px-lg-0">
            <div class="input-group mb-3 wallet-searchbox position-relative">
                <input class="form-control wallet-search" (keypress)="alphabets($event)" [(ngModel)]="search"
                    type="text" name="search" autocomplete="off" placeholder="Search" (keyup)="filterData()"
                    id="searchbar">
                <button type="submit" *ngIf="search == ''" class="fa fa-search btn-blog"></button>
                <button type="button" *ngIf="search != ''" (click)="clearText()"
                    class="fa-solid fa-circle-xmark btn-blog"></button>
            </div>
        </div>
    </div>

    <div class="tab-content pt-4" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab"
            tabindex="0">
            <div class="night-bg p-4 rounded-2 table-responsive">
                <table class="table table-fixed">
                    <thead>
                        <tr>
                            <th class="fw-bold">Coin</th>
                            <th class="fw-bold">Name</th>
                            <th class="fw-bold">Total balanceof?
                            </th>
                            <th class="fw-bold">Available Balance</th>
                            <th class="fw-bold">Escrow</th>
                            <th class="fw-bold">PNL</th>
                            <th class="fw-bold">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let wall of wallet">
                            <td>
                                <div class="">
                                    <img src="{{imagePath+wall.image}}" class="wallet_coin_img">
                                </div>
                            </td>
                            <td>{{wall?.slug}}<span *ngIf="wall.coin_type == 1"> </span>
                                <span *ngIf="wall.coin_type == 3"></span>
                            </td>
                            <td>{{(wall?.balance)|tofixed}}
                            </td>
                            <td>{{(wall?.total_balance)|tofixed}}</td>
                            <td>{{(wall?.escrow_balance)|tofixed}}</td>
                            <td>
                                <button (click)="getCoinPnlHistory(wall?._id,wall?.image,wall?.slug)"
                                    class="btn_theme-primary">{{'view'}}</button>
                            </td>
                            <td>
                                <div class="d-flex align-items-center">
                                    <button class="btn_theme-primary" *ngIf="wall?.coin_type == 0"
                                        routerLink="/fiat-deposit" [queryParams]="{id : wall?._id}"
                                        (click)="createDepositAddress(wall?._id)">Deposit</button>
                                    <button class="btn_theme-primary"
                                        *ngIf="wall?.coin_type == 1 || wall?.coin_type == 3 "
                                        routerLink="/crypto-deposit" (click)="createWalletAddress(wall?._id)"
                                        [queryParams]="{id : wall?._id}">Deposit</button>
                                    <button class="btn_theme-primary ms-2" *ngIf="wall?.coin_type == 0"
                                        routerLink="/fiat-withdraw" [queryParams]="{id : wall?._id}">Withdraw</button>
                                    <button class="btn_theme-primary ms-2"
                                        *ngIf="wall?.coin_type == 1 || wall?.coin_type == 3"
                                        routerLink="/crypto-withdraw" [queryParams]="{id : wall?._id}">Withdraw</button>
                                </div>
                            </td>
                            <td>
                                <div class="text-center" *ngIf="wallet?.length == 0 || wallet == undefined">{{'--Wallet Not Found !--'}}</div>
                            </td>
                        </tr>
                        <tr class="mx-auto py-3 notfound">
                            <td colspan="12" class="text-center">
                                <span *ngIf="wallet?.length == 0">--No Records Found ! --</span>
                                <span *ngIf="wallet == undefined">Loading...</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- <div [hidden]="page_history?.totalPage<=10">
                <mat-paginator #paginatorTrade="matPaginator" [length]="page_history?.totalPage "
                    [pageSizeOptions]="[10]" (page)="WalletHandlePageEvent($event)" aria-label="Select page">
                </mat-paginator>
            </div> -->
        </div>
        <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
            <div class="night-bg p-4 rounded-2 table-responsive">
                <table class="table table-fixed">
                    <thead>
                        <tr>
                            <th>{{'Wallet'}}</th>
                            <th>{{'Amount'}}</th>
                            <th>{{'Coin'}}</th>
                            <th>{{'Action'}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{'Spot'}}</td>
                            <td>{{coinValues?.totalBalance}}</td>
                            <td>USD</td>
                            <td>
                                <!-- <button routerLink="/trade" class="button-secondary w-btn">{{'Trade'}}</button> -->
                                <a routerLink="/trade" class="button-secondary w-btn text-center">Trade</a>
                            </td>
                        </tr>
                        <tr>
                            <td>{{'Earn'}}</td>
                            <td>{{earn}}</td>
                            <td>USD</td>
                            <td>
                                <!-- <button routerLink="/referral-commission" class="button-secondary w-btn">{{'Earn'}}</button> -->
                                <a routerLink="/referral-commission"
                                    class="button-secondary w-btn text-center">Earn</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
<!-- popup Start -->

<ng-template #walletcoindetails>
    <section *ngIf="depositMin?.coin_type == 1 || depositMin?.coin_type == 3" class="position-relative history-box">
        <button mat-icon-button class="close-button float-end" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <h5 class="mb-0 p-2">
            <img src="{{imagePath+currencyImg}}" class="wallet_coin_img">
            <span class="wallet_coin_title_span">depositMin?.slug | uppercase</span>
        </h5>
        <div class="p-3">
            <div class="bal-details-grp d-sm-flex justify-content-between shadow mt-3 rounded-2 p-2">
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="total_coin_div">
                        <p class="total_coin_p text-white">{{'Total'}}</p>
                        <p class="total_coin_amount">{{(balanceof?.balance + balanceof?.escrow_balance)|tofixed}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="available_balance_div">
                        <p class="available_coin_p text-white">Available Balance</p>
                        <p class="available_coin_amount">{{balanceof?.balance|tofixed}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="escrow_balance_div">
                        <p class="escrow_coin_p text-white">{{'Escrow'}}</p>
                        <p class="escrow_coin_amount">{{balanceof?.escrow_balance|tofixed}}</p>
                    </div>
                </div>
            </div>
            <mat-tab-group animationDuration="1000ms">
                <mat-tab [label]="deposit" class="me-2 mb-2 mb-sm-0">
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==1">
                        <div class="row">
                            <div class="col-lg-12">
                                <label class="wallet_label" *ngIf="!fiatId" class="wallet_label">Wallet Address</label>
                            </div>

                            <div class="col-lg-6">
                                <div class="input-group wallet-form mb-2">
                                    <select name="" id="" *ngIf="walletData?.walletDetails?.token"
                                        class="form-control select-grp" (change)="networkChange($event.target)">
                                        <option [value]="data?.network"
                                            *ngFor="let data of walletData?.walletDetails?.wallet_details">
                                            {{data?.network | uppercase }}
                                        </option>
                                    </select>
                                </div>
                                <div class="input-group wallet-form">
                                    <input type="text" *ngIf="!fiatId" name="addres" readonly [(ngModel)]="value"
                                        class="form-control" aria-label="Recipient's username"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" *ngIf="!fiatId">
                                        <button (click)="copyText(value)"
                                            class="button-secondary h-100 shadow-none rounded-3 border-0 ms-2 p-2"
                                            type="button">{{copyTxt }}</button>
                                    </div>
                                </div>
                                <div class="qr_code_div">
                                    <qr-code [value]='value' class="val_Qr" size="150" errorCorrectionLevel="M">
                                    </qr-code>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-sm-4 px-0" *ngIf="!fiatId">
                                <div class="wallet_content mt-2">
                                    <p class="wallet_content_p">Minimum Deposit Limit :
                                        {{depositMin?.min_deposit_limit}} depositMin?.slug </p>
                                    <p class="wallet_content_p mb-1">Maximum Deposit Limit :
                                        {{depositMin?.max_deposit_limit}} depositMin?.slug 
                                    </p>
                                    <p class="wallet_content_p mb-1 mt-2">Deposit Fee : {{depositMin?.deposit_fee|tofixed}}%
                                        {{depositMin?.slug | uppercase }}
                                    </p>
                                    <p class="wallet_content_p note_content">{{'NOTE : Deposit may take from a few
                                        minutes to over 30 minutes.'}}</p>
                                    <p>To see deposit history for this coin / token,<a routerLink="/deposit-history" class="Wallet_a_link">Click Here</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==0">
                        <div
                            class="d-flex justify-content-center align-items-center deposit-disable alert alert-danger p-3 mx-3">
                            <p class="mb-0 me-3" *ngIf="depositMin?.coin_type ==1"><i
                                    class="fas fa-exclamation-circle"></i> 'Deposits for this Coin is temporarily
                                disabled</p>
                            <p class="mb-0 me-3" *ngIf="depositMin?.coin_type ==3"><i
                                    class="fas fa-exclamation-circle"></i> 'Deposits for this Token is temporarily
                                disabled</p>

                        </div>
                    </div>
                </mat-tab>
                <mat-tab [label]="withdraw" class="ms-2">
                    <div class="deposite_hole_div" *ngIf="kyc?.authentic_status==0 && kyc?.twofaActiveStatus == 0">
                        <div class="d-flex justify-content-center align-items-center deposit-disable text-center h-300">
                            <div class="mt-4">
                                <h4 class="mb-0 me-3">Please Enable 2FA
                                </h4>
                                <div class="mt-2">
                                    <button class="button-secondary" (click)="open2fa()">Click Here</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="kyc?.authentic_status == 1 || kyc?.authentic_status == 2 || kyc?.twofaActiveStatus == 2"
                        class="p-3">
                        <div class="row" *ngIf="depositMin.withdraw_status ==1">
                            <div class="col-lg-6 mt-3">
                                <div>
                                    <h5 class="fw-semibold my-3">Payment Method</h5>
                                </div>
                                <form [formGroup]="form" *ngIf="!fiatId">
                                    <div class="form-group">
                                        <label for="exampleInputEmail1" class="wallet_address_label">To Address</label>
                                        <div class="input-group wallet-form mb-2">
                                            <select name="" id="" formControlName="tokenNetwork"
                                                *ngIf="walletData?.walletDetails?.token" class="form-control">
                                                <option [value]="data?.network"
                                                    *ngFor="let data of walletData?.walletDetails?.wallet_details">
                                                    {{data?.network | uppercase }}
                                                </option>
                                            </select>
                                            <a class="nav-link count-indicator px-0" id="countDropdown"
                                                (click)="openPopup(listAddress)" data-bs-toggle="dropdown"
                                                aria-expanded="false">
                                            </a>
                                        </div>
                                        <input name="amt" formControlName="to_address"
                                            [ngClass]="{'is-invalid': submitted && f['to_address'].errors}" type="text"
                                            class="form-control" id="exampleInputPassword1" [(ngModel)]="add"
                                            placeholder="Address">
                                        <svg (click)="openPopup(listAddress)" xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16" fill="currentColor"
                                            class="float-end class_icon cursor-pointer bi bi-book" viewBox="0 0 16 16">
                                            <path
                                                d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                        </svg>
                                        <div *ngIf="submitted && f['to_address'].errors" class="invalid-feedback">
                                            <div class="align" *ngIf="f['to_address'].errors?.['required']">'Required Address'
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <label for="exampleInputPassword1" class="wallet_address_label">Amount</label>
                                        <input type="text" name="amt" appCopypaste appDecimalrestrict
                                            formControlName="amount"
                                            [ngClass]="{'is-invalid' : submitted && f['amount'].errors}" type="text"
                                            class="form-control" id="exampleInputPassword1" placeholder="Amount">
                                        <div *ngIf="f['amount'].errors" class="invalid-feedback">
                                            <div class="align" *ngIf="f['amount'].errors?.['required']">
                                                {{' Required Amount'}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="example-label-container mt-3">
                                        <label id="example-name-label" class="example-name-label">Percentage</label>
                                        <label class="example-value-label float-end">rangevalue%</label>
                                    </div>
                                    <mat-slider (change)="selectPercentage($event)" class="example-margin w-100"
                                        max="100" min="0">
                                        <input matSliderThumb [(ngModel)]="rangevalue"
                                            [ngModelOptions]="{standalone: true}">
                                    </mat-slider>
                                    <div class="text-center mt-4 mb-3">
                                        <button type="button" class="button-secondary btn-sm border-0"
                                            (click)="submit(twoFa)" [disabled]="myform?.invalid">
                                            <ng-container *ngIf="myform?.invalid">Fill the Fields!
                                            </ng-container>
                                            <ng-container *ngIf="!myform?.invalid">Submit</ng-container>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-6 mt-4">
                                <div class="wallet_content mt-2" *ngIf="!fiatId">
                                    <p class="wallet_content_p">Minimum Withdraw Limit :
                                        {{depositMin?.min_withdraw_limit}}
                                        {{depositMin?.slug |
                                        uppercase }}</p>
                                    <p class="wallet_content_p">Maximum Withdraw Limit : {{depositMin?.max_withdraw_limit}}
                                        {{depositMin?.slug |
                                        uppercase }}</p>
                                    <p class="wallet_content_p">{{'Withdraw Fee'}}
                                        : {{depositMin?.withdraw_fee|tofixed}}%
                                        {{depositMin?.slug |
                                        uppercase }}</p>
                                    <p class="wallet_content_p note_content">NOTE : Withdraw may take from a few minutes to over 30 minutes.</p>
                                </div>
                            </div>
                        </div>
                        <div class="deposite_hole_div" *ngIf="depositMin.withdraw_status ==0">
                            <div
                                class="d-flex justify-content-center align-items-center deposit-disable alert alert-danger p-3 mx-3">
                                <p class=" mb-0 me-3" *ngIf="depositMin.coin_type == 1"><i
                                        class="fas fa-exclamation-circle"></i> Withdraw for this Coin is temporarily disabled </p>
                                <p class=" mb-0 me-3" *ngIf="depositMin.coin_type == 3"><i
                                        class="fas fa-exclamation-circle"></i> Withdraw for this Token is temporarily disabled </p>

                            </div>
                        </div>
                    </div>
                </mat-tab>
            </mat-tab-group>
            <mat-sidenav-container class="example-container">
                <mat-sidenav-content>
                    <!-- <a class="d-inline-block button-secondary shadow-none" (click)="mynav.toggle()" (click)="getCoinHistory()">{{'Historybvvdfnnf'}}</a> -->
                </mat-sidenav-content>
                <mat-sidenav #mynav mode="over" opened="false" position="end" fixedInViewport="true"
                    class="example-sidenav">
                    <button mat-icon-button class="position-absolute end-0" (click)="mynav.toggle()">
                        <mat-icon class="close-icon" disabledClose="true">close</mat-icon></button>
                    <div class="p-3">
                        <div *ngFor="let wall of wallet">
                            <h5 class="">
                                <img src="{{wall?.image}}" class="wallet_coin_img">
                                <span class="wallet_coin_title_span">{{wall?.slug | uppercase}}</span>
                            </h5>
                        </div>
                        <div class="border-0 p-2 bg-dark text-white rounded">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="mt-3">
                                    <p><span class="fw-bold">24h PNL :</span>&nbsp;&nbsp;{{((allHistory?.PNL?.pnl?allHistory?.PNL?.pnl:0) |
                                        tofixed )}}</p>
                                    <p class="mb-0"><span class="fw-bold">24h PNL (%) :</span>
                                        <span class="fw-bold"
                                            [ngClass]="allHistory?.PNL?.percentage < 0 ? 'value_ticker text-danger' : 'value_ticker text-success'">&nbsp;&nbsp;{{((allHistory?.PNL?.percentage?allHistory?.PNL?.percentage:0)|tofixed
                                            )}}</span>
                                    </p>
                                </div>
                                <div class="trade_btn navigate-arrow" type="button" routerLink="/trade">
                                    <img src="assets/img/right.png" alt="arrow" class="" height="12px" width="12px">
                                </div>
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="d-flex justify-content-between">
                                <p class="fw-bold">{{'Available Balance :'}}</p>
                                <p class="fw-bold pe-4">
                                    {{((allHistory?.PNL?.avaiableBalance?allHistory?.PNL?.avaiableBalance:0))}}</p>

                            </div>
                        </div>
                        <div class="pt-3">
                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="btn_theme-primary py-1 px-2 active" id="all-details-tab"
                                        data-bs-toggle="pill" data-bs-target="#all-details" type="button" role="tab"
                                        aria-controls="all-details" aria-selected="true">All</button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="btn_theme-primary py-1 px-2 ms-2" id="deposit-details-tab"
                                        data-bs-toggle="pill" data-bs-target="#deposit-details" type="button" role="tab"
                                        aria-controls="deposit-details" aria-selected="false"
                                        (click)="getDepositHistory()">Deposit</button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="btn_theme-primary py-1 px-2 ms-2" id="withdraw-details-tab"
                                        data-bs-toggle="pill" data-bs-target="#withdraw-details" type="button"
                                        role="tab" aria-controls="withdraw-details" aria-selected="false"
                                        (click)="getWithdrawHistory()">Withdraw</button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="btn_theme-primary py-1 px-2 ms-2" id="buy-details-tab"
                                        data-bs-toggle="pill" data-bs-target="#buy-details" type="button" role="tab"
                                        aria-controls="buy-details" aria-selected="false"
                                        (click)="getBuyHistory()">Buy</button>
                                </li>
                                <li class="nav-item mb-2" role="presentation">
                                    <button class="btn_theme-primary py-1 px-2 ms-2" id="sell-details-tab"
                                        data-bs-toggle="pill" data-bs-target="#sell-details" type="button" role="tab"
                                        aria-controls="sell-details" aria-selected="false"
                                        (click)="getSellHistory()">Sell</button>
                                </li>
                            </ul>
                            <div class="tab-content rounded" id="pills-tabContent">
                                <div class="tab-pane fade show active scroler" id="all-details" role="tabpanel"
                                    aria-labelledby="all-details-tab" tabindex="0">
                                    <table class="table sitetable table-responsive-stack">
                                        <thead>
                                            <tr class="sticky-top">
                                                <th class="ps-1"> {{'History'}}
                                                </th>
                                                <th class=""> {{'Amount'}} </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-bold history-list">
                                            <tr *ngFor="let user of allHistory?.Result">
                                                <td>
                                                    <ng-container>
                                                        <span *ngIf="user.type == 3">Transfer</span>
                                                    </ng-container>
                                                    <ng-container *ngIf="user.type != 3">(user.type)</ng-container>
                                                    <!-- {{(element.createdAt).tz(settingsData?.timeZone).format('lll')}} -->
                                                    <br> <span
                                                        class="date-span">{{(user?.createdAt).tz(settingsData?.timeZone).format('lll')}}</span>
                                                </td>
                                                <td>{{(user.quantity?user.quantity:0)|tofixed }} {{user.slug}}<p
                                                        class="date-span"><img src="../../../assets/img/check .png"
                                                            class="img-size" alt="Tick Logo"> Completed</p>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td *ngIf="allHistory?.Result?.length == 0" colspan="2">--No Records
                                                    Found !--</td>
                                                <td *ngIf="allHistory == null">Loading...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade scroler" id="deposit-details" role="tabpanel"
                                    aria-labelledby="deposit-details-tab" tabindex="0">
                                    <table class="table sitetable table-responsive-stack">
                                        <thead>
                                            <tr class="sticky-top">
                                                <th class="ps-1"> {{'History'}}
                                                </th>
                                                <th> {{'Amount'}} </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-bold">
                                            <tr *ngFor="let user of depositHistory">
                                                <td>(user.type)<br><span
                                                        class="date-span">{{(user?.createdAt).tz(settingsData?.timeZone).format('lll')}}</span>
                                                </td>
                                                <td> {{(user.quantity?user.quantity:0) | tofixed}} {{user.slug}}<p
                                                        class="date-span"><img src="../../../assets/img/check .png"
                                                            class="img-size" alt="Tick Logo"> Completed</p>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td *ngIf="depositHistory?.length == 0" colspan="2">--No Records
                                                    Found !--</td>
                                                <td *ngIf="depositHistory == null">Loading...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade scroler" id="withdraw-details" role="tabpanel"
                                    aria-labelledby="withdraw-details-tab" tabindex="0">
                                    <table class="table sitetable table-responsive-stack">
                                        <thead>
                                            <tr class="sticky-top">
                                                <th class="ps-1"> {{'History'}}
                                                </th>
                                                <th> {{'Amount'}} </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-bold">
                                            <tr *ngFor="let user of withdrawHistory">
                                                <td>{{(user.type)}} <br><span
                                                        class="date-span">{{(user?.createdAt).tz(settingsData?.timeZone).format('lll')}}</span>
                                                </td>
                                                <td>{{(user.quantity?user.quantity:0) | tofixed}} {{user.slug}}<p
                                                        class="date-span"><img src="../../../assets/img/check .png"
                                                            class="img-size" alt="Tick Logo"> Completed</p>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td *ngIf="withdrawHistory?.length == 0" colspan="2">--No Records
                                                    Found !--</td>
                                                <td *ngIf="withdrawHistory == null">Loading...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade scroler" id="buy-details" role="tabpanel"
                                    aria-labelledby="buy-details-tab" tabindex="0">
                                    <table class="table sitetable table-responsive-stack">
                                        <thead>
                                            <tr class="sticky-top">
                                                <th class="ps-1"> {{'History'}}
                                                </th>
                                                <th> {{'Amount'}} </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-bold">
                                            <tr *ngFor="let user of buyHistory">
                                                <td>{{(user.type)}} <br> <span
                                                        class="date-span">{{(user?.createdAt).tz(settingsData?.timeZone).format('lll')}}</span>
                                                </td>
                                                <td>{{(user.quantity?user.quantity:0) | tofixed}} {{user.slug}}<p
                                                        class="date-span"><img src="../../../assets/img/check .png"
                                                            class="img-size" alt="Tick Logo">Wallet Balance Completed</p>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td *ngIf="buyHistory?.length == 0" colspan="2">--No Records Found
                                                    !--</td>
                                                <td *ngIf="buyHistory == null">Loading...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="tab-pane fade scroler" id="sell-details" role="tabpanel"
                                    aria-labelledby="sell-details-tab" tabindex="0">
                                    <table class="table sitetable table-responsive-stack">
                                        <thead>
                                            <tr class="sticky-top">
                                                <th class="ps-1"> {{'History'}}
                                                </th>
                                                <th> {{'Amount'}} </th>
                                            </tr>
                                        </thead>
                                        <tbody class="fw-bold">
                                            <tr *ngFor="let user of sellHistory">
                                                <td>{{(user.type)}} <br> <span
                                                        class="date-span">{{(user?.createdAt).tz(settingsData?.timeZone).format('lll')}}</span>
                                                </td>
                                                <td>{{(user.quantity?user.quantity:0) | tofixed}} {{user.slug}}<p
                                                        class="date-span"> <img src="../../../assets/img/check .png"
                                                            class="img-size" alt="Tick Logo"> CompletedCompleted</p>
                                                </td>
                                            </tr>
                                            <tr class="text-center">
                                                <td *ngIf="sellHistory?.length == 0" colspan="2">--No Records Found
                                                    !--</td>
                                                <td *ngIf="sellHistory == null">Loading...</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </mat-sidenav>
            </mat-sidenav-container>
        </div>
    </section>
    <!-- usd popup start -->

    <section *ngIf="depositMin?.coin_type=='0'" class="position-relative history-box">
        <button mat-icon-button class="close-button float-end" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <h5 class="mb-0 p-3">
            <img src="{{imagePath+currencyImg}}" class="wallet_coin_img">
            <span class="wallet_coin_title_span align-middle">{{depositMin?.slug | uppercase}}</span>
        </h5>
        <div class="p-3">
            <div class="bal-details-grp d-sm-flex justify-content-between shadow mt-3 rounded-2 p-2">
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="total_coin_div">
                        <p class="total_coin_p text-white">{{'Total'}}</p>
                        <p class="total_coin_amount">{{(balanceof?.balance + balanceof?.escrow_balance)|tofixed}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="available_balance_div">
                        <p class="available_coin_p text-white">Available Balance</p>
                        <p class="available_coin_amount">{{balanceof?.balance|tofixed}}</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <div class="escrow_balance_div">
                        <p class="escrow_coin_p text-white">{{'Escrow'}}</p>
                        <p class="escrow_coin_amount">{{balanceof?.escrow_balance|tofixed}}</p>
                    </div>
                </div>
            </div>
            <mat-tab-group animationDuration="1000ms">
                <mat-tab [label]="deposit" class="me-2">
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==1">
                        <div class="row">
                            <div class="col-lg-6">
                                <div>
                                    <div *ngIf="fiatId" class="d-md-flex d-lg-block d-xl-flex">
                                        <button class="payment-btn text-center" (click)="open(wiredTransfer)">{{'Payment
                                            Method' }}</button>
                                        <button class="payment-btn mx-md-3 mx-lg-0 mx-xl-3 text-center" *ngIf="fiatId"
                                            (click)="paypalPopup(paypal)">Paypal</button>
                                        <button class="payment-btn me-3 text-center" *ngIf="fiatId"
                                            (click)="StripePopup(stripe)">Stripe</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-4" *ngIf="fiatId">
                                <div class="wallet_content mt-3">
                                    <p class="wallet_content_p">Minimum Deposit Limit :
                                        {{depositMin?.min_deposit_limit}} {{depositMin?.slug |
                                        uppercase}} </p>
                                    <p class="wallet_content_p mb-1">Maximum Deposit Limit :
                                        {{depositMin?.max_deposit_limit}} {{depositMin?.slug |
                                        uppercase}}
                                    </p>
                                    <p class="wallet_content_p mb-1 mt-2">Deposit Fee : {{depositMin?.deposit_fee|tofixed}}%
                                        {{depositMin?.slug | uppercase}}
                                    </p>
                                    <p class="wallet_content_p note_content">NOTE : Deposit may take from a few minutes to over 30 minutes</p>
                                    <p>To see deposit history for this coin / token <a routerLink="/deposit-history" class="Wallet_a_link">Click Here</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==0">
                        <div class="d-flex justify-content-center align-items-center deposit-disable">
                            <h4 class="mb-0 me-3">Deposits for this Currency is Temporary Disabled</h4>
                            <img src="assets/img/block.png" alt="block" width="32px" height="32px">
                        </div>
                    </div>
                </mat-tab>
                <div class="deposite_hole_div tab-pane fade text-center" id="nav-withdraw" role="tabpanel"
                    aria-labelledby="nav-withdraw-tab">
                    <mat-tab [label]="withdraw" class="ms-2">
                        <div class="deposite_hole_div" *ngIf="kyc?.authentic_status==0 && kyc?.authentic_status == 0">
                            <div
                                class="d-flex justify-content-center align-items-center deposit-disable text-center h-300">
                                <div class="">
                                    <h4 class="mb-0 me-3">{{'Please Enable 2FA'}}
                                    </h4>
                                    <div class="mt-2">
                                        <button class="button-secondary" (click)="open2fa()">Click Here</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row"
                            *ngIf="kyc?.authentic_status == 1 || kyc?.authentic_status == 2 || kyc?.twofaActiveStatus == 2">
                            <div class="col-lg-6 mt-3" *ngIf="depositMin.withdraw_status ==1">
                                <div *ngIf="fiatId">
                                    <button class="payment-btn mx-sm-3 text-center" (click)="open(withDraw)">Payment
                                        Method</button>
                                </div>
                            </div>
                            <div class="col-lg-6 mt-2" *ngIf="fiatId && depositMin.withdraw_status ==1">
                                <div class="wallet_content mt-2">
                                    <p class="wallet_content_p">Minimum Withdraw Limit :
                                        {{depositMin?.min_withdraw_limit}}
                                        {{depositMin?.slug |
                                        uppercase}}</p>
                                    <p class="wallet_content_p">{{'Maximum Withdraw Limit :'| translate :
                                        translateControl | async}}
                                        {{depositMin?.max_withdraw_limit}}
                                        {{depositMin?.slug |
                                        uppercase}}</p>
                                    <p class="wallet_content_p">{{'Withdraw Fee :'| translate : translateControl |
                                        async}} {{depositMin?.withdraw_fee|tofixed}}%
                                        {{depositMin?.slug |
                                        uppercase}}</p>
                                    <p class="wallet_content_p note_content">{{'NOTE : Withdraw may take from a few
                                        minutes to over 30 minutes.'}}
                                    </p>
                                </div>
                                <div class="wallet_content">
                                    <p>{{'To see Withdraw history for this coin / token,'| translate : translateControl
                                        | async}} <a routerLink="/history" class="Wallet_a_link">{{'Click Here'|
                                            translate : translateControl | async}}</a></p>
                                </div>
                            </div>

                            <div class="deposite_hole_div" *ngIf="depositMin.withdraw_status ==0">
                                <div class="d-flex justify-content-center align-items-center deposit-disable">
                                    <h4 class="mb-0 me-3">{{'Withdraw for this Curremcy is Temporary Disabled'|
                                        translate : translateControl | async}}
                                    </h4>
                                    <img src="assets/img/block.png" alt="block" width="32px" height="32px">
                                </div>
                            </div>
                        </div>
                    </mat-tab>
                </div>
            </mat-tab-group>
        </div>
    </section>
    <!-- usd popup start -->
</ng-template>
<!-- deposit pop-up -->
<ng-template #wiredTransfer>
    <mat-dialog-content class="position-relative wal-pop-wdth-dep-grp">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <h2 class="fs-4">{{'Payment Method'}}</h2>
        <form (ngSubmit)="depositRequest()" #depositForm="ngForm" autocomplete="off">
            <div class="form-group">

                <div class="">
                    <h6 class="mt-3">{{'Choose Payment Method'}}</h6>
                    <div class="payment-tabbed align-items-start mt-2">

                        <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="btn_theme-primary shadow-none p-2 active me-2"
                                id="v-pills-wired-transfer-tab " data-bs-toggle="pill"
                                data-bs-target="#v-pills-wired-transfer" type="button" role="tab"
                                aria-controls="v-pills-wired-transfer" aria-selected="true" name="depositWiredTransfer"
                                (click)="setPaymentType(1)">{{'Wired Transfer'| translate : translateControl |
                                async}}</button>
                            <button class="btn_theme-primary shadow-none p-2" id="v-pills-profile-tab"
                                data-bs-toggle="pill" (click)="setPaymentType(2)" data-bs-target="#v-pills-profile"
                                type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false"
                                name="depositOnlineTransfer">{{'UPI Pay'| translate : translateControl |
                                async}}</button>

                        </div>
                        <div
                            *ngIf="(transaction_method==1 && userBanks?.bank_count<1)||(transaction_method==2 && userBanks?.upi_count<1) || (userBanks?.bank_count<1 && userBanks?.upi_count<1)">
                            <!-- <div class="row align-items-center"> -->
                            <div class="text-center mt-4 mt-lg-0">
                                <h5 class="mb-3">{{'Please Add Bank Account Details'| translate : translateControl |
                                    async}}</h5>
                                <button class="button-secondary" (click)="addbank()">{{'Click Here'| translate :
                                    translateControl | async}}</button>
                            </div>
                            <!-- <div class="col-lg-6 d-lg-block d-none">
                                    <img src="assets/img/add-bankdetails.png" class="img-fluid" alt="">
                                </div> -->
                            <!-- </div> -->
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel"
                                aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                                <div class="bank-check-grp text-start">
                                    <div _ngcontent-cag-c149="" class="mt-4 d-flex" *ngIf="userBanks?.bank_count>0"
                                        style="column-gap: 15px;">
                                        <div class="col">
                                            <div class="from-details h-100">
                                                <div>
                                                    <label class="label-text fw-bold mb-2">{{'Deposit From:'| translate
                                                        : translateControl | async}}</label><br>
                                                    <select class="wallet-select form-control "
                                                        (change)="changeBank($event, 'user', 'bank')">
                                                        <ng-container *ngFor="let bank of userBanks; let i = index">
                                                            <option [value]="i" *ngIf="bank.payment_method==1">{{
                                                                bank.account_no }} - {{ bank.bank_name | translate :
                                                                translateControl | async }}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>

                                                <div class="bank-detail-wallet" *ngIf="userBankInfo.account_no">
                                                    <ul>
                                                        <li><span>{{'Account Number'| translate : translateControl |
                                                                async}}</span> : <span>{{ userBankInfo.account_no
                                                                }}</span></li>
                                                        <li><span>{{'Account Name'| translate : translateControl |
                                                                async}}</span> : <span>{{ userBankInfo.account_name |
                                                                translate : translateControl | async }}</span></li>
                                                        <li><span>{{'Bank Name'| translate : translateControl |
                                                                async}}</span> : <span>{{ userBankInfo.bank_name |
                                                                translate : translateControl | async }}</span></li>
                                                        <li><span>{{'Account Type'| translate : translateControl |
                                                                async}}</span> : <span>{{ userBankInfo.account_type
                                                                }}</span></li>
                                                        <li><span>{{'IFSC Code'| translate : translateControl |
                                                                async}}</span> : <span>{{ userBankInfo.ifsc_code
                                                                }}</span></li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col mt-3 mt-lg-0">
                                            <div class="to-details h-100">
                                                <div>
                                                    <label class="label-text fw-bold mb-2">{{'Deposit To:'| translate :
                                                        translateControl | async}}</label><br>
                                                    <select class="wallet-select"
                                                        (change)="changeBank($event, 'admin', 'bank')">
                                                        <ng-container *ngFor="let bank of adminBanks; let i = index">
                                                            <option [value]="i" *ngIf="bank.payment_method==1">{{
                                                                bank.account_no }} - {{ bank.bank_name | translate :
                                                                translateControl | async}}</option>
                                                        </ng-container>
                                                    </select>
                                                </div>

                                                <div class="bank-detail-wallet" *ngIf="adminBankInfo.account_no">
                                                    <ul>
                                                        <li><span>{{'Account Number'| translate : translateControl |
                                                                async}}</span> : <span>{{ adminBankInfo.account_no
                                                                }}</span></li>
                                                        <li><span>{{'Account Name'| translate : translateControl |
                                                                async}}</span> : <span>{{ adminBankInfo.account_name |
                                                                translate : translateControl | async }}</span></li>
                                                        <li><span>{{'Bank Name'| translate : translateControl |
                                                                async}}</span> : <span>{{ adminBankInfo.bank_name |
                                                                translate : translateControl | async}}</span></li>
                                                        <li><span>{{'Account Type'| translate : translateControl |
                                                                async}}</span> : <span>{{ adminBankInfo.account_type
                                                                }}</span></li>
                                                        <li><span>{{'IFSC Code'| translate : translateControl |
                                                                async}}</span> : <span>{{ adminBankInfo.ifsc_code
                                                                }}</span></li>
                                                    </ul>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                aria-labelledby="v-pills-profile-tab" tabindex="0">
                                <div class="mt-4 d-flex bank-check-grp" *ngIf="userBanks?.upi_count>0"
                                    style="column-gap: 15px;">
                                    <div class="col">
                                        <div class="from-details h-100">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Deposit From:'| translate :
                                                    translateControl | async}}</label><br>
                                                <select class="wallet-select"
                                                    (change)="changeBank($event, 'upi', 'deposit')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank?.payment_method==2">{{
                                                            bank.payment_id }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="bank-detail-wallet" *ngIf="userUpiInfo?.payment_id">
                                                <ul>
                                                    <li><span>{{'UPI ID'| translate : translateControl |
                                                            async}}</span>:<span>{{ userUpiInfo?.payment_id }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col mt-3 mt-lg-0">
                                        <div class="to-details h-100">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Deposit To:'| translate :
                                                    translateControl | async}}</label><br>
                                                <select class="wallet-select"
                                                    (change)="changeBank($event, 'admin', 'upi')">
                                                    <ng-container *ngFor="let bank of adminBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank.payment_method==2">{{
                                                            bank.payment_id }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="bank-detail-wallet" *ngIf="adminUpiInfo?.payment_id">
                                                <ul>
                                                    <li><span>{{'UPI ID'| translate : translateControl |
                                                            async}}</span>:<span>{{ adminUpiInfo?.payment_id }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- choose payment -->
                </div>
                <div class="mt-4"
                    *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                    <div class="d-flex" style="column-gap: 15px;">
                        <div class="col">
                            <div class="">
                                <label class=" col-form-label">{{'Transaction ID:'| translate : translateControl |
                                    async}}</label>

                                <input [placeholder]="eti" name="transaction_id" [(ngModel)]="transactionId" type="text"
                                    #transaction="ngModel" required class="form-control border p-2 rounded-3"
                                    [ngClass]="{ 'is-invalid': amountSubmitted && transaction.errors }" appUpi
                                    pattern="[a-zA-Z0-9]*">

                                <div *ngIf="amountSubmitted" class="text-danger_1">
                                    <div *ngIf="transaction.errors?.['required']">{{'Transaction ID Required'| translate
                                        : translateControl | async}}</div>
                                </div>
                            </div>

                            <div class="mt-2">
                                <label class=" col-form-label">{{'Enter Amount:'| translate : translateControl |
                                    async}}</label>

                                <input type="hidden" name="transaction_method" [(ngModel)]="transaction_method" min="0">
                                <input min="0" max="1000" [placeholder]="ea" name="amount" [(ngModel)]="amount"
                                    type="number" #enteramount="ngModel" required
                                    class="form-control border p-2 rounded-3"
                                    [ngClass]="{ 'is-invalid': amountSubmitted && enteramount.errors }">

                                <div *ngIf="amountSubmitted" class="text-danger_1">
                                    <div *ngIf="enteramount.errors?.['required']">{{'Amount Required'| translate :
                                        translateControl | async}}</div>
                                </div>
                            </div>

                            <div class="mt-2">
                                <label class=" col-form-label">{{'Attach Your Document:'| translate : translateControl |
                                    async}}</label>
                                <input class="mt-2" type="file" (change)="img($event)" name="imgs" [(ngModel)]="imgs"
                                    #image="ngModel" required
                                    [ngClass]="{ 'is-invalid': amountSubmitted && image.errors }">
                            </div>
                            <div *ngIf="amountSubmitted" class="text-danger_1">
                                <div *ngIf="image.errors?.['required']">{{'Image Proof Required'| translate :
                                    translateControl | async}}</div>
                            </div>
                        </div>
                        <div class="col text-center">
                            <img *ngIf="!fiatPreVieImg" style="object-fit: cover;" height="250px" width="250px"
                                src="assets/img/attached-document.png" class="img-fluid">
                            <img *ngIf="fiatPreVieImg" style="object-fit: cover;" (error)="brokeImage($event)"
                                height="250px" width="250px" [src]="fiatPreVieImg">
                        </div>
                    </div>

                </div>

            </div>
            <div class=" text-start"
                *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                <button type="submit" class="button-secondary mt-3 p-2">{{'Submit'| translate : translateControl |
                    async}}</button>
            </div>

        </form>
    </mat-dialog-content>
</ng-template>

<ng-template #withDraw>
    <mat-dialog-content>
        <mat-icon class="close-icon float-end" id="withdraw-id" [mat-dialog-close]="true">close</mat-icon>
        <h2 class="fs-3"> {{'Payment Method'}}</h2>
        <form (ngSubmit)="withdrawRequest()" autocomplete="off">
            <div class="form-group">
                <div class="">
                    <h6 class="mt-3">{{'Choose Payment Method'}}</h6>
                    <div class="payment-tabbed mt-2">
                        <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="btn_theme-primary me-2 active" id="v-pills-wired-transfer-tab"
                                data-bs-toggle="pill" data-bs-target="#v-pills-wired-transfer" type="button" role="tab"
                                aria-controls="v-pills-wired-transfer" aria-selected="true"
                                (click)="setPaymentType(1)">{{'Wired Transfer'| translate : translateControl |
                                async}}</button>

                            <button class="btn_theme-primary" id="v-pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile" type="button" role="tab"
                                aria-controls="v-pills-profile" aria-selected="false" name="withDrawOnlineTranser"
                                value="2" (click)="setPaymentType(2)">{{'UPI Pay'| translate : translateControl |
                                async}}</button>
                        </div>
                        <div class="mt-4"
                            *ngIf="(transaction_method==1 && userBanks?.bank_count<1)||(transaction_method==2 && userBanks?.upi_count<1) || (userBanks.bank_count<1 && userBanks.upi_count<1)">
                            <div class="row align-items-center">
                                <div class="col-lg-12 text-center mt-4 mt-lg-0">
                                    <h5 class="mb-3">{{'Please Add Bank Account Details'| translate : translateControl |
                                        async}}</h5>
                                    <button class="button-secondary" (click)="addbank()">{{'Click Here'| translate :
                                        translateControl | async}}</button>
                                </div>

                            </div>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel"
                                aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                                <div class="mt-4 bank-check-grp text-start" *ngIf="userBanks?.bank_count>0">
                                    <div class="">
                                        <div class="from-details">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Withdraw Account:'| translate
                                                    : translateControl | async}}</label><br>
                                                <select class="form-control wallet-select bg-light"
                                                    (change)="changeBank($event, 'user', 'bank')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank?.payment_method==1">{{
                                                            bank?.account_no }} - {{ bank?.bank_name | translate :
                                                            translateControl | async }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="bank-detail-wallet" *ngIf="userBankInfo?.account_no">
                                                <ul>
                                                    <li><span>{{'Account Number' | translate : translateControl |
                                                            async}}</span> : <span>{{ userBankInfo?.account_no }}</span>
                                                    </li>
                                                    <li><span>{{'Account Name'| translate : translateControl |
                                                            async}}</span> : <span>{{ userBankInfo?.account_name
                                                            }}</span></li>
                                                    <li><span>{{'Bank Name'| translate : translateControl |
                                                            async}}</span> : <span>{{ userBankInfo?.bank_name |
                                                            translate : translateControl | async }}</span></li>
                                                    <li><span>{{'Account Type'| translate : translateControl |
                                                            async}}</span> : <span>{{ userBankInfo?.account_type
                                                            }}</span></li>
                                                    <li><span>{{'IFSC Code'| translate : translateControl |
                                                            async}}</span> : <span>{{ userBankInfo?.ifsc_code }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                aria-labelledby="v-pills-profile-tab" tabindex="0">
                                <div class="bank-check-grp" *ngIf="userBanks?.upi_count>0">
                                    <div class="">
                                        <div class="from-details p-3 mt-3">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Withdraw UPI:'| translate :
                                                    translateControl | async}}</label><br>
                                                <select class="wallet-select"
                                                    (change)="changeBank($event, 'user', 'upi')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank?.payment_method==2">{{
                                                            bank?.payment_id }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="bank-detail-wallet" *ngIf="userUpiInfo?.payment_id">
                                                <ul>
                                                    <li><span>{{'UPI ID'}}</span>
                                                        : <span>{{ userUpiInfo?.payment_id }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- choose payment -->
                </div>
                <div class="text-start mt-3 rounded-3"
                    *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks.upi_count>0)">
                    <label class="col-form-label d-block">{{'Enter Amount:'| translate : translateControl |
                        async}}</label>
                    <mat-form-field appearance="outline" class="example-full-width">
                        <input matInput [placeholder]="ea" name="amounts" min="0" [(ngModel)]="amount" type="number"
                            #upiamount="ngModel" required [ngClass]="{ 'is-invalid': upiSubmit && upiamount.errors }">
                    </mat-form-field>
                    <div *ngIf="upiSubmit" class="text-danger_1">
                        <div *ngIf="upiamount.errors?.['required']">{{'Transaction Amount is Required'| translate :
                            translateControl | async}}</div>
                    </div>
                </div>
            </div>
            <div class=""
                *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                <button type="submit" class="btn_theme-primary w-100 mt-3 p-3">{{'Submit'| translate : translateControl
                    | async}}</button>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>


<ng-template #paypal>
    <mat-dialog-content class="paypal stripcard">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <h2 class="fs-4">{{'Paypal'}}</h2>
        <div class="form-group">
            <div class="mt-4">
                <label class="col-form-label me-0">{{'Enter Amount:'}} </label>
                <div class="input-group mb-3">
                    <input class="form-control border-end-0" appNonegativevalue [placeholder]="ea" name="amounts"
                        [(ngModel)]="amount" type="number">
                    <span class="slug_name">{{depositMin?.slug | uppercase}}</span>
                </div>
                <ngx-paypal class="paypalbtn" *ngIf="fiatId" [config]="payPalConfig"></ngx-paypal>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #listAddress>
    <mat-dialog-content class="paypal stripcard">
        <mat-icon class="close-icon" id="address-id" [mat-dialog-close]="true">{{'close'| translate : translateControl |
            async}}</mat-icon>
        <div class="form-group">
            <div class="">
                <h5 class="fw-semibold">{{'Select'}} {{depositMin?.slug}}
                    {{'Address'}}</h5>
                <h6 class="fs-16 my-3">{{'EVM Address'}}</h6>
                <ul class="list-unstyled px-0">
                    <li class="d-flex align-items-center justify-content-between border rounded p-3 mt-2"
                        *ngFor="let data of addressList">
                        <div (click)="getAddress(data)" class="cursor-pointer">
                            <div class="address_ellipsis mb-2">
                                <span>{{data?.wallet_address}}</span>
                            </div>
                            <h6 class="fs-13 mb-0 fw-semibold"><span>{{data?.remarks | translate : translateControl |
                                    async}}</span></h6>
                        </div>
                        <div>
                            <svg *ngIf="isIconClicked == false || (this.add != data?.wallet_address && isIconClicked == true)"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <svg *ngIf=" this.add == data?.wallet_address && isIconClicked == true"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16" style="fill: green !important;">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <button type="button" class="btn btn-warning w-100 mt-4" routerLink="/withdraw-book">{{'Add withdraw
                    address'}}</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>

<ng-template #stripe>
    <mat-dialog-content class="stripcard p-4">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <main class="">
            <div class="stripe-grp">
                <div class="p-7">
                    <h2 class="fs-4"><i class="fa fa-credit-card"></i> {{'Credit Card'| translate : translateControl |
                        async}}</h2>
                    <div class="tab-content">
                        <div class="" id="nav-tab-card">
                            <form role="form" [formGroup]="stripeform" (ngSubmit)="submitStripe()">
                                <div class="row mt-4">
                                    <div class="col-sm-8 ps-0">
                                        <div class="form-group mb-4 mb-sm-0">
                                            <label class="mb-2" for="username">{{'Full name (on the card)'| translate :
                                                translateControl | async}}</label>
                                            <input type="text" class="form-control shadow" formControlName="fullName"
                                                name="fullName" [placeholder]="Full_Name"
                                                [ngClass]="{ 'is-invalid': payingsubmitted && sf.fullName?.errors }">
                                            <div *ngIf="payingsubmitted && sf['fullName'].errors"
                                                class="invalid-feedback">
                                                <span *ngIf="sf['fullName'].errors?.['required']">{{'FullName required'|
                                                    translate : translateControl | async}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 ps-0">
                                        <div class="form-group">
                                            <label class="mb-2" for="amount">{{'Amount'| translate : translateControl |
                                                async}}</label>
                                            <input type="number" (keypress)="numberValidation($event)"
                                                class="form-control shadow" formControlName="Amount" name="Amount"
                                                [placeholder]="Amount"
                                                [ngClass]="{ 'is-invalid': payingsubmitted && sf.Amount?.errors }">
                                            <div *ngIf="payingsubmitted && sf?.Amount?.errors" class="invalid-feedback">
                                                <div *ngIf="sf?.Amount.errors?.required">{{'Amount is required'|
                                                    translate : translateControl | async}}</div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="form-group mt-4">
                                    <label class="mb-2" for="cardNumber">{{'Card number'| translate : translateControl |
                                        async}}</label>
                                    <div class="input-group">
                                        <input type="text" class="form-control shadow"
                                            (ngModelChange)="onCardNumberChange($event)" formControlName="cardNumber"
                                            maxlength="19" (keyup)="creditCheck($event)" name="cardNumber"
                                            [placeholder]="Card_Number"
                                            [ngClass]="{ 'is-invalid': payingsubmitted && sf.cardNumber?.errors }">
                                        <div class="input-group-append shadow">
                                            <span class="input-group-text text-muted">
                                                <span [ngClass]="{'Visa': cardType === 'Visa'}"><i
                                                        class="fab fa-cc-visa fa-lg pr-1"></i></span>
                                                <span [ngClass]="{'Amex': cardType === 'Amex'}"><i
                                                        class="fab fa-cc-amex fa-lg pr-1"></i></span>
                                                <span [ngClass]="{'mastercard': cardType === 'MasterCard'}"><i
                                                        class="fab fa-cc-mastercard fa-lg"></i></span>
                                            </span>
                                        </div>

                                        <div *ngIf="payingsubmitted && sf?.cardNumber?.errors" class="invalid-feedback">
                                            <div *ngIf="sf?.cardNumber.errors?.required">{{'Card Number is required'|
                                                translate : translateControl | async}}</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-sm-8 ps-0">
                                        <div class="form-group mb-4 mb-sm-0">
                                            <label class="mb-2"><span class="hidden-xs">{{'Expiration'| translate :
                                                    translateControl | async}}</span> </label>
                                            <div class="input-group">
                                                <select class="form-control wallet-select w-auto shadow"
                                                    formControlName="month"
                                                    [ngClass]="{ 'is-invalid': payingsubmitted && sf.month?.errors }">
                                                    <option value="" disabled selected>{{'MM'| translate :
                                                        translateControl | async}}</option>
                                                    <option *ngFor="let mon of months let i = index" value="{{1+i}}">
                                                        {{mon }}</option>
                                                </select>
                                                <select class="form-control wallet-select w-auto shadow"
                                                    formControlName="year"
                                                    [ngClass]="{ 'is-invalid': payingsubmitted && sf.year?.errors }">
                                                    <option value="" selected disabled>{{'YYYY'| translate :
                                                        translateControl | async}}</option>
                                                    <option *ngFor="let y of years let i = index" value="{{y}}">{{y|
                                                        translate : translateControl | async}}</option>
                                                </select>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 px-0">
                                        <div class="form-group">
                                            <label class="mb-2" data-toggle="tooltip" title=""
                                                data-original-title="3 digits code on back side of the card">{{'CVV'|
                                                translate : translateControl | async}} <i
                                                    class="fa fa-question-circle"></i></label>
                                            <input type="number" class="form-control shadow" placeholder="CVV"
                                                name="cvv" formControlName="cvv"
                                                [ngClass]="{ 'is-invalid': payingsubmitted && sf.cvv?.errors }">
                                            <div *ngIf="payingsubmitted && sf?.cvv?.errors" class="invalid-feedback">
                                                <div *ngIf="sf?.cvv.errors?.required">{{'CVV is required'| translate :
                                                    translateControl | async}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn_theme-primary w-100 p-3 mt-4" type="submit">
                                    <span *ngIf="!payingsubmitted">{{'PAY'| translate : translateControl |
                                        async}}</span>
                                    <span *ngIf="payingsubmitted">{{'PAYING'| translate : translateControl |
                                        async}}</span>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    </mat-dialog-content>
</ng-template>

<ng-template #twoFa>
    <div class="row align-middle">
        <div class="p-3">
            <div class="login_div email-auth">
                <h4 *ngIf="user_tfa?.authentic_status === '1'" class="fw-bolder mb-4"><span
                        class="gradient-text-color">{{'Google Authenticate Code'| translate : translateControl |
                        async}}</span></h4>
                <h4 *ngIf="user_tfa?.authentic_status === '2'" class="fw-bolder mb-4"><span
                        class="gradient-text-color">{{'Email Authenticate Code'| translate : translateControl |
                        async}}</span></h4>
                <form [formGroup]="otp_form">
                    <div class="form-group add_form_group">
                        <input formControlName="otp" (keypress)="keyPressNumbers($event)" maxlength="6" type="text"
                            class="form-control shadow border rounded-3 p-2" id="exampleInputEmail1"
                            aria-describedby="emailHelp" [placeholder]="Enter_2FA_code">
                        <div *ngIf="isSubmited" class="text-danger mt-2">
                            <p *ngIf="formVerify['otp'].errors?.['required']"> {{'OTP is required'| translate :
                                translateControl | async}} </p>
                            <p *ngIf="formVerify['otp'].errors?.['minlength']||formVerify['otp'].errors?.['maxlength']">
                                {{'OTP Must be 6 Digits'}}</p>
                        </div>
                        <div class="btn_div">
                            <button *ngIf="user_tfa?.authentic_status === '1'" type="submit" (click)="verifyOTP()"
                                class="button-secondary border-0 mt-3">{{'Verify'| translate : translateControl |
                                async}}</button>
                            <button *ngIf="user_tfa?.authentic_status === '2'" type="button" (click)="emailTfaVerify()"
                                class="button-secondary border-0 mt-3">{{'Verify'| translate : translateControl |
                                async}}</button>
                            <button *ngIf="user_tfa?.authentic_status === '2'" type="button"
                                (click)="emailAuthentication()" class="button-secondary border-0 ms-3 mt-3">{{'Resend
                                OTP'}}</button>
                            <div class="row mt-3">
                                <div class=" add_right_forgot" style="text-align: end;">
                                    <a class="theme-text-color-1" routerLink="/tfa-losting">{{'2FA Code Losting ?'|
                                        translate : translateControl | async}}</a>
                                </div>
                                <div class="add_right_forgot" style="text-align: end;">
                                    <a class="theme-text-color-1" style="cursor: pointer;" (click)="logOut()">{{'Back?'|
                                        translate : translateControl | async}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #viewHistory>
    <div class="mb-3 mb-sm-0 popup-padding p-4">
        <div class="d-flex justify-content-between align-items-center">
            <button mat-icon-button class="border-0 close-button " [mat-dialog-close]="true">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </div>
        <h5 class="">
            <img src="{{selectImage}}" class="wallet_coin_img">
            <span class="wallet_coin_title_span">{{selectSlug | uppercase | translate :
                translateControl | async}}</span>
        </h5>
        <div class="border-0 p-2 ">
            <div class="mt-3">
                 <p><span class="fw-bold">{{"24h PNL :"| translate : translateControl |
                    async}}</span>&nbsp;&nbsp;{{((allHistory?.day_pnl.toFixed(2)?allHistory?.day_pnl.toFixed(2):0) 
             )}}</p>
                <p class="mb-0"><span class="fw-bold">{{"24h PNL (%) :"| translate :
                    translateControl | async}}</span>
                <span class="fw-bold"
                    [ngClass]="allHistory?.pnl_per < 0 ? 'value_ticker text-danger' : 'value_ticker text-success'">
                    &nbsp;&nbsp;{{((allHistory?.pnl_per.toFixed(2)?allHistory?.pnl_per.toFixed(2):0)
                    )}}</span>
            </p>
            </div>
        </div>
    </div>
</ng-template>