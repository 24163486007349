import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './common/login/login.component';
import { LayoutComponent } from './component/layout/layout.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { RegisterComponent } from './common/register/register.component';
import { MyorderComponent } from './myorder/myorder.component';
import { ClaimComponent } from './component/claim/claim.component';
import { ResultComponent } from './result/result.component';
import { ViewticketComponent } from './viewticket/viewticket.component';
import { ProfileComponent } from './profile/profile.component';
import { UserresultsComponent } from './userresults/userresults.component';
import { KycSubComponent } from './kyc-sub/kyc-sub.component';
import { VerifiedauthGuard, authGuard, kycCheck } from './auth.guard';
import { PricelistComponent } from './pricelist/pricelist.component';
import { MyCartComponent } from './component/my-cart/my-cart.component';
import { TwoFaComponent } from './two-fa/two-fa.component';
import { MyLotteryComponent } from './my-lottery/my-lottery.component';
import { EmailotpComponent } from './emailotp/emailotp.component';
import { UsermailOtpComponent } from './usermail-otp/usermail-otp.component';
import { TfaVerificationComponent } from './tfa-verification/tfa-verification.component';
import { TfaLostingComponent } from './tfa-losting/tfa-losting.component';
import { WalletComponent } from './wallet/wallet.component';
import { DepositComponent } from './deposit/deposit.component';
import { FiatWithdrawComponent } from './component/fiat-withdraw/fiat-withdraw.component';
import { TfaGuard} from './services/auth.guard';
import { CryptodepositComponent } from './cryptodeposit/cryptodeposit.component';
import { CryptoWithdrawComponent } from './crypto-withdraw/crypto-withdraw.component';
import { BankdetailsComponent } from './bankdetails/bankdetails.component';
import { ViewdialogComponent } from './viewdialog/viewdialog.component';
import { ViewlosedialogComponent } from './viewlosedialog/viewlosedialog.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', component :  WelcomeComponent },
  { path: 'login', pathMatch: 'full', component:LoginComponent, canActivate:[VerifiedauthGuard] },
  {
    path: '', component: LayoutComponent, children: [
      { path: 'kyc-sub', component:KycSubComponent},
      { path: 'myorder', component:MyorderComponent,canActivate:[authGuard]},
      { path: 'claim', component:ClaimComponent ,canActivate:[authGuard]},
      { path:'result',component:ResultComponent,canActivate:[authGuard,kycCheck]},
      { path:'viewticket',component:ViewticketComponent,canActivate:[authGuard,kycCheck]},
      { path:'pricelist',component:PricelistComponent},
      { path:'profile',component:ProfileComponent,canActivate:[authGuard]},
      { path:'userresults',component:UserresultsComponent,canActivate:[authGuard]},
      { path:'mycart',component:MyCartComponent,canActivate:[authGuard,kycCheck]},
      { path:'two-fa', component:TwoFaComponent},
      {path:'my-lottery',component:MyLotteryComponent,canActivate:[authGuard,kycCheck]},
      {path:'emailotp',component:EmailotpComponent},
      {path:'email-otp-verification',component:UsermailOtpComponent},
      { path:'tfa-verification',component:TfaVerificationComponent},
      { path:'tfa-losting',component:TfaLostingComponent},
      { path:'wallet',component:WalletComponent,canActivate:[authGuard,kycCheck]},
      { path: 'fiat-deposit', component:DepositComponent,canActivate: [authGuard,TfaGuard,kycCheck] },
      { path: 'fiat-withdraw', component:FiatWithdrawComponent,canActivate: [authGuard,TfaGuard,kycCheck] },
      { path: 'crypto-deposit', component:CryptodepositComponent,canActivate: [authGuard,TfaGuard,kycCheck] },      
      { path: 'crypto-withdraw', component:CryptoWithdrawComponent,canActivate: [authGuard,TfaGuard,kycCheck] }, 
      { path: 'bank-details', component: BankdetailsComponent, canActivate: [authGuard,TfaGuard] },
      { path: 'viewDialog', component:ViewdialogComponent},
      { path: 'viewLoseDialog', component:ViewlosedialogComponent}
    ]},
    {path:'register',component:RegisterComponent},
];
                                              

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
