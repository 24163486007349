<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">{{'Loading'}}...</p>
</ngx-spinner>
<section class="emailotp-sec height">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-5 col-md-7 col-sm-12 mt-5">
                <div class="card  rounded-4 position-relative text-start night-bg px-4 py-5">
                    <div class="">
                        <div class="d-flex justify-content-between align-items-center mb-4">
                            <h3 class="fs-5 mb-0 otp-color " >{{'Enter E-mail OTP'}}</h3>
                            <button routerLink="/profile" class="btn resend-btn w-auto"><i
                                    class="fa fa-long-arrow-left"></i>
                                {{'Back To Profile'}}</button>
                        </div>
                        <form [formGroup]="form">
                            <div class="form-group ">
                                <label for="exampleInputEmail1" class="fw-semibold otp-color mb-2">{{'OTP'}} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="twofa_otp" placeholder="Enter your OTP" maxlength="6" type="text"
                                    class="form-control rounded-3 border-0"
                                    [ngClass]="{'is-invalid' : submitted && f['twofa_otp'].errors}"
                                    aria-label="Recipient's username" aria-describedby="basic-addon2">
                                <div *ngIf="submitted && f['twofa_otp'].errors" class="invalid-feedback">
                                    <div *ngIf="f['twofa_otp'].errors?.['required']">{{'Email OTP is Required'}}</div>
                                    <div *ngIf="f['twofa_otp'].errors?.['pattern']">{{'Invalid Code'}}</div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between align-items-center mt-4">
                                <button class="btn resend-btn">
                                    <i class="loading-icon fa"
                                        [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>
                                    {{button}}</button>
                                <button type="button" (click)="submit()" class="btn resend-btn">{{'Submit'}}</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>