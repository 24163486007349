import { Component,TemplateRef,OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Form, FormBuilder, Validators } from '@angular/forms';
import { ViewdialogComponent } from '../viewdialog/viewdialog.component';
import { ViewlosedialogComponent } from '../viewlosedialog/viewlosedialog.component';
import { environment } from '../../environments/environment.development';
@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrl: './result.component.scss'
})
export class ResultComponent implements OnInit {
  result:any[]=[];
translateControl: any;
searchMarket: any;
filteredItems: any;
page:any = {page_no:1,total_page:'',itemsPerPage:environment.PAGE_SIZE,s_no:0}
searchForm:any;
payload:any = {}
constructor( public dialog : MatDialog,private callApi:UserService, private fb:FormBuilder){}

ngOnInit(){
this.getUserResult()
  this.createForm()
}

createForm(){
  this.searchForm = this.fb.group({
    searchEl:["",[Validators.required]]
  })
}

getUserResult(){
  this.callApi.getUserResult(this.payload,this.page.page_no).subscribe((res:any) => {
    if(res.success){
      this.result = res.data.data
      this.page.total_page = res.data.totalCount
      this.page.s_no = (Number(this.page.page_no - 1)) * Number(environment.PAGE_SIZE)
    }
  })
}


openDialog(resultData: any): void {  
  if (resultData.isWin) {
    this.dialog.open(ViewdialogComponent, {
      data: resultData
    });
  } else {
    this.dialog.open(ViewlosedialogComponent, {
      data: resultData
    });
  }
}

handlePageEvents(event:any){
  this.page.page_no = event
  this.getUserResult()
}

onSearch(){
  if(this.searchForm.valid){
    this.payload = this.searchForm.value
  this.getUserResult()
  }else{
    this.callApi.showError("Please enter valid inputs")
  }
  
  
}

}
