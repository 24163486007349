import { Component,Inject} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-viewdialog',
  templateUrl: './viewdialog.component.html',
  styleUrl: './viewdialog.component.scss'
})
export class ViewdialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
