import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-usermail-otp',
  templateUrl: './usermail-otp.component.html',
  styleUrl: './usermail-otp.component.scss'
})
export class UsermailOtpComponent {
  public translateControl: any;
  showOtp: any;
  form: any;
  submitted: boolean = false;
  email:any
  button='Resend Code';
  enterValidCode: any;
  isLoading:boolean=false
  // @ViewChild('welcome') welcome : ElementRef | any
  constructor(
    private callApi: UserService,
    private router: Router,
    private route:ActivatedRoute,
    private fb: FormBuilder,private dialog:MatDialog
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((res:any)=>{
      const decrypt_email = this.callApi.decryptData(res?.mail)
      this.email=decrypt_email      
    })
    this.createForm();
    this.showOtp = localStorage.getItem('otp')
  }

  get f() { return this.form.controls; }
  createForm() {
    this.form = this.fb.group({
      otp: [null, [Validators.required, Validators.pattern(/^((\\+91-?)|0)?[0-9]{6}$/)]]
    })
  }
  submit() {
    this.submitted = true
    let formvalid = this.form.value;
    if (!this.form.invalid) {
      let payload = {
        email: this.email,
        otp:formvalid.otp
      }
      this.callApi.verifyOtp(payload).subscribe(async (res: any) => {
        if (res.success) {
          // this.callApi.showInfo(res.message)
          this.router.navigate(['/welcome'])
          this.showOtp = res?.data?.otp
          localStorage.setItem('access_token',res?.data?.token)
          // this.callApi.publishEvent({ 'EventName': 'header_reload', 'EventDetail': '' } )
          // this.dialog.open(this.welcome,{
          //     width: '100%',
          //     height:"auto",
          //     minWidth: 'auto',
          //     maxWidth: '40rem'
          //   })
            this.callApi.getToken()
          }
        else {
          this.callApi.showError(res.message)
        }
      }, err => {
        this.callApi.serverError();
      })
    }
  }
 
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  back() {
    localStorage.clear();
    this.router.navigate(['/register'])
  }
  resendOTP(){
    this.button='Processing',
    this.isLoading=true
    // this.callApi.startSpinner();
    console.log("here")
    this.callApi.resendmailotp({email:this.email}).subscribe(async (res:any) => {
      if (res.success == true) {
         this.callApi.showSuccess(res.message)
         this.button='Resend Code',
      this.isLoading=false
      }
      else{
        this.callApi.showError(res.message)
        this.button='Resend Code',
      this.isLoading=false
      }
    }, err => {
      this.callApi.serverError();
      this.button='Resend Code',
      this.isLoading=false
    })
  }

  openRewards(template: TemplateRef<any>) {
    this.dialog.open(template, {
      width: '100%',
      minWidth: 'auto',
      maxWidth: '35rem',
      height: 'auto'
    })
  }
}
