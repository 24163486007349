import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectService } from '../services/translate/select.service';
import { UserService } from '../services/user.service';
import { environment } from '../../environments/environment.development';

@Component({
  selector: 'app-tfa-losting',
  templateUrl: './tfa-losting.component.html',
  styleUrl: './tfa-losting.component.scss'
})
export class TfaLostingComponent {
  public translateControl: any;

  form:any
  userimage:any
  public imagepath = environment.IMAGE_URL
  image: any;
  submitted:boolean = false
  profileData: any;

  constructor(private callApi:UserService,private fb:FormBuilder,private router:Router,public selectService: SelectService,
    ){ }

  ngOnInit(): void {
    this.createForm()
    this.callApi.getProfile().subscribe((res:any)=>{
    this.profileData = res?.data?.TwofaLostkycDetails
    })

    this.selectService.getSharedData().subscribe(data => {
      this.translateControl = data
      // if(this.translateControl == 'es'){
      //   this.Choose_start_date = 'Elige fecha de inicio',
      //   this.Choose_end_date = 'Elige la fecha de finalización'
        
      //     }
      //     else if(this.translateControl == 'fr'){
      //       this.Choose_start_date = 'Choisissez la date de début',
      //       this.Choose_end_date = 'Choisissez la date de fin'
            
      //     }else{
      //       this.Choose_start_date = 'Choose start date',
      //       this.Choose_end_date = 'Choose end date'
            
      //     }
        
    })
  }

  createForm(){
    this.form = this.fb.group({
      user_name :['',[Validators.required]],
      dob :['',Validators.required],
      mobile_number :['',Validators.required],
      image:['',Validators.required]
      })
    }
    get f () {
      return this.form.controls
    }
  
  submit(){
    this.submitted = true
    if(this.form.valid && this.userimage){
      let payload = this.form.value
      payload.image = this.userimage
      this.callApi.twofaLostingRequest(this.form.value).subscribe(async (res:any)=>{
        if(res.success == true){
          this.callApi.showSuccess(res.message)
          this.form.reset()
          this.userimage = ''
          localStorage.clear()
          this.router.navigate(['/login'])

        } else{
          this.callApi.showError(res.message)
        }
      })
    }
  }
  // get f(){return this.form.controls}

  uploadImage(event:any){
    this.image = event.target.files[0]
    let formData = new FormData()
    formData.append('image',this.image)
    this.callApi.twofaLostImage(formData).subscribe(async (res:any)=>{
      if(res.success == true){
        this.userimage = res?.data
      } else{
        this.callApi.showError(res.message)
      }
    })
  }
  stringValidation(event: any) {
    if (!event.key.match(/^[a-zA-Z]+$/)) {
      event.preventDefault();
    }
  }
}



