import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from '../services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CartService } from '../cart.service';
import { TicketManagementService } from '../services/ticket-management.service';



@Component({
  selector: 'app-viewticket',
  templateUrl: './viewticket.component.html',
  styleUrl: './viewticket.component.scss'
})
export class ViewticketComponent implements OnInit {
  ticket_id:any;
  viewticket: any;
  isSubmitted:boolean = false;
  ticketArray:any;
  tickets:any[]=[]
  ticketsDet:any
  selectedOption: any = [];
  notificationCount: any;
  drawName: any;
  ticketCount :any;
  ticketPurchased:any
  AlreadyBuyTicketCount:any
  ticketDrawName:any;
  constructor(
    private fb:FormBuilder,
    private callapi:UserService,
    private route:Router,
    public dialog:MatDialog,
    private router: ActivatedRoute,
    private ticketService:TicketManagementService
    ){}
  
  ngOnInit(): void {
    
    this.getTicketId()
    this.initializeForm();
    
  }

  initializeForm(){
    this.viewticket=this.fb.group({
      ticket_id:['',Validators.required],
      ticket_price:['',Validators.required],
      ticket_name:['',Validators.required],
      date:['',Validators.required]
    })
  } 

  getTicketId(){
     this.router.queryParams.subscribe((res:any) => {
      this.ticket_id=res?.id
     })
     this.getTickets()
  }

  getTickets(){
       
    let payload = {
      ticket_id:this.ticket_id
    }
    this.callapi.getSpecificTickets(payload).subscribe((res:any) => {
      if(res.success){
        this.ticketArray = res?.data[0];
        this.ticketDrawName = this.ticketArray.ticketDrawName
        this.selectedOption = this.ticketArray.ticketCategories
        this.ticketsDet = this.ticketArray.ticketCategories[0] 
        this.AlreadyBuyTicketCount = res?.data[0].users_arr.ticketCount  ? res?.data[0].users_arr.ticketCount : 0      
        this.ticketCount =this.AlreadyBuyTicketCount
        this.getPurchasedDets()
        this.updateFormValues();
        this.drawName = res?.data[0].ticketDrawName;
      }else{
        this.route.navigate(['/welcome'])
        this.callapi.showError(res.message)
      }
    })
  }
  updateFormValues() {
    this.viewticket.patchValue({
      ticket_id: this.ticketArray.ticket_no,
      ticket_price: this.ticketArray.ticketDrawPrice,
      ticket_name: this.ticketArray.ticket_name,
      date: this.ticketArray.result_date // Adjust this based on your actual data structure
    });
  }

openDialog(templateRef: TemplateRef<any>) {
  this.dialog.open(templateRef, {
    disableClose: true,
    width: '35%',
    height: '70%',
  })
  }

selectTicket(ticket: any) {
  if(ticket.disabled){
    return;
  }
console.log(this.ticketCount,"this.ticketCount")
  if(this.ticketArray.minimumTicketsBuyByUser > this.ticketCount){
    
    let payload = {
      costPerTicket:this.ticketsDet.costPerTicket,
      ticket_no:ticket.ticket_no,
      isSold:ticket.isSold,
      ticketDrawPrice:this.ticketsDet.ticketDrawPrice,
      ticketDrawName:this.ticketArray.ticketDrawName,
      ticket_id:ticket._id
    }
this.callapi.choosingTicket(payload).subscribe((res:any) => {
  if(res.success){
    this.getPurchasedDets()
  }
})
    
      ticket.disabled = true;
  }
  
  else{
    this.callapi.showError(`You can buy only ${4 - this.AlreadyBuyTicketCount} tickets in this draw , Because you already bought ${this.AlreadyBuyTicketCount} Tickets in this draw ` )
  } 
  }

  onClickOption(data:any){
    this.ticketsDet = data
    this.getPurchasedDets()
    
  }

  getPurchasedDets(){
    this.callapi.getPurchasedDet({}).subscribe((res:any) => {
      if(res.success){
        this.ticketPurchased= res.data.ticketDetails.filter((each:any) => each.ticketDrawName ==  this.ticketDrawName)             
        if(this.ticketPurchased.length > 0){
          for( let selectedOption of this.ticketPurchased){  
            this.ticketsDet.ticketArr.map((each:any) => {
              if(each._id == selectedOption.ticket_id){
                each.disabled = true                
              }
            })
        }
        this.ticketCount = this.AlreadyBuyTicketCount + this.ticketPurchased.length
      }else{
        this.ticketCount =this.AlreadyBuyTicketCount
        console.log(this.ticketCount,"lop");
        
      }
      
      }
  })
 
}

}



                                                                                                                                                                                                                                                                               