import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { WelcomeComponent } from './component/welcome/welcome.component';
import { LayoutComponent } from './component/layout/layout.component';
import { LoginComponent } from './common/login/login.component';
import { RegisterComponent } from './common/register/register.component';
import { TfaVerificationComponent } from './tfa-verification/tfa-verification.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { MyorderComponent } from './myorder/myorder.component';
import { ToastrModule } from 'ngx-toastr';
import { ClaimComponent } from './component/claim/claim.component';
import { ResultComponent } from './result/result.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { ViewticketComponent } from './viewticket/viewticket.component';
import { ProfileComponent } from './profile/profile.component';
import { UserresultsComponent } from './userresults/userresults.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatSelectModule } from '@angular/material/select';
import { DatePipe } from '@angular/common';

import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';

// import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { PricelistComponent } from './pricelist/pricelist.component';
import { MyCartComponent } from './component/my-cart/my-cart.component';
import { TwoFaComponent } from './two-fa/two-fa.component';
import { MyLotteryComponent } from './my-lottery/my-lottery.component';
import { KycSubComponent } from './kyc-sub/kyc-sub.component';
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatNativeDateModule } from '@angular/material/core';
import { WebcamModule } from 'ngx-webcam';
import { EmailotpComponent } from './emailotp/emailotp.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { UsermailOtpComponent } from './usermail-otp/usermail-otp.component';
import { TfaLostingComponent } from './tfa-losting/tfa-losting.component';


import { WalletComponent } from './wallet/wallet.component';
import { TofixedPipe } from './services/pipes/tofixed.pipe';
import { MatTabsModule } from '@angular/material/tabs';
import { QrCodeModule } from 'ng-qrcode';
import { MatSliderModule } from '@angular/material/slider'; // Import MatSliderModule
import { MatSidenavModule } from '@angular/material/sidenav'; // Import MatSidenavModule
import { TranslatePipe } from './services/translate/translate.pipe';
import { NgxPayPalModule } from 'ngx-paypal';
import { FiatWithdrawComponent } from './component/fiat-withdraw/fiat-withdraw.component';
import { CryptoWithdrawComponent } from './crypto-withdraw/crypto-withdraw.component';
import { CryptodepositComponent } from './cryptodeposit/cryptodeposit.component';
import { NgxLoadingModule } from "ngx-loading";
import { DepositComponent } from './deposit/deposit.component';
import { MyFilterPipePipe } from './services/pipes/my-filter-pipe.pipe';
import { BankdetailsComponent } from './bankdetails/bankdetails.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RecaptchaModule } from 'ng-recaptcha';
import { ViewdialogComponent } from './viewdialog/viewdialog.component';
import { ViewlosedialogComponent } from './viewlosedialog/viewlosedialog.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        WelcomeComponent,
        LayoutComponent,
        LoginComponent,
        RegisterComponent,
        MyorderComponent,
        ClaimComponent,
        ResultComponent,
        ViewticketComponent,
        ProfileComponent,
        UserresultsComponent,
        PricelistComponent,
        MyCartComponent,
        TwoFaComponent,
        MyLotteryComponent,
        KycSubComponent,
        EmailotpComponent,
        UsermailOtpComponent,
        TfaVerificationComponent,
        TfaLostingComponent,
        BankdetailsComponent,
      
        WalletComponent,
        TofixedPipe,
        TranslatePipe,
        FiatWithdrawComponent,
        CryptoWithdrawComponent, 
        CryptodepositComponent,
        DepositComponent,
        MyFilterPipePipe,
        ViewdialogComponent,
        ViewlosedialogComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatFormFieldModule,
        MatInputModule,
        MatButtonModule,
        MatStepperModule,
        CommonModule,
        MatSelectModule,
        MatNativeDateModule,
        MatDatepickerModule,
        WebcamModule,
        MatDialogModule,
        MatIconModule,
        NgxSpinnerModule,
        ToastrModule.forRoot({
            timeOut: 2000,
        }),
        MatTabsModule,
        QrCodeModule,
        MatSliderModule,
        MatSidenavModule,
        NgxPayPalModule,
        NgxLoadingModule,
        NgxPaginationModule,
        RecaptchaModule
    ],
    providers: [
        DatePipe,
        provideAnimationsAsync(),
        {
            provide: STEPPER_GLOBAL_OPTIONS,
            useValue: { showError: true },
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }