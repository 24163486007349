import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
// import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators } from '@angular/forms';
// import { UserService } from 'src/app/service/user.service';
import { MatDialog } from '@angular/material/dialog';
import translate from 'translate';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
// import { ImageValidationService } from 'src/app/service/image-validation.service';
import Swal from 'sweetalert2';
import { environment } from '../../environments/environment.development';
import { ImageValidationService } from '../services/image-validation.service';
import { SelectService } from '../services/translate/select.service';
import { UserService } from '../services/user.service';
// import { SelectService } from 'src/app/service/translate/select.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {

  backendend = environment.IMAGE_URL
  title = 'payment'
  value: Number = 1;
  userBanks: any;
  adminBankInfo: any;
  adminBanks: any;
  transaction_method: any;
  userBankInfo: any
  public translateControl: any;
  userUpiInfo: any
  adminUpiInfo: any
  transactionId: any
  amountSubmitted: boolean = false
  fiatPreVieImg: any;
  amount: any
  imgs: any
  fiatId: any;
  public payPalConfig?: IPayPalConfig
  @ViewChild('wiredTransfer') wiredTransfer: ElementRef | any;
  @ViewChild('paypal') paypalpop: ElementRef | any;
  @ViewChild('stripe') stripe: ElementRef | any;
  showSuccess: boolean = false;
  showCancel: boolean = false;
  paypalClientId: any = environment.paypal_client_id;
  fiatDocImg: any;
  payingsubmitted: boolean = false;
  stripeform: any;
  cardType: any;
  cardPatterns: any;
  months: any;
  years: any;
  fiatAmount: any
  fiat: any;
  Fiatdata: any = [];
  fiatSlug: any
  data: any = {};
  amount_field!: string;
  eti!: string;
  Full_Name!: string;
  Card_Number!: string;
  selected!: string;
  walletData: any;
  currencyImg: any;
  balanceof: any;
  total: any;
  depositMin: any;
  showCoin: boolean = false
  payPalShow: boolean = false;
  isSelectionMade = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private callApi: UserService,
    private dialog: MatDialog, private validimage: ImageValidationService, private services: SelectService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.paypal();
    this.getBankList();
    this.getFiatList();
    this.stripeForm();
    this.services.getSharedData().subscribe((res: any) => {
      this.translateControl = res
      if (this.translateControl == 'es') {
        this.amount_field = 'Cantidad',
          this.eti = 'Ingrese el ID de la transacción',
          this.Full_Name = 'Nombre completo',
          this.Card_Number = 'Número de tarjeta',
          this.selected = 'Seleccionar'
      } else if (this.translateControl == 'fr') {
        this.amount_field = 'Montant'
        this.eti = 'Entrez l identifiant de la transaction',
          this.Full_Name = 'Nom et prénom',
          this.Card_Number = 'Numéro de carte',
          this.selected = 'Sélectionner'
      } else {
        this.amount_field = 'Amount'
        this.eti = 'Enter Transaction Id',
          this.Full_Name = 'Full Name',
          this.Card_Number = 'Card Number',
          this.selected = 'Select'
      }
    })
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams.id) {
        this.fiatSlug = queryParams.id ? queryParams.id : this.fiatSlug;
        this.showCoin = true
      }
    })

  }

  selectType(type: any, value: any) {
    this.title = type
    this.value = value
  }




  getFiatList() {
    this.callApi.getTradeHistoryList().subscribe((res: any) => {
      this.fiat = res?.data?.coin
      this.data = res?.data?.coin
      this.fiat.map((item: any) => {
        if (item.coin_type == 0) {
          this.Fiatdata.push(item)          
        }
      })
      this.route.queryParams.subscribe((queryParams: any) => {
        if (queryParams.id) {
          this.fiatSlug = queryParams.id;
          let dataSlug = this.Fiatdata.filter((item: any) => item._id === queryParams.id);
          this.data.slug = dataSlug[0]?.slug;
          console.log(this.data.slug);
          this.selectFiat({ value: dataSlug[0]?._id })
        }
        else {
          this.fiatSlug = this.Fiatdata[0]?._id
          this.data.slug = this.Fiatdata[0]?.slug
        }
      })
      this.data.fee = this.Fiatdata[0]?.deposit_fee
    })
  }

  selectFiat(event: any) {
    this.isSelectionMade = true;
    this.Fiatdata.map((item: any) => {
      if (event?.value == item._id) {
        this.data = item
        this.paypal()
      }
    })
  }

  stripeForm() {
    this.stripeform = this.fb.group({
      fullName: ['', Validators.required],
      Amount: ['', Validators.required],
      cardNumber: ['', Validators.required],
      cvv: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required]
    });
  }

  setPaymentType(type: any) {
    this.transaction_method = type
  }

  addbank() {
    this.router.navigate(['/bank-details'])
    this.dialog.closeAll();
  }
  changeBank(event: any, on: string, type: string) {
    let index = event.target.value
    if (type == 'bank') {
      if (on == 'admin') {
        this.adminBankInfo = this.adminBanks[index]
      } else {
        this.userBankInfo = this.userBanks[index]
      }
    } else {
      if (on == 'admin') {
        this.adminUpiInfo = this.adminBanks[index]
      } else {
        this.userUpiInfo = this.userBanks[index]
      }
    }
  }

  async img(event: any) {
    let data = this.validimage.fileUploadValidator(event)
    if (data == false) {
      this.callApi.showError(await translate('File Format Like JPG,PNG,JPEG', this.translateControl))
    } else {
      this.imgs = event.target.files[0]
      let formData = new FormData()
      formData.append('document_proof', this.imgs)
      this.callApi.fiatDepositImaget(formData).subscribe((res: any) => {
        if (res.success) {
          this.fiatDocImg = res?.data?.document_proof
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (event: any) => {
            this.fiatPreVieImg = event.target.result;
          }
        }
      })
    }
  }

  get sf() { return this.stripeform.controls }

  brokeImage(event: any) {
    event?.target?.src
  }

  submitFiatDeposit() {
    if (this.value == 1) {
      this.amount = this.fiatAmount
      this.transaction_method = 1
      this.transaction_method = 1
      this.transactionId = '';
      this.fiatDocImg = '';
      this.fiatPreVieImg = '';
      this.amountSubmitted = false;
      this.dialog.open(this.wiredTransfer, {
        width: "100%",
        height: "auto",
        maxWidth: "50rem",
        minWidth: "auto"
      })
    } else if (this.value == 2) {
      this.dialog.open(this.paypalpop, {
        width: "100%",
        height: "auto",
        minWidth: "auto",
        maxWidth: "40rem"
      })
    } else {
      this.clearStripFrom();
      this.years = this.generateArrayOfYears();
      this.months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      this.dialog.open(this.stripe, {
        width: "100%",
        height: "auto",
        minWidth: "auto",
        maxWidth: "35rem"
      })
    }
    this.dialog.afterAllClosed.subscribe((res) => {
      (this.payPalShow && this.value) == 2 ? window.location.reload() : '';
    })
  }

  clearStripFrom() {
    this.stripeform?.get('fullName').reset();
    this.stripeform?.get('Amount').reset();
    this.stripeform?.get('cardNumber').reset();
    this.stripeform?.get('cvv').reset();
  }

  generateArrayOfYears() {
    var min = new Date().getFullYear()
    var max = min + 9
    var years = []
    for (var i = min; i <= max; i++) {
      years.push(i)
    }
    return years
  }

  creditCheck(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let val = input.value?.toUpperCase();
    let trimmed = val.replace(/[^0-9A-Z]+/g, '');
    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }
    input.value = numbers.join(' ');

  }

  getBankList() {
    this.callApi.getBankDetails({}).subscribe((res: any) => {
      this.userBanks = res?.data?.data
      let user_bank: any = []
      let user_upi: any = []
      if (res.data.totalcount > 0) {
        this.userBanks.map((item: any) => {
          if (item.payment_method == 1) {
            user_bank.push(item)
          }
          else {
            user_upi.push(item)
          }
        })
      }
      this.userBanks.bank_count = user_bank.length

      this.userBanks.upi_count = user_upi.length

      let bankArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 1;
      });
      let upiArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 2;
      });
      this.userBankInfo = bankArr[0]
      this.userUpiInfo = upiArr[0]
    })
    this.callApi.adminBankDetails({}).subscribe((res: any) => {
      this.adminBanks = res?.data?.data

      if (Array.isArray(this.adminBanks)) {

        let bankArr = this.adminBanks.filter(function (item: any) {
          return item.payment_method == 1;
        });
        let upiArr = this.adminBanks.filter(function (item: any) {
          return item.payment_method == 2;
        });
        this.adminBankInfo = bankArr[0]
        this.adminUpiInfo = upiArr[0]
      }
    })
  }

  depositRequest() {
    this.amountSubmitted = true
    if ((this.transactionId == "") || (this.amount == "") || (this.fiatDocImg == "")) {
      return
    }
    let payload = {
      coin_id: this.data._id,
      user_bank_id: this.transaction_method == 1 ? this.userBankInfo?._id : this.userUpiInfo?._id,
      admin_bank_id: this.transaction_method == 1 ? this.adminBankInfo?._id : this.adminUpiInfo?._id,
      amount: this.amount,
      transaction_type: 1,
      transaction_id: this.transactionId,
      transaction_method: this.transaction_method,
      document_proof: this.fiatDocImg,
    }
    this.callApi.depositApproveRequest(payload).subscribe(async (res: any) => {
      if (res.success) {
        Swal.fire({
          iconHtml: '<i class="fa-solid fa-check"></i>',
          title: await translate('Success!', this.translateControl),
          text: await translate(res.message, this.translateControl)
        })
        this.amountSubmitted = false
        this.amount = ""
        this.dialog.closeAll();
      } else {
        Swal.fire({
          icon: 'error',
          title: await translate('Opps.....!', this.translateControl),
          text: await translate(res.message, this.translateControl)
        })
      }
    })
  }

  paypal() {
    this.payPalConfig = {
      currency: this.data?.slug,
      clientId: this.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.data?.slug,
            value: this.amount,
            breakdown: {
              item_total: {
                currency_code: this.data?.slug,
                value: this.amount
              }
            }
          }
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data: any, actions: any) => {
        actions.order.get().then((details: any) => {
        });

      },
      onError: async err => {
        if (this.amount == null) {
          this.callApi.showError(await translate('Amount Field is Required', this.translateControl))
        } else {
          this.callApi.showError(await translate(err, this.translateControl))
        }
      },
      onClientAuthorization: (data: any) => {
        let payload = {
          'trxid': data.id,
          'slug': this.data?.slug,
          // 'fee': this.depositMin?.deposit_fee
        }
        this.callApi.paypalTransaction(payload).subscribe(async (response: any) => {
          if (response.success == true) {
            this.showSuccess = true;
            // this.getWallet({},1);
            this.callApi.showSuccess(await translate(response.message, this.translateControl));
            this.dialog.closeAll()
          } else {
            this.callApi.showError(await translate(response.message, this.translateControl))
          }
        })
        this.showSuccess = true;
      },
      onClick: () => {
        this.payPalShow = true
      },
      onCancel: (data, actions) => {
        this.showCancel = true;
      },
    };
  }


  submitStripe() {
    this.payingsubmitted = true;
    this.stripeform.value.slug = this.data?.slug;
    this.stripeform.value.fee = this.data?.deposit_fee
    // this.stripeform = this.fb.group({
    //   parentesco: [''],
    //   estado: [null, [Validators.required]]

    // });
    if (this.stripeform.invalid) {
      return;
    }
    let payload = {
      ...this.stripeform.value,
      fee: this.data.fee,
      slug: this.data.slug
    }
    this.callApi.createStripe(payload).subscribe(async (res: any) => {
      if (res.success == true) {
        this.callApi.showSuccess(await translate(res.message, this.translateControl))
        this.dialog.closeAll()
      } else {
        this.callApi.showError(await translate(res.message, this.translateControl))
        this.dialog.closeAll()
      }
      this.payingsubmitted = false;
    })
  }

  onCardNumberChange(cardNumber: any): void {
    this.cardType = this.getCardType(cardNumber);
  }

  getCardType(cardNumber: any): any {
    const numberFormatted = cardNumber.replace(/\D/g, '');
    for (const cardType in this.cardPatterns) {
      if (this.cardPatterns[cardType].test(numberFormatted)) {
        return cardType;
      }
    }
    return 'Unknown';
  }

  numberValidation(event: any) {

  }
  amountKeypress(event:any){
    var charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 32 && charCode < 48 || charCode > 57 && charCode < 127){
      event.preventDefault();
      return false;
    }else{
      return true;
    }
  }

  keyPressNumbers(event:any){
    var charCode = (event.which) ? event.which : event.keyCode;
    if((charCode > 47 && charCode < 58)){
      return true;
    }else{
      event.preventDefault();
      return false;
    }
  }
}