import { Component,Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-viewlosedialog',
  templateUrl: './viewlosedialog.component.html',
  styleUrl: './viewlosedialog.component.scss'
})
export class ViewlosedialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}
}
