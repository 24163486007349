<section class="crypto-withdraw-page">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="col-lg-11 div_deposit-details rounded-4">
                    <div
                        class="div_deposit-details_title d-block d-xl-flex justify-content-between align-items-center mb-4">
                        <h5 class="fw-semibold mb-0">{{'Crypto Withdraw' | translate : translateControl | async}}</h5>
                        <button class="btn_theme-grey fw-medium mt-3 mt-xl-0" routerLink="/crypto-deposit">{{'Crypto
                            Deposit' | translate : translateControl | async}}<i
                                class="fas fa-arrow-right ms-2"></i></button>
                    </div>
                    <div class="div_deposit-details_amount mb-4">
                        <div class="row">
                            <!-- <div class="col-sm-8">
                                <label class="d-block small fw-medium mb-2">Amount</label>
                                <input type="text" class="form-control" />
                            </div> -->
                            <div class="col-sm-4">
                                <!-- <select name="fiat_type" class="form-control h-100" (change)="coinlist($event)"
                                    [(ngModel)]="coinId">
                                    <option value="">Select Coin</option>
                                    <ng-container *ngFor="let coin of coindata">
                                        <option [value]="coin._id" *ngIf="coin?.coin_type !==0">{{coin?.slug}}</option>
                                    </ng-container>
                                </select> -->
                                <button class="btn select-btn" *ngIf="!showCoin"
                                    (click)="openCryptoWithdraw(cryptoWithdraw)">
                                    <div class="d-flex align-items-center">
                                        <ng-container *ngIf="coinGetData?.image">
                                            <img [src]="imageUrl(coinGetData?.image)" [alt]="coinGetData?.name"
                                                class="img-fluid me-2" width="28" />
                                            <h6 class="fs-16 mb-0">{{coinGetData?.slug}}</h6>
                                        </ng-container>
                                        <div class="d-flex">
                                            <span class="fs-13 mb-0" *ngIf="!coinGetData?.image">{{'Select Coin'|
                                                translate :
                                                translateControl | async}}</span>
                                        </div>
                                    </div>
                                </button>
                                <button class="btn select-btn" *ngIf="showCoin">
                                    <div class="d-flex align-items-center">
                                        <ng-container>
                                            <img [src]="walletData?.walletDetails?.coins[0]?.image" alt="coinimg"
                                                class="img-fluid me-2" width="28" />
                                            <h6 class="fs-16 mb-0">{{walletData?.walletDetails?.coins[0]?.slug}}</h6>
                                        </ng-container>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="deposite_hole_div" *ngIf="kyc?.authentic_status==0 && kyc?.twofaActiveStatus == 0">
                        <div class="d-flex justify-content-center align-items-center deposit-disable text-center h-300">
                            <div class="mt-4">
                                <h6 class="fs-16 mb-0">{{'Please Enable 2FA'| translate : translateControl | async}}
                                </h6>
                                <div class="mt-2">
                                    <button class="btn_theme-primary" (click)="open2fa()">{{'Click Here'| translate :
                                        translateControl | async}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        *ngIf="kyc?.authentic_status == 1 || kyc?.authentic_status == 2 || kyc?.twofaActiveStatus == 2">
                        <div class="row" *ngIf="depositMin?.withdraw_status ==1">
                            <div class="col-lg-12 mt-3">
                                <div>
                                    <h6 class="fs-16 fw-semibold">{{'Payment Method'| translate : translateControl |
                                        async}}</h6>
                                </div>
                                <form [formGroup]="form" *ngIf="!fiatId" class="crypto-withdraw-from">
                                    <div class="form-group mt-3 ">
                                        <label for="exampleInputEmail1"
                                            class="wallet_address_label col-form-label">{{'To Address'|
                                            translate : translateControl | async}}</label>
                                        <div class="input-group wallet-form">
                                            <!-- <select name="" id="" formControlName="tokenNetwork"
                                                *ngIf="walletData?.walletDetails?.token" class="form-select mb-2">
                                                <option [value]="data?.network"
                                                    *ngFor="let data of walletData?.walletDetails?.wallet_details">
                                                    {{data?.network | uppercase | translate : translateControl | async}}
                                                </option>
                                            </select> -->
                                            <select name="" id="" formControlName="tokenNetworkSlug"
                                                *ngIf="walletData?.walletDetails?.token == true"
                                                class="form-select mb-2" (change)="token_changes($event)">
                                                <option *ngFor="let data of walletData?.walletDetails?.wallet_details"
                                                    [value]="data.network">
                                                    {{data?.network | uppercase | translate : translateControl | async}}
                                                </option>
                                            </select>
                                        </div>
                                        <input name="amt" formControlName="to_address" [(ngModel)]="add"
                                            [ngClass]="{'is-invalid': submitted && f['to_address'].errors}" type="text"
                                            class="form-control rounded-2" id="exampleInputPassword1"
                                            placeholder="{{'To Address'|translate : translateControl | async}}">
                                        <svg (click)="openPopup(listAddress)" xmlns="http://www.w3.org/2000/svg"
                                            width="16" height="16" fill="currentColor"
                                            class="float-end class_icon cursor-pointer bi bi-book" viewBox="0 0 16 16">
                                            <path
                                                d="M1 2.828c.885-.37 2.154-.769 3.388-.893 1.33-.134 2.458.063 3.112.752v9.746c-.935-.53-2.12-.603-3.213-.493-1.18.12-2.37.461-3.287.811zm7.5-.141c.654-.689 1.782-.886 3.112-.752 1.234.124 2.503.523 3.388.893v9.923c-.918-.35-2.107-.692-3.287-.81-1.094-.111-2.278-.039-3.213.492zM8 1.783C7.015.936 5.587.81 4.287.94c-1.514.153-3.042.672-3.994 1.105A.5.5 0 0 0 0 2.5v11a.5.5 0 0 0 .707.455c.882-.4 2.303-.881 3.68-1.02 1.409-.142 2.59.087 3.223.877a.5.5 0 0 0 .78 0c.633-.79 1.814-1.019 3.222-.877 1.378.139 2.8.62 3.681 1.02A.5.5 0 0 0 16 13.5v-11a.5.5 0 0 0-.293-.455c-.952-.433-2.48-.952-3.994-1.105C10.413.809 8.985.936 8 1.783" />
                                        </svg>

                                        <div *ngIf="submitted && f['to_address'].errors" class="invalid-feedback">
                                            <div class="align" *ngIf="f['to_address'].errors?.['required']">{{'Enter to
                                                address field is required'| translate : translateControl | async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group mt-3">
                                        <label for="exampleInputPassword1"
                                            class="wallet_address_label col-form-label">{{'Amount'|
                                            translate : translateControl | async}}</label>
                                        <input type="text" name="amt" appCopypaste appDecimalrestrict
                                            formControlName="amount"
                                            [ngClass]="{'is-invalid' : submitted && f['amount'].errors}" type="text"
                                            class="form-control rounded-2" id="exampleInputPassword1"
                                            [placeholder]="Amount">
                                        <div *ngIf="f['amount'].errors" class="invalid-feedback">
                                            <div class="align" *ngIf="f['amount'].errors?.['required']">
                                                {{'Enter amount field is required'| translate : translateControl |
                                                async}}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center mt-4 mb-3">
                                        <button type="button" class="button-secondary" (click)="submit()"
                                            [disabled]="myform?.invalid">
                                            <ng-container *ngIf="myform?.invalid">{{'Fill the Fields!'| translate :
                                                translateControl | async}}
                                            </ng-container>
                                            <ng-container *ngIf="!myform?.invalid"><i class="fa me-1"
                                                    [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{'Submit'|
                                                translate :
                                                translateControl | async}}</ng-container>
                                        </button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-lg-12 mt-sm-4 px-0" *ngIf="!fiatId">
                                <div class="wallet_content mt-2">
                                    <div class="crypto-inner-box">
                                        <p class="wallet_content_p">{{'Minimum Withdraw Limit :'| translate :
                                            translateControl | async}}
                                            {{depositMin?.min_withdraw_limit}}
                                            {{depositMin?.slug |
                                            uppercase | translate : translateControl | async}}</p>
                                        <p class="wallet_content_p">{{'Maximum Withdraw Limit :'| translate :
                                            translateControl | async}} {{depositMin?.max_withdraw_limit | translate :
                                            translateControl | async}}
                                            {{depositMin?.slug |
                                            uppercase | translate : translateControl | async}}</p>
                                        <p class="wallet_content_p">{{'Withdraw Fee'| translate : translateControl |
                                            async}} : {{depositMin?.withdraw_fee|tofixed}}%
                                            {{depositMin?.slug |
                                            uppercase | translate : translateControl | async}}</p>

                                    </div>

                                    <p class="wallet_content_p note_content">{{'NOTE : Withdraw may take from a few
                                        minutes to over 30 minutes.'| translate : translateControl | async}}</p>
                                    <p>{{'To see Withdraw history for this coin / token,'| translate : translateControl
                                        | async}} <a routerLink="/history" class="Wallet_a_link">{{'Click Here'|
                                            translate : translateControl | async}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-lg-6 mt-4 mt-xl-0">
                <h5 class="fw-semibold mb-4">{{'Withdraw' | translate : translateControl | async}}</h5>
                <h6 class="fs-16"><i class="fas fa-pencil-alt text_theme-primary me-1"></i> {{'Withdraw Instructions' |
                    translate : translateControl | async}}</h6>
                <!-- <h6 class="fs-13 fw-semibold">{{'Tips'| translate : translateControl | async }}</h6>
                <ol>
                    <li class="text-secondary">{{'Note: Please ensure your account details match the information
                        provided during Identity Verification. Inconsistent account information may result in
                        unsuccessful deposits, with funds returned within 7–14 working days for such cases' | translate
                        : translateControl | async}}.</li>
                    <li class="text-secondary">{{'Fiat Deposit and Fiat Balance currently do not support P2P Trading' | translate : translateControl | async}}.</li>
                </ol>
                <h6 class="fs-13 fw-semibold">{{'ETA' | translate : translateControl | async}}</h6>
                <p class="text-secondary">{{'Please note that during national holidays or under special circumstances, the crediting of your fiat deposit to your account may be subject to a delay of up to four (4) working days' | translate : translateControl | async}}.
                </p> -->
                <ol class="text-secondary">
                    <li class="mb-2">{{'Supported Currencies: AUD , USD , EUR crypto currencies that are accepted for
                        withdrawals on the platform.'| translate : translateControl | async}}</li>
                    <li class="mb-2">{{'Withdraw Methods: Information on the different methods available for withdraw
                        crypto currency, such as bank transfers, credit/debit card payments, and other payment
                        options.'|translate : translateControl | async}}
                    </li>
                    <li class="mb-2">{{'Withdraw Limits: Details on the minimum and maximum withdraw amounts for crypto
                        currency withdrawals.'| translate : translateControl | async}}
                        <div class="fw-bold">
                            <span class="d-block">{{'Min.Limit'| translate : translateControl | async}} -
                                {{depositMin?.min_withdraw_limit}} {{depositMin?.slug |uppercase }}</span>
                            <span>{{'Max.Limit'| translate : translateControl | async}} -
                                {{depositMin?.max_withdraw_limit}} {{depositMin?.slug |
                                uppercase| translate : translateControl | async}}</span>
                        </div>
                    </li>
                    <li class="mb-2">{{'Withdraw Fees: Information on withdraw fees that may be charged for withdraw
                        crypto currency into the account.'| translate : translateControl | async}}
                        <div class="fw-bold">
                            {{'Withdraw Fees'| translate : translateControl | async}} -
                            {{depositMin?.withdraw_fee|tofixed}}%
                            {{depositMin?.slug | uppercase| translate : translateControl | async}}
                        </div>
                    </li>
                    <li class="mb-2">{{'Security Measures: Information on the security measures in place to protect
                        users crypto withdrawals and personal information & Google 2FA will protect user transaction
                        securely & perfectly.'| translate : translateControl | async}}</li>
                    <li class="mb-2">{{'Customer Support: Contact information for customer support in case users
                        encounter any issues or have questions about crypto withdrawals.'| translate : translateControl
                        | async}}</li>
                    <li class="mb-2">{{'Disclaimer: A disclaimer outlining the risks associated with crypto withdrawals
                        and advising users to conduct their own research before making a withdraw.'| translate :
                        translateControl | async}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>
<ng-template #GoogleAuthenticate>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
    </button>
    <div class="">
        <div class="p-3">
            <div class="login_div email-auth">

                <!-- <form [formGroup]="otp_form">
                    <div class="form-group add_form_group">
                        <input formControlName="otp" (keypress)="keyPressNumbers($event)" maxlength="6" type="text"
                            class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"
                            [placeholder]="Enter_2FA_code">
                        <div *ngIf="isSubmited" class="text-danger mt-2">
                            <p *ngIf="formVerify['otp'].errors?.['required']"> {{'OTP is required'| translate :
                                translateControl | async}} </p>
                            <p *ngIf="formVerify['otp'].errors?.['minlength']||formVerify['otp'].errors?.['maxlength']">
                                {{'OTP Must be 6 Digits'| translate : translateControl | async}}</p>
                            <span *ngIf="otp?.errors?.['required']" class="text-danger">{{'OTP is must' | translate :
                                translateControl | async}}</span>
                            <span *ngIf="otp?.errors?.['pattern']" class="text-danger">{{'OTP must be 6 digits' |
                                translate : translateControl | async}}</span>
                        </div>
                        <div class="btn_div text-center">
                            <button type="submit" (click)="userverifyAuthenticator()"
                                class="btn_theme-primary border-0 mt-3">{{'Submit'| translate : translateControl |
                                async}}</button>
                            <div class="row mt-3">
                                <div class=" add_right_forgot" style="text-align: end;">
                               
                                </div>
                                <div class="add_right_forgot" style="text-align: end;">
                                    <a class="theme-text-color-1" style="cursor: pointer;" (click)="logOut()">{{'Back?'|
                                        translate : translateControl | async}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form> -->

                <div class="mb-3 mb-sm-0 px-3 py-4">
                    <button mat-icon-button class="close-button bg-transparent" [mat-dialog-close]="true">
                        <mat-icon class="close-icon">close</mat-icon>
                    </button>
                    <h5 class="fw-semibold mb-4" *ngIf="user_tfa?.authentic_status === '1'">{{'Google Authenticate
                        Code'| translate : translateControl |
                        async}}</h5>

                    <div class="mt-3">
                        <input type="text" class="form-control shadow-none" placeholder="Enter OTP here"
                            [formControl]="otp" (keypress)="keyPressNumbers($event)">
                        <span *ngIf="isSubmited">
                            <span *ngIf="otp?.errors?.['required']" class="text-danger">{{'OTP is must' | translate
                                :
                                translateControl | async}}</span>
                            <span *ngIf="otp?.errors?.['pattern']" class="text-danger">{{'OTP must be 6 digits' |
                                translate : translateControl | async}}</span>
                        </span>

                    </div>
                    <div class="text-center">
                        <button class="btn button-primary mt-3 me-2" (click)="userverifyAuthenticator()"
                            type="button">{{'Submit'|
                            translate : translateControl | async }}
                            <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '6px' }"
                                title="Please Wait..."></ngx-loading>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #cryptoWithdraw>
    <div class="">
        <button mat-dialog-close="close" class="close-button" mat-icon-button>
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <h5 class="fw-semibold px-4 py-3 mb-0">{{'Crypto withdraw' | translate : translateControl | async}}</h5>
    </div>
    <div class="input-group px-4">
        <span class="input-group-text">
            <svg _ngcontent-wjr-c129="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                viewBox="0 0 16 16" class="bi bi-search">
                <path _ngcontent-wjr-c129=""
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0">
                </path>
            </svg>
        </span>
        <input type="text" placeholder="{{'What are you searching for?' | translate : translateControl | async }}"
            class="form-control border" [(ngModel)]="searchText" (keyup)="filterData()" />

    </div>
    <mat-dialog-content>
        <ul class="ul_select-coin list-unstyled">
            <div *ngIf="(coindata |filter:searchText)?.length > 0; else notFound">
                <ng-container *ngFor="let coin of coindata |filter:searchText">
                    <div *ngIf="coin?.coin_type !==0">
                        <li (click)="coinSelect(coin, 1)" class="cursor-pointer rounded-2 p-2">
                            <img [src]="imageUrl(coin?.image)" alt={{coin?.slug}} width="28" class="img-fluid" /> <span
                                class="fs-6 text-dark ms-2 align-middle">{{coin?.slug}}</span>
                        </li>
                    </div>
                </ng-container>
            </div>
        </ul>
    </mat-dialog-content>
</ng-template>
<ng-template #notFound>
    <p class="fs-5 text-dark fw-medium text-center mb-0">{{'Not found' | translate : translateControl | async}}</p>
</ng-template>
<ng-template #otpChangeContent>
    <div class="mb-3 mb-sm-0 px-3 py-4">
        <button mat-icon-button class="close-button bg-transparent" [mat-dialog-close]="true">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
        <h5 class="fw-semibold mb-4 pt-4 pt-md-0" *ngIf="user_tfa?.authentic_status === '2'"><span> {{ 'Email
                Authenticate Code' | translate : translateControl | async }}</span></h5>
        <div class="mt-3">
            <input type="text" class="form-control shadow-none" placeholder="Enter OTP here" [formControl]="otp"
                (keyup)="otpValueCheck($event)">
            <span *ngIf="otpSubmit">
                <span *ngIf="otp?.errors?.['required']" class="text-danger">{{'OTP is must' | translate :
                    translateControl | async}}</span>
                <span *ngIf="otp?.errors?.['pattern']" class="text-danger">{{'OTP must be 6 digits' |
                    translate : translateControl | async}}</span>
            </span>
        </div>
        <div class="text-center">
            <button class="btn button-primary mt-3 me-2" (click)="userverifyOTP()" type="button">{{'Submit'|
                translate : translateControl | async }}
                <ngx-loading [show]="loading" [config]="{ backdropBorderRadius: '6px' }"
                    title="Please Wait..."></ngx-loading>
            </button>
        </div>
    </div>
</ng-template>

<ng-template #listAddress>
    <mat-dialog-content class="paypal stripcard">
        <mat-icon class="close-icon" id="address-id" #address_id [mat-dialog-close]="true">{{'close'| translate : translateControl |
            async}}</mat-icon>
        <div class="form-group">
            <div class="">
                <h5 class="fw-semibold">{{'Select'| translate : translateControl | async}} {{depositMin?.slug}}
                    {{'Address'| translate : translateControl | async}}</h5>
                <!-- <div class="input-group mb-3">
                    <input type="text" class="form-control" placeholder="address" [(ngModel)]="searchaddress"  aria-label="Recipient's username"
                        aria-describedby="basic-addon2" (keyup)="filterAddress()" >
                    <span class="input-group-text border-0" id="basic-addon2">search</span>
                </div> -->
                <h6 class="fs-16 my-3">{{'EVM Address'| translate : translateControl | async}}</h6>
                <ul class="list-unstyled px-0">
                    <li class="d-flex align-items-center justify-content-between border rounded p-3 mt-2"
                        *ngFor="let data of addressList">
                        <div (click)="getAddress(data)" class="cursor-pointer">
                            <div class="address_ellipsis mb-2">
                                <span>{{data?.wallet_address}}</span>
                            </div>
                            <h6 class="fs-13 mb-0 fw-semibold"><span>{{data?.remarks | translate : translateControl |
                                    async}}</span></h6>
                        </div>
                        <div>
                            <svg *ngIf="isIconClicked == false || (this.add != data?.wallet_address && isIconClicked == true)"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="grey"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                            <svg *ngIf=" this.add == data?.wallet_address && isIconClicked == true"
                                xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green"
                                class="bi bi-check-circle-fill" viewBox="0 0 16 16" style="fill: green !important;">
                                <path
                                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                            </svg>
                        </div>
                    </li>
                </ul>
            </div>
            <div>
                <button type="button" class="btn btn-warning w-100 mt-4" (click)="addwithdrawAddressBook()">{{'Add
                    withdraw
                    address'| translate : translateControl | async}}</button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>>