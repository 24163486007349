<div class="bg-orange login-bg">
    <div class="container">
        <div class="shadow bg-color m-5">
            <div class="row align-items-center border border-white m-auto">
                <div class="col-xl-5 text-center p-4">
                    <h1 class="text-center justify-content-center pb-3 mb-0">If
                        you want <span class="text-theme-1">More
                            Luck,</span> take more chances</h1>
                    <img src="./assets/img/addus_logo_white.png" alt="logo"
                        width="200" class="bg-filter pb-3">
                    <p class="mb-0">{{"business@firebeetechnoservices.com"}}</p>
                </div>
                <div class="col-xl-7 p-0">
                    <div class="card border-0 bg-white">

                        <div class="card-body ">
                            <!-- <img src="./assets/img/cancel.png" alt="cancel"> -->
                            <h2 class="card-title text-center mb-4">Create
                                Account</h2>
                            <div
                                class="d-md-flex justify-content-center text-center">
                                <button type="button"
                                    class="btn btn-dark mb-2 mb-md-0 me-1">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        fill="currentColor"
                                        class="bi bi-google"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z">
                                        </path>
                                    </svg>
                                    Sign up with Google
                                </button>
                                <button type="button" class="btn btn-primary">
                                    <svg xmlns="http://www.w3.org/2000/svg"
                                        width="24" height="24"
                                        fill="currentColor"
                                        class="bi bi-facebook"
                                        viewBox="0 0 16 16">
                                        <path
                                            d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951" />
                                    </svg>
                                    Sign up with Facebook
                                </button>
                                <!-- <button class="btn"><i class="bi bi-google"></i> Home</button>
                                <button type="button" class="btn btn-outline-primary">Sign up with Google</button>
                                <button type="button" class="btn btn-outline-primary">Sign up with Facebook</button> -->
                            </div>
                            <div
                                class="d-flex justify-content-center align-items-center m-2">
                                <hr class="line">
                                <h4>OR</h4>
                                <hr class="line">
                            </div>
                            <form [formGroup]="regForm">
                                <div class="mt-2">
                                    <div class="text-start">
                                        <label>User Name</label>
                                        <div>
                                            <input type="text"
                                                class="form-control p-2"
                                                id="name" name="name"
                                                placeholder="Name"
                                                formControlName="user_name">
                                        </div>
                                        <div
                                            *ngIf="isSubmitted && regForm.controls.user_name.invalid">
                                            <p
                                                *ngIf="regForm.controls.user_name?.errors?.required"
                                                class="text-danger mt-3">
                                                * User Name field is
                                                Required</p>
                                        </div>
                                    </div>

                                    <div class="text-start mt-2">
                                        <label>Email</label>
                                        <div>
                                            <input type="text"
                                                class="form-control p-2"
                                                id="name" name="name"
                                                placeholder="Email"
                                                formControlName="email">
                                        </div>
                                        <div
                                            *ngIf="isSubmitted  && regForm.controls.email.invalid">
                                            <p
                                                *ngIf="regForm.controls.email?.errors?.required"
                                                class="text-danger mt-3">
                                                *Email field is Required</p>
                                            <p
                                                *ngIf="regForm.controls.email?.errors?.pattern"
                                                class="text-danger mt-3">
                                                Enter
                                                Valid Email</p>
                                        </div>
                                    </div>

                                    <!-- <div class="text-start mt-2">                          
                                        <div class="icon-password">
                                            <label>Password</label>
                                            <input type="text" class="form-control p-2" id="name" name="name"
                                                placeholder="Password" formControlName="password">
                                        </div>
                                        <div
                                            *ngIf="isSubmitted  && regForm.controls.password.invalid">
                                            <p *ngIf="regForm.controls.password?.errors?.required" class="text-danger mt-3">
                                                *Password field is Required</p>
                                            <p *ngIf="regForm.controls.password?.errors?.pattern" class="text-danger mt-3">
                                                Password must include at least one uppercase letter, one lowercase
                                                letter, one number, and one special character.</p>
                                        </div>
                                        <div class="password__show">
                                            <i class="fa" 
                                               [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}" 
                                               (click)="showPassword = !showPassword" 
                                               aria-label="Toggle password visibility">
                                            </i>
                                        </div>
                                    </div> -->

                                    <div class="text-start mt-2 ">
                                        <label>Password</label>
                                        <div class="icon-password">
                                            <input
                                                [type]="showPassword ? 'text' : 'password'"
                                                class="form-control p-2"
                                                id="password" name="password"
                                                placeholder="Password"
                                                formControlName="password">

                                            <div class="password__show">
                                                <i class="fa"
                                                    [ngClass]="{'fa-eye': showPassword, 'fa-eye-slash': !showPassword}"
                                                    (click)="togglePassword()"
                                                    aria-label="Toggle password visibility">
                                                </i>
                                            </div>
                                        </div>
                                        <div
                                            *ngIf="isSubmitted && regForm.controls.password.invalid">
                                            <p
                                                *ngIf="regForm.controls.password?.errors?.required"
                                                class="text-danger mt-3">
                                                *Password field is Required
                                            </p>
                                            <p
                                                *ngIf="regForm.controls.password?.errors?.pattern"
                                                class="text-danger mt-3">
                                                Password must include at
                                                least one uppercase letter,
                                                one lowercase
                                                letter, one number, and one
                                                special character.
                                            </p>
                                        </div>
                                    </div>


                                    <div class="text-start mt-2">
                                        <label>Confirm Password</label>
                                        <div class="icon-password">
                                            <input type="text"
                                                class="form-control p-2"
                                                id="confirmPassword" name="confirmPassword"
                                                placeholder="Confirm Password"
                                                [type]="showConfirmPassword ? 'text' : 'password'"
                                                formControlName="confirmPassword">
                                            <div class="password__show">
                                                <i class="fa"
                                                    [ngClass]="{'fa-eye': showConfirmPassword, 'fa-eye-slash': !showConfirmPassword}"
                                                    (click)="toggleConfirmPassword()"
                                                    aria-label="Toggle confirm password visibility">
                                                </i>
                                            </div>
                                        </div>
                                        <div *ngIf="isSubmitted && regForm.controls.confirmPassword.invalid">
                                            <p *ngIf="regForm.controls.confirmPassword?.errors?.required" class="text-danger mt-3">
                                                *Confirm Password field is Required
                                            </p>
                                            <div *ngIf="regForm.controls.confirmPassword.errors?.mustMatch" class="text-danger mt-3">
                                                Mismatch password
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="button text-center mt-4">
                                    <div>
                                        <button type="submit"
                                            (click)="onSubmit()"
                                            class="submit btn btn-warning p-2">Create
                                            Account</button>
                                    </div>
                                    <div class="mt-2">
                                        <p
                                            class="text-secondary-emphasis mb-0">Don't
                                            have an account? <a
                                                routerLink="/login"><span
                                                    class="text-theme-1 fw-bold">Login</span></a>
                                        </p>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
