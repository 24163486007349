<section class="dashboard_section">
  <div class="container">
    <div class="card p-4">

      <div class="row align-items-end">
        <div class="col-lg-3 mt-3">
          <mat-label>Ticket Name</mat-label>
          <mat-select class="form-control" placeholder="Select TicketName" disabled>
            <mat-option value="0">123456</mat-option>
            <mat-option value="0">123456</mat-option>
            <mat-option value="0">123456</mat-option>
          </mat-select>
        </div>
        <div class="col-lg-3 mt-3">
          <mat-label>Ticket ID</mat-label>
          <mat-select class="form-control" placeholder="Select Ticket ID" disabled>
            <mat-option value="0">123456</mat-option>
            <mat-option value="0">123456</mat-option>
            <mat-option value="0">123456</mat-option>
          </mat-select>
        </div>
        <div class="col-lg-3 mt-3 datepicker-container">
          <mat-label>Date</mat-label>
          <div>
            <input matInput class="form-control input-icon" [matDatepicker]="picker" placeholder="Choose a date">
            <mat-datepicker-toggle matSuffix [for]="picker" class="pick"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>      
        <div class="col-lg-3 mt-3">
          <button mat-raised-button class="search-button me-2">Search</button>
          <button mat-raised-button>Clear</button>
        </div>
      </div>
  
      <!-- <div class="table-responsive mt-5">
          <table mat-table [formGroup]="dataSource" class="userresults-table">
            <ng-container matColumnDef="serialNumber">
              <th mat-header-cell *matHeaderCellDef>S.No</th>
              <td mat-cell *matCellDef="let element">{{element.serialNumber}}</td>
            </ng-container>
    
            <ng-container matColumnDef="profileImage">
              <th mat-header-cell *matHeaderCellDef>Profile Image</th>
              <td mat-cell *matCellDef="let element">{{element.profileImage}}</td>
            </ng-container>
    
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let element">{{element.date}}</td>
            </ng-container>
    
            <ng-container matColumnDef="ticketId">
              <th mat-header-cell *matHeaderCellDef>Ticket ID</th>
              <td mat-cell *matCellDef="let element">{{element.ticketId}}</td>
            </ng-container>
    
            <ng-container matColumnDef="ticketName">
              <th mat-header-cell *matHeaderCellDef>Ticket Name</th>
              <td mat-cell *matCellDef="let element">{{element.ticketName}}</td>
            </ng-container>
    
            <ng-container matColumnDef="ticketPrice">
              <th mat-header-cell *matHeaderCellDef>Ticket Price</th>
              <td mat-cell *matCellDef="let element">{{element.ticketPrice}}</td>
            </ng-container>
    
            <ng-container matColumnDef="status">
              <th mat-header-cell *matHeaderCellDef>Status</th>
              <td mat-cell *matCellDef="let element">{{element.status}}</td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
      </div> -->
      <div class=" table-responsive mt-4">
        <table class=" userresults-table w-100 ">
          <thead>
            <tr>
              <th>S.No</th>
              <th>ProfileImage</th>
              <th>Date</th>
              <th>Ticket ID</th>
              <th>TicketName</th>
              <th>TicketPrice</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>img</td>
              <td>23.07.2024</td>
              <td>25687</td>
              <td>saturday</td>
              <td>$ 5000</td>
              <td>win-$ 5000</td>
            </tr>
            <tr>
              <td>1</td>
              <td>img</td>
              <td>23.07.2024</td>
              <td>25687</td>
              <td>saturday</td>
              <td>$ 5000</td>
              <td>win-$ 5000</td>
            </tr>
            <tr>
              <td>1</td>
              <td>img</td>
              <td>23.07.2024</td>
              <td>25687</td>
              <td>saturday</td>
              <td>$ 5000</td>
              <td>win-$ 5000</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</section>