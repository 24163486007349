import { Component, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';

@Component({
  selector: 'app-userresults',
  templateUrl: './userresults.component.html',
  styleUrl: './userresults.component.scss'
})
export class UserresultsComponent {
  @ViewChild(MatDatepicker) picker!: MatDatepicker<Date>;
  dataSource!:FormGroup;

  displayedColumns: string[] = ['serialNumber', 'profileImage', 'date', 'ticketId', 'ticketName', 'ticketPrice', 'status'];
  // dataSource = [
  //   { serialNumber: 1, profileImage: 'img', date: '23.07.2024', ticketId: 25687, ticketName: 'Saturday', ticketPrice: '$5000', status: 'win-$5000' },
  //   // Add more data as needed
  // ];
}