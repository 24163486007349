import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TicketManagementService {
  addToCart(ticket: any) {
    throw new Error('Method not implemented.');
  }
  // BehaviorSubject to hold the array of ticket objects
  private ticketsSubject = new BehaviorSubject<any[]>(this.loadTicketsFromLocalStorage())

  // Observable to expose the tickets data
  tickets$ : Observable<any[]> = this.ticketsSubject.asObservable()

  private notificationCountSubject = new BehaviorSubject<number>(0);
  notificationCount$ = this.notificationCountSubject.asObservable();

  updateNotificationCount(count: number): void {
    this.notificationCountSubject.next(count);
  }

  constructor() { }
// Method to update the tickets data
setTickets(tickets: any[]) {
  this.ticketsSubject.next(tickets);
  this.saveTicketsToLocalStorage(tickets);
}

addTicket(ticket: any) {
  const currentTickets = this.ticketsSubject.value;
    const updatedTickets = [...currentTickets, ticket];
    this.ticketsSubject.next(updatedTickets);
    this.saveTicketsToLocalStorage(updatedTickets);
    this.saveNotificationToLocalStorage(updatedTickets.length);
}

removeTicket(ticketId: string) {
  const currentTickets = this.ticketsSubject.value;
  const updatedTickets = currentTickets.filter(ticket => ticket.ticket_no !== ticketId);
  
  this.ticketsSubject.next(updatedTickets);
  this.saveTicketsToLocalStorage(updatedTickets);
  this.saveNotificationToLocalStorage(updatedTickets.length);
}

// Save tickets to local storage
private saveTicketsToLocalStorage(tickets: any[]) {
  localStorage.setItem('tickets', JSON.stringify(tickets));
}

// Load tickets from local storage
private loadTicketsFromLocalStorage(): any[] {
  const tickets = localStorage.getItem('tickets');
  return tickets ? JSON.parse(tickets) : [];
}
private saveNotificationToLocalStorage(notificationCount: any) {
  localStorage.setItem('notification', JSON.stringify(notificationCount));
}

clearTickets(): void {
  this.ticketsSubject.next([]);
  this.saveTicketsToLocalStorage([]);
  this.updateNotificationCount(0);
}

}
