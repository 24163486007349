import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private callApi: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = localStorage.getItem('access_token')
    if (!token) {
      this.callApi.getToken();
      this.callApi.showError('Please Login First !');
      this.router.navigate(['/login']);
    }
    return true;
  }

}
@Injectable({
  providedIn: 'root'
})
export class AllowedSite implements CanActivate {
  constructor(private router: Router, private callApi: UserService) { }
  allowedRouteData : any ;
  constRoute = ["Trade","Earn","NFT","DEX","DEFI"]
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this.callApi.subscribeEvent().subscribe((res:any)=>{
        
        if(res.EventName=="allowedRoute"){
          this.allowedRouteData = res.EventDetails     
             
          for(let i = 0 ; i < this.allowedRouteData?.length ; i++){    
            let storedData = this.allowedRouteData[i][this.allowedRouteData[i].name] 
            if(this.constRoute[i]==this.allowedRouteData[i].name){
              for(let j = 0 ; j < storedData.length ; j++){
                if(storedData[j].router==this.router.url){
                  if(!storedData[j].data){
                    this.router.navigate(['/'])
                    this.callApi.showError('Admin Disabled the Module')
                  }
                }
              }
            }           
          }
        }
      })
    return true;
  }

}

@Injectable({
  providedIn: 'root'
})



@Injectable({
  providedIn: 'root'
})
export class TfaGuard implements CanActivate {
  private token:any
  status: any;
  status_check: boolean = false;
  constructor(private userService:UserService ,private router:Router){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      this.token = localStorage.getItem('access_token')
      if(this.token){
        this.userService.tfaStatus().subscribe((res:any)=>{
          let user_data = res.data
          if(user_data.authentic_status==1 || user_data.authentic_status == 2){
            if(user_data.twofaActiveStatus == 0){
            this.router.navigate(['/tfa_verification'])
            }
            }
        })
    }
    return true;
  }
  
}

