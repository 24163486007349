import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectService } from '../services/translate/select.service';
import { UserService } from '../services/user.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-tfa-verification',
  templateUrl: './tfa-verification.component.html',
  styleUrl: './tfa-verification.component.scss'
})
export class TfaVerificationComponent {
  public translateControl: any;

  user_tfa:any
  otp_form:any
  isSubmited:boolean=false
  Enter_2FA_code: any;
  subscribe:any;

  constructor(private fb:FormBuilder,
    private router:Router,
    public selectService: SelectService,
    private callApi:UserService,
    private http: HttpClient){}

  ngOnInit(): void {
    this.otpForm()
    this.selectService.getSharedData().subscribe(data => {
      this.translateControl = data
      if(this.translateControl == 'es'){
        this.Enter_2FA_code = 'Ingrese el código 2FA..'
          }
          else if(this.translateControl == 'fr'){
            this.Enter_2FA_code = 'Entrez le code 2FA..'
          }else{
            this.Enter_2FA_code = 'Enter 2FA code..'
          }
        
    })
  }
  tfaStatus(): Observable<any> {
    return this.http.get<any>('');
  }

  otpForm() {
    this.otp_form = this.fb.group({
      otp: ['', [Validators.required]]
    });
  
    this.callApi.tfaStatus().subscribe(async(res: any) => {
      this.user_tfa = res.data;
      console.log(this.user_tfa,"gggggggg");
      
      if (this.user_tfa?.authentic_status === '2') {
        this.emailAuthentication();
      }
    }, (error: any) => {
      console.error('Error fetching TFA status', error);
    });
  }
  
 
  emailAuthentication(){
    this.callApi.emailAuthentication().subscribe( async (resp:any)=>{
      this.callApi.showInfo( resp?.message)
    })
  }

  get formVerify(){return this.otp_form.controls}

  verifyOTP(){
    this.isSubmited=true
    if(this.otp_form.valid){
      let payload = {
        status:"0",
        ...this.otp_form.value
      }
     this.callApi.checkTfa(payload).subscribe(async (res:any)=>{
      if(res.success){
        // this.callApi.publishEvent({ 'EventName': 'header_reload', 'EventDetail': '' })
        this.callApi.showSuccess(res.message)
        this.router.navigate(['/welcome'])
      }else{
        this.callApi.showError(res.message)
      }
     })
    }
  }
  emailTfaVerify(){
    this.isSubmited=true;
    if(this.otp_form.valid){
      console.log(this.otp_form,"ffffffffff ");
      
      let payload ={
       twofa_otp:this.otp_form.get('otp').value,
       status :"0"
      }

      this.callApi.otpEmailVerification(payload).subscribe(async (res:any)=>{
        if(res.success){
          console.log("here");
          
          // this.callApi.publishEvent({ 'EventName': 'header_reload', 'EventDetail': '' })
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/welcome'])
        }else{
          this.callApi.showError(res.message)
        }
      })
    }
  }
  logOut(){
    this.callApi.logOut().subscribe((res: any)=>{
      if(res.success){
        localStorage.clear();
        this.router.navigate(['/login'])
      }
      else{
        localStorage.clear();
        this.router.navigate(['/login'])
      }
      // this.callApi.publishEvent({ 'EventName': 'header_reload', 'EventDetail': '' })
    })  
  }
}



