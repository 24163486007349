<section class="wave img-fluid">
    <div class="container">
        <div class="row ">
            <div class="col d-flex align-items-center track_section mx-0">
                <div class="shadow rounded-5 bg-white p-5 text-align-start">
                    <h4>Track an Order</h4>
                    <form [formGroup]="MyorderForm" (ngSubmit)="onclick()">
                        <div class="mb-3">
                            <label class="form-label">Order Number</label>
                            <input type="number" class="form-control" id="exampleInputNumber"
                                aria-describedby="emailHelp" placeholder="Order ID" formControlName="OrderId">
                            <div
                                *ngIf="isSubmitted || (MyorderForm.controls.OrderId.touched || MyorderForm.controls.OrderId.dirty && MyorderForm.controls.OrderId.invalid)">
                                <p *ngIf="MyorderForm.controls.OrderId?.errors?.required" class="text-danger">
                                    *Required</p>
                            </div>
                        </div>
                        <div class="mb-3">
                            <label class="form-label">Mobile Number</label>
                            <input type="number" class="form-control" id="exampleInputNumber1"
                                placeholder="Number" formControlName="Number">
                            <div
                                *ngIf="isSubmitted || (MyorderForm.controls.Number.touched || MyorderForm.controls.Number.dirty && MyorderForm.controls.Number.invalid)">
                                <p *ngIf="MyorderForm.controls.Number?.errors?.required" class="text-danger">
                                    *Required</p>
                                <p *ngIf="MyorderForm.controls.Number?.errors?.minlength" class="text-danger">
                                    Minimum Length should be 10</p>
                            </div>
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-1">
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
