import { ChangeDetectorRef, HostListener, Pipe, PipeTransform } from '@angular/core';
import translate from 'translate';
import { SelectService } from './select.service';

@Pipe({
  name: 'translate',
  pure: true // Make the pipe impure

})
export class TranslatePipe implements PipeTransform {
  current_language: any
  hostChange:any;

  constructor(public selectService: SelectService){
    this.selectService.selectedValue.subscribe(value => {
      this.hostChange = value
     });
  }
  async transform(value: any, translateControl: any): Promise<any> {
    let text = await translate(value, this.hostChange);
    return (text)
  }

}
