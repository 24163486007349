import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
// import { SelectService } from 'src/app/service/translate/select.service';
// import { UserService } from 'src/app/service/user.service';
// import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import translate from 'translate';
import { environment } from '../../../environments/environment.development';
import { UserService } from '../../services/user.service';
import { SelectService } from '../../services/translate/select.service';

@Component({
  selector: 'app-fiat-withdraw',
  templateUrl: './fiat-withdraw.component.html',
  styleUrls: ['./fiat-withdraw.component.scss']
})
export class FiatWithdrawComponent implements OnInit {
  public translateControl: any;
  userBanks: any
  transaction_method: any
  userBankInfo: any
  userUpiInfo: any
  amount: any
  upiSubmit: boolean = false
  title: any;
  value: any;
  @ViewChild('withDraw') withDraw: ElementRef | any
  adminBankInfo: any;
  adminBanks: any;
  adminUpiInfo: any;
  Fiatdata: any = [];
  fiatSlug: any;
  data: any = {};
  fiat: any;
  fiatAmount: any
  backendend = environment.IMAGE_URL
  eti!: string;
  Full_Name!: string;
  Card_Number!: string;
  amount_field!: string;
  selected!: string;
  showCoin: boolean = false
  isSelectionMade = false;


  constructor(private callApi: UserService, private dialog: MatDialog, private router: Router, private services: SelectService, private route: ActivatedRoute) { }
  ngOnInit(): void {
    this.getFiatList();
    this.getBankList();
    this.services.getSharedData().subscribe((res: any) => {
      this.translateControl = res
      if (this.translateControl == 'es') {
        this.amount_field = 'Cantidad'
        this.eti = 'Ingrese el ID de la transacción',
          this.Full_Name = 'Nombre completo',
          this.Card_Number = 'Número de tarjeta',
          this.selected = 'Seleccionar'
      } else if (this.translateControl == 'fr') {
        this.amount_field = 'Montant'
        this.eti = 'Entrez l identifiant de la transaction',
          this.Full_Name = 'Nom et prénom',
          this.Card_Number = 'Numéro de carte',
          this.selected = 'Sélectionner'
      } else {
        this.amount_field = 'Amount'
        this.eti = 'Enter Transaction Id',
          this.Full_Name = 'Full Name',
          this.Card_Number = 'Card Number',
          this.selected = 'Select'
      }
    })
    this.route.queryParams.subscribe((queryParams: any) => {
      if (queryParams.id) {
        this.fiatSlug = queryParams.id ? queryParams.id : this.fiatSlug;
        this.data._id = this.fiatSlug
        this.showCoin = true
      }
    })
  }



  getFiatList() {
    this.callApi.getTradeHistoryList().subscribe((res: any) => {
      this.fiat = res?.data?.coin
      this.fiat.map((item: any) => {
        if (item.coin_type == 0) {
          this.Fiatdata.push(item)
        }
      })
      this.route.queryParams.subscribe((queryParams: any) => {
        if (queryParams.id) {
          this.fiatSlug = queryParams.id;
          let dataSlug = this.Fiatdata.filter((item: any) => item._id === queryParams.id);
          this.data.slug = dataSlug[0]?.slug;
          this.selectFiat({ value: dataSlug[0]?._id })
        }
        else {
          this.fiatSlug = this.Fiatdata[0]?._id
          this.data.slug = this.Fiatdata[0]?.slug
        }
      })
      this.data.fee = this.Fiatdata[0]?.deposit_fee
      this.data.withdraw_fee = this.Fiatdata[0]?.withdraw_fee
      this.data.min_withdraw_limit = this.Fiatdata[0]?.min_withdraw_limit
      this.data.max_withdraw_limit = this.Fiatdata[0]?.max_withdraw_limit
    })
  }
  selectFiat(event: any) {
    this.isSelectionMade = true;
    this.Fiatdata.map((item: any) => {
      if (event?.value == item?._id) {
        this.data = item
      }
    })
  }


  addbank() {
    this.router.navigate(['/bank-details'])
    this.dialog.closeAll()
  }

  changeBank(event: any, on: any, type: any) {
    let index = event.target.value
    if (type == 'bank') {
      if (on == 'admin') {
        this.adminBankInfo = this.adminBanks[index]
      } else {
        this.userBankInfo = this.userBanks[index]
      }
    } else {
      if (on == 'admin') {
        this.adminUpiInfo = this.adminBanks[index]

      } else {
        this.userUpiInfo = this.userBanks[index]
      }
    }
  }
  setPaymentType(type: any) {
    this.transaction_method = type
  }
  selectType(data: any, type: any) {
    this.title = data
    this.value = type
  }

  submitWithdraw() {
    this.amount = this.fiatAmount
    this.transaction_method = 1
    this.upiSubmit = false
    this.value == 1
    this.dialog.open(this.withDraw, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "40rem"
    })
  }

  getBankList() {
    this.callApi.getBankDetails({}).subscribe((res: any) => {
      this.userBanks = res?.data?.data

      let user_bank: any = []
      let user_upi: any = []
      if (res.data.totalcount > 0) {
        this.userBanks.map((item: any) => {
          if (item.payment_method == 1) {
            user_bank.push(item)
          }
          else {
            user_upi.push(item)
          }
        })
      }
      this.userBanks.bank_count = user_bank.length
      this.userBanks.upi_count = user_upi.length
      let bankArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 1;
      });
      let upiArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 2;
      });
      this.userBankInfo = bankArr[0]
      this.userUpiInfo = upiArr[0]
    })
    // this.callApi.adminBankDetails({}).subscribe((res: any) => {
    //   this.adminBanks = res?.data?.data

    //   if (Array.isArray(this.adminBanks)) {

    //     let bankArr = this.adminBanks.filter(function (item: any) {
    //       return item.payment_method == 1;
    //     });
    //     let upiArr = this.adminBanks.filter(function (item: any) {
    //       return item.payment_method == 2;
    //     });
    //     this.adminBankInfo = bankArr[0]
    //     this.adminUpiInfo = upiArr[0]
    //   }
    // })
  }

  async withdrawRequest() {
    this.upiSubmit = true
    if (this.amount != '') {
      let payload = {
        coin_id: this.data?._id,
        user_bank_id: this.transaction_method == 1 ? this.userBankInfo._id : this.userUpiInfo._id,
        amount: this.amount,
        transaction_type: 2,
        transaction_method: this.transaction_method,
      }
      this.callApi.fiatWithdrawRequest(payload).subscribe(async (res: any) => {
        if (res.success) {
          Swal.fire({
            iconHtml: '<i class="fa-solid fa-check"></i>',
            title: await translate('Success!', this.translateControl),
            text: await translate(res.message, this.translateControl)
          })
          this.dialog.closeAll();
        } else {
          Swal.fire({
            iconHtml: '<i class="fa-solid fa-xmark"></i>',
            title: await translate('Oops.....!', this.translateControl),
            text: await translate(res.message, this.translateControl)
          })
        }
      })
    }
  }
  amountKeypress(event : any){
    var charCode = (event.which) ? event.which : event.keyCode;
    if(charCode > 32 && charCode < 48 || charCode > 57 && charCode < 127){
      event.preventDefault();
      return false;
    }else{
      return true;
    }
  }
}
