<section class="cryptodeposit-sec my-5">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="col-lg-11 div_deposit-details rounded-4">
                    <div
                        class="div_deposit-details_title d-block d-xl-flex justify-content-between align-items-center mb-4">
                        <h5 class="fw-semibold mb-0">{{'Crypto Deposit'|translate : translateControl | async}}</h5>
                        <button class="btn_theme-grey fw-medium mt-4 mt-xl-0" routerLink="/crypto-withdraw">{{'Crypto
                            Withdrawal ' | translate : translateControl | async}}<i
                                class="fas fa-arrow-right ms-2"></i></button>
                    </div>
                    <div class="div_deposit-details_amount mb-4">
                        <div class="row">
                            <!-- <div class="col-sm-8">
                                <label class="d-block small fw-medium mb-2">Amount</label>
                                <input type="text" class="form-control" />
                            </div> -->
                            <div class="">
                                <!-- <select name="fiat_type" class="form-control h-100" (change)="coinlist($event)" [(ngModel)]="coinId">
                                    <option value="">{{'Select Coin' | translate : translateControl |async}}</option>
                                    <ng-container *ngFor="let coin of coindata">
                                        <option  [value]="coin._id" *ngIf="coin?.coin_type !==0">{{coin?.slug}}</option>
                                    </ng-container>                                   
                                </select> -->
                                <button class="btn select-btn" *ngIf="!showCoin"
                                    (click)="openCryptoDeposit(cryptoDeposit)">
                                    <div class="d-flex align-items-center">
                                        <ng-container *ngIf="coinGetData?.image">
                                            <img [src]="imageUrl(coinGetData?.image)" [alt]="coinGetData?.name"
                                                class="img-fluid me-2" width="28" />
                                            <h6 class="fs-16 mb-0">{{coinGetData?.slug}}</h6>
                                        </ng-container>
                                        <!-- <ng-container *ngIf="coinGetData?.image">
                                            <img [src]="imageUrl(coinGetData?.image)" [alt]="coinGetData?.name"
                                                class="img-fluid me-2" width="28" />
                                            <h6 class="fs-16 mb-0">{{coinGetData?.slug}}</h6>
                                        </ng-container> -->
                                        <div class="d-flex">
                                            <span class="fs-13 mb-0" *ngIf="!coinGetData?.image">{{'Select
                                                Coin'|translate : translateControl | async}}</span>
                                        </div>
                                    </div>
                                </button>
                                <button class="btn select-btn" *ngIf="showCoin">
                                    <div class="d-flex align-items-center">
                                        <ng-container>
                                            <img [src]="walletData?.walletDetails?.coins[0]?.image" alt="coinimg"
                                                class="img-fluid me-2" width="28" />
                                            <h6 class="fs-16 mb-0">{{walletData?.walletDetails?.coins[0]?.slug}}</h6>
                                        </ng-container>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==1">
                        <label class="wallet_label" *ngIf="!fiatId" class="wallet_label">{{'Wallet Address'| translate :
                            translateControl | async}}</label>
                        <div class="row">

                            <div class="col-lg-12">
                                <div class="input-group mb-2">
                                    <select name="" id="" *ngIf="walletData?.walletDetails?.token"
                                        class="form-select select-grp">
                                        <option [value]="data?.network"
                                            *ngFor="let data of walletData?.walletDetails?.wallet_details">
                                            {{data?.network| uppercase|translate : translateControl | async}}
                                        </option>
                                    </select>
                                </div>
                                <div class="input-group wallet-form">
                                    <input type="text" *ngIf="!fiatId" name="addres" readonly [(ngModel)]="value"
                                        class="form-control " aria-label="Recipient's username"
                                        aria-describedby="basic-addon2">
                                    <div class="input-group-append" *ngIf="!fiatId">
                                        <button (click)="copyText(value)"
                                            class="btn_theme-primary h-100 shadow-none rounded-3 border-0 ms-2 p-2"
                                            type="button">{{copyTxt | translate : translateControl | async}}</button>
                                    </div>
                                </div>
                                <div class="qr_code_div mt-3 text-center">
                                    <qr-code [value]='value' class="val_Qr" size="150" errorCorrectionLevel="M">
                                    </qr-code>
                                </div>
                            </div>
                            <div class="col-lg-12 mt-sm-4 px-0" *ngIf="!fiatId">
                                <div class="wallet_content mt-2">
                                    <div class="crypto-inner-box">
                                        <p class="wallet_content_p mb-1"><span>{{'Minimum Deposit Limit :'|translate :
                                                translateControl | async}}</span>
                                            <span> {{depositMin?.min_deposit_limit}} {{depositMin?.slug |
                                                uppercase }}</span>
                                        </p>
                                        <p class="wallet_content_p mb-1"><span>{{'Maximum Deposit Limit :'|translate :
                                                translateControl | async}}</span>
                                            <span> {{depositMin?.max_deposit_limit}} {{depositMin?.slug |
                                                uppercase| translate : translateControl | async}}</span>
                                        </p>
                                        <p class="wallet_content_p mb-1 mt-2"><span>{{'Deposit Fee :'| translate :
                                                translateControl | async}}</span>
                                            <span>{{depositMin?.deposit_fee|tofixed}}%
                                                {{depositMin?.slug | uppercase| translate : translateControl |
                                                async}}</span>
                                        </p>
                                    </div>

                                    <p class="wallet_content_p note_content">{{'NOTE : Deposit may take from a few
                                        minutes to over 30 minutes.'| translate : translateControl | async}}</p>
                                    <p>{{'To see deposit history for this coin / token,'| translate : translateControl |
                                        async}} <a routerLink="/history" class="Wallet_a_link">{{'Click Here'| translate
                                            : translateControl | async}}</a></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="deposite_hole_div" *ngIf="depositMin?.deposit_status ==0">
                        <div class="d-flex justify-content-center align-items-center deposit-disable">
                            <h4 class="mb-0 me-3" *ngIf="depositMin?.coin_type ==1">{{'Deposits for this Coin is
                                Temporary Disabled| translate : translateControl | async'}}</h4>
                            <h4 class="mb-0 me-3" *ngIf="depositMin?.coin_type ==3">{{'Deposits for this Token is
                                Temporary Disabled'| translate : translateControl | async}}</h4>
                            <img src="assets/img/block.png" alt="block" width="32px" height="32px">
                        </div>
                    </div>
                    <!-- <div class="div_deposit-details_payment mb-3">
                        <h5 class="fs-6">Payment Method</h5>
                        <ul class="list-unstyled mb-0">
                            <li class="active mb-2">
                                <div class="d-flex align-items-center">
                                    <img src="../../../assets/img/coins/btc.png" class="img-fluid" width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h3 class="fs-5">BankTransfer</h3>
                                        <p class="mb-0">Real Time</p>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="d-flex align-items-center">
                                    <img src="../../../assets/img/coins/btc.png" class="img-fluid" width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h3 class="fs-5">BankTransfer</h3>
                                        <p class="mb-0">Real Time</p>
                                    </div>
                                </div>
                            </li>
                            <div class="mt-3">
                                <button class="btn_theme-primary small fw-medium w-100">Continue</button>
                            </div>
                        </ul>
                    </div> -->
                </div>
            </div>
            <div class="col-lg-6 mt-4 mt-xl-0">
                <h5 class="fw-semibold mb-4">{{'Deposit' |translate : translateControl | async}}</h5>
                <h6 class="fs-16"><i class="fas fa-pencil-alt text_theme-primary me-1"></i> {{'Deposit Instructions' |
                    translate : translateControl | async}}</h6>
                <!-- <h6 class="fs-13 fw-semibold">{{'Tips' | translate : translateControl | async}}</h6>
                <ol>
                    <li class="text-secondary">{{'Note: Please ensure your account details match the information provided during Identity Verification. Inconsistent account information may result in unsuccessful deposits, with funds returned within 7–14 working days for such cases.' | translate : translateControl | async }}</li>
                    <li class="text-secondary">{{'Fiat Deposit and Fiat Balance currently do not support P2P Trading.' | translate : translateControl |async}}</li>
                </ol>
                <h6 class="fs-13 fw-semibold">{{'ETA' | translate : translateControl | async }}</h6>
                <p class="text-secondary">{{'Please note that during national holidays or under special circumstances, the crediting of your fiat deposit to your account may be subject to a delay of up to four (4) working days.' | translate : translateControl | async}}
                </p> -->
                <ol class="text-secondary">
                    <li class="mb-2">{{'Supported Currencies: AUD , USD , EUR crypto currencies that are accepted for
                        deposits on the platform.' | translate : translateControl | async}}</li>
                    <li class="mb-2">{{'Deposit Methods: Information on the different methods available for depositing
                        crypto currency, such as bank transfers, credit/debit card payments, and other payment
                        options.'| translate : translateControl | async}}
                    </li>
                    <li class="mb-2">{{'Deposit Limits: Details on the minimum and maximum deposit amounts for crypto
                        currency deposits.'| translate : translateControl | async}}
                        <div class="fw-bold">
                            <span *ngIf="depositMin?.slug == 'ETH' " class="d-block">
                                {{'Min.Limit' |translate : translateControl | async}} -
                                {{depositMin?.min_deposit_limit['$numberDecimal']}} {{depositMin?.slug |
                                uppercase }}</span>
                            <span *ngIf="depositMin?.slug != 'ETH' " class="d-block">{{'Min.Limit' |translate : translateControl | async}} -
                                {{depositMin?.min_deposit_limit}} {{depositMin?.slug |
                                uppercase }}</span>
                            <span>{{'Max.Limit'|translate : translateControl | async}} -
                                {{depositMin?.max_deposit_limit}} {{depositMin?.slug |
                                uppercase| translate : translateControl | async}}</span>
                        </div>
                    </li>
                    <li class="mb-2">{{'Deposit Fees : Information on deposit fees that may be charged for depositing
                        crypto currency into the account.'|translate : translateControl | async}}
                        <div class="fw-bold">
                            {{'Deposit Fees'|translate : translateControl | async}}-
                            {{depositMin?.deposit_fee|tofixed}}%
                            {{depositMin?.slug | uppercase| translate : translateControl | async}}
                        </div>
                    </li>
                    <li class="mb-2">{{'Security Measures: Information on the security measures in place to protect
                        users
                        crypto deposits and personal information & Google 2FA will protect user transaction securely &
                        perfectly.'| translate : translateControl | async}}</li>
                    <li class="mb-2">{{'Customer Support: Contact information for customer support in case users
                        encounter
                        any issues or have questions about crypto deposits.'|translate : translateControl | async}}</li>
                    <li class="mb-2">{{'Disclaimer: A disclaimer outlining the risks associated with crypto deposits and
                        advising users to conduct their own research before making a deposit.'|translate :
                        translateControl | async}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>

<ng-template #cryptoDeposit>
    <div class="d-flex justify-content-between align-items-center">
        <h2 class="fs-5 px-4 py-3 mb-0">{{'Crypto Deposit' | translate : translateControl | async }}</h2>
        <button mat-icon-button [mat-dialog-close]="true" class="close-button">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </div>
    <div class="input-group px-4">
        <span class="input-group-text">
            <svg _ngcontent-wjr-c129="" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                viewBox="0 0 16 16" class="bi bi-search">
                <path _ngcontent-wjr-c129=""
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0">
                </path>
            </svg>
        </span>
        <input type="text" placeholder="{{'What are you searching for?' | translate : translateControl | async }}"
            class="form-control border" [(ngModel)]="searchText" (keyup)="filterData()" />


    </div>
    <mat-dialog-content>
        <ul class="ul_select-coin list-unstyled">
            <div *ngIf="(coindata |filter:searchText)?.length > 0; else notFound">
                <ng-container *ngFor="let coin of coindata |filter:searchText">
                    <div *ngIf="coin?.coin_type !==0">
                        <li (click)="coinSelect(coin, 1)" class="cursor-pointer rounded-2 p-2">
                            <img [src]="imageUrl(coin?.image)" alt={{coin?.slug}} width="28" class="img-fluid" /> <span
                                class="fs-6 text-dark ms-2 align-middle">{{coin?.slug}}</span>
                        </li>
                    </div>
                </ng-container>
            </div>
        </ul>
    </mat-dialog-content>
</ng-template>
<ng-template #notFound>
    <p class="fs-5 text-dark fw-medium text-center mb-0">{{'Not found' | translate : translateControl | async}}</p>
</ng-template>