import { Component, OnInit } from '@angular/core';
import { UserService } from '../../services/user.service';
import Swal from 'sweetalert2';
import { Route, Router } from '@angular/router';
import { environment } from '../../../environments/environment.development';
import { TicketManagementService } from '../../services/ticket-management.service';
import { SocketServiceService } from '../../socket-service.service';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit  {
  profileImage:any;
  token:any;
  ticketsArrLength :any;
  tickets:any;
 
  base_url: any = environment.IMAGE_URL;
  constructor(public callApi:UserService,public router:Router, private ticketService:TicketManagementService,private socket:SocketServiceService){
    this.token = localStorage.getItem('access_token')
  
  }
  ngOnInit() {
    // this.getConnectSocket()
  }
  
  // getConnectSocket(){
  //   let payload = {
  //     params:"connectSocket",
  //     token:this.callApi.getToken()
  //   }
  //   this.socket.sendMessage(payload)
  // }

  


  getImage(event: any) {
    event.target.src = this.callApi.getProfileAvatar();
  }

  

  logOut() {
    Swal.fire({
      title: 'Do you want to Logout?',
      // icon: 'question',
      iconHtml: '<i class="fas fa-sign-out-alt"></i>',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: `Yes`,
      denyButtonText:`No`,
    }).then((result) => {
      if (result.isConfirmed) {
        this.callApi.logOut().subscribe(async (res: any) => {
          if (res.success) {
            this.callApi.showSuccess(res.message)
            localStorage.clear();
            localStorage.setItem('current_token', 'All')
            localStorage.removeItem("tickets")
            this.router.navigate(['/login']);
            this.callApi.getToken();
            this.token = localStorage.getItem('access_token') || null
          } else {
            // this.callApi.showError(res.message)
            // localStorage.clear()
            // this.router.navigate(['/login']);
          }
        })
      }
    })
  }

  
  // notificationCount: any = localStorage.getItem('notification'); // Initialize notification count
  // ticketsDet = {
  //   ticketArr: [],
  //   costPerTicket: 0
  // };

  selectedOption: any[] = [];

  onClickOption(option: any) {
    // Your existing logic for selecting options
  }

  selectTicket(ticket: any) {
    // Your logic for adding ticket to cart
    // Assuming ticketsDet.ticketArr contains items in the cart
    if (!ticket.disabled) {
      ticket.disabled = true; // Mark as added
      // this.notificationCount++; // Increment count
    }
  }
}


