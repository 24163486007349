<section class=" height">
    <div class="container ">
        <div class="row justify-content-center ">
            <div class="">
                <div class="p-4 my-5 rounded-4 night-bg twofa-sec">
                    <div class="mt-3 mb-3 ">
                        <button routerLink="/profile" class="btn btn-secondary backk "><i class="fa fa-long-arrow-left"></i>
                            Back To Profile</button>
                    </div>
                    <h3 class="fw-semibold mb-0 text-center">{{'Scan QR code on mobile'}}</h3>
                    <div class="twofa_content fs-13 fw-medium w-75 mx-auto text-center ">{{' Install google autheticator app in your mobile and scan QR Code (or) If you are unable to scan the QR code, please enter this code manually into the app'}}
                       
                    </div>
                    <div class=" my-4 align-items-center">
                        <div class="order-lg-2">
                            <div class="qr_code_div mt-2 mb-2 ">
                                <img [src]="tfaQr?.data" class="img-fluid ">
                            </div>
                        </div>
                        <div class="order-lg-1">
                            <div class=" ">
                                <form [formGroup] ="form " class="my-3 tfa-form">
                                    <div class="mt-3 mb-3 ">
                                        <label class="label-text ">{{'Google 2FA Secret Code'}}</label>
                                        <div class="input-group ">

                                            <input type="text" name="secrectkey" formControlName="secrectkey"
                                                [value]="tfa" readonly class="form-control"
                                                aria-label="Recipient 's username" aria-describedby="basic-addon2">
                                            <div class=" input-group-append position-absolute end-0 h-100">
                                                <button (click)="copyText(tfa)" class="btn btn-copy h-100"
                                                    type="button">{{'copy'}}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="label-text">{{'Google Authentication code'}}
                                      <span class="text-danger">*</span></label>
                                      <div class="input-group ">
                                        <input maxlength="6" formControlName="otp" placeholder="Enter Your OTP" type="text" class="form-control" [ngClass]="{'is-invalid': submitted && f['otp'].errors}" aria-label="Recipient's username" aria-describedby="basic-addon2 ">
                                        <div *ngIf="submitted && f['otp']?.errors" class="invalid-feedback">
                                            <div *ngIf="f['otp']?.errors?.['required'] ">{{'Google Authentication Code is Required'}}</div>
                                            <div *ngIf="f['otp']?.errors?.['pattern'] ">{{'Invalid Code'}}</div>
                                        </div>
                                    </div>
                                    </div>

                                    <div class="form-group mt-20 ">
                                        <button (click)="submit() " type="button" name=" "
                                            class="btn btn-secondary my-3 w-100 border-0 submmit">{{'Submit'}}</button>
                                            
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
        </div>
    </div>
</section>