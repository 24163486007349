<div class="body-bg">
    <div class="container py-5">
        <form  class="m-3" [formGroup]="searchForm">
            <div class="bg-form">
                <div class="d-flex flex-column flex-md-row gap-4">
                    <div class="int-icon mb-md-0">
                        <input type="text" class="form-control tag" placeholder="Search.." name="search" formControlName="ticketDrawName">
                        <i class="fa fa-search icon"></i>
                    </div>
                    <div class="mb-3 mb-md-0">
                        <select name="status" id="status" class="form-control tag" formControlName="ticketStatus">
                            <option value=null  disabled selected>Status</option>
                            <option value="">All</option>
                            <option value="1">Progress</option>
                            <option value="2">Completed</option>
                        </select>
                    </div>
                    <div>
                        <button type="submit" class="btn submit" (click)="onSearch()" >Submit</button>
                    </div>
                    <div>
                        <button type="submit" class="btn submit" (click)="onClear()" >Clear</button>
                    </div>
                </div>
               
            </div>
        </form>
        <div class="row justify-content-center">
            <div class="col-lg-10">
                <div class="card border-0 mt-5"  *ngFor="let cartDetail of cartDetails">
                    <div class="position-absolute end-0">
                        <button class="btn btn-sm rounded-3 text-bg-info p-2 border-0 fw-bold ">{{cartDetail.isClosed?'Completed':'In progress'}}</button>
                    </div>
                    <div class="row p-4 align-items-center">
                        <div class="col-lg-4">
                            <img src="../../assets/img/lottery-bg.png" alt="" class="img-fuild img-tick">
                        </div>
                        <div class="col-lg-8">
                            <div class="d-flex justify-content-between p-1 mt-4">
                                <div>
                                    <h2 class="mb-2 fw-bold">{{cartDetail.ticketDrawName}}</h2>
                                    <h4 class="mb-0 ">{{cartDetail.resultDate |  date:'shortDate'}}</h4>
                                </div>
                                <div>
                                    
                                    <h2 class="mb-2 fw-bold">{{cartDetail.ticketDrawPrice}} USDT</h2>
                                    <h4 class="mb-0">Ticket Draw Price</h4>
                                </div>
                            </div>
                            <hr>
                            <div class="d-block d-sm-flex justify-content-between p-1">
                                <div>
                                    <h2 class="mb-0 fw-bold">User Name</h2>
                                    <h2 class="mb-2 fw-bold">{{cartDetail.
                                        user_name
                                        }} </h2>
                                </div>
                                <div class=" p-1 text">
                                    <div>
            
                                        <h3>Ticket Number</h3>
                                    </div>
                                    <div class="row gap-3 justify-content-around">
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[0]}}</h2>
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[1]}}</h2>
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[2]}}</h2>
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[3]}}</h2>
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[4]}}</h2>
                                        <h2 class="circle mb-0">{{cartDetail.ticketDetails.ticket_no[5]}}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="cartDetails.length == 0" >
                    <p class="text-center no_records mt-5">No Records Found</p>
                </div>
            </div>
        </div>
</div>