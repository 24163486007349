import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { SelectServiceService } from '../select-service.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-two-fa',
  templateUrl: './two-fa.component.html',
  styleUrls: ['./two-fa.component.scss']
})
export class TwoFaComponent implements OnInit {
  form!: FormGroup;
  submitted = false;
  tfaQr: any;
  tfa: any;
  code: string = '';
  copy: string = 'Copy';

  constructor(
    private callApi: UserService,
    private fb: FormBuilder,
    public selectService: SelectServiceService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.getTfa();  // Fetch TFA data on initialization
  }

  get f() { return this.form.controls; }

  createForm() {
    this.form = this.fb.group({
      secrectkey: [{ value: this.tfa, disabled: true }], // Disabled as it's read-only
      otp: [null, [Validators.required, Validators.pattern('^\\d{6}$')]]
    });
  }

  copyText(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.copy = 'Copied!';

    setTimeout(() => {
      this.copy = 'Copy';
    }, 2000);
  }

  submit() {
    // this.callApi.startSpinner();
    this.submitted = true
    if (this.form.valid) {
      this.form.value.otp = Number(this.form.value.otp)
      this.form.value.status = '1'     // enable google authentication
      this.callApi.checkTfa(this.form.value).subscribe(async (res: any) => {
        if (res.success === true) {
          this.callApi.showSuccess(res.message)
          this.router.navigate(['/profile'])
          // this.callApi.startSpinner();
        }
        else {
          this.callApi.showError(res.message)
          // this.callApi.startSpinner();
        }
      }, err => {
        this.callApi.serverError();
        // this.callApi.startSpinner();
      })
    }

  }

  getTfa() {
    this.callApi.getTfa().subscribe((res: any) => {
      this.tfaQr = res?.data
      this.tfa = res?.data?.secrectkey
      this.form.patchValue({"secrectkey":this.tfa});
    },err => {
      this.callApi.serverError();
    })
  }
  
}
