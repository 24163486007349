import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-claim',
  templateUrl: './claim.component.html',
  styleUrl: './claim.component.scss'
})
export class ClaimComponent implements OnInit{
  claimForm! : any;
  isSubmitted :boolean = false

  constructor(private fb:FormBuilder, private route:Router){}

ngOnInit(){
 this.claim();
}

claim(){
  this.claimForm = this.fb.group({
    Order_ID:['',[Validators.required]],
    Date:['',[Validators.required]],
    Draw_date:['',[Validators.required]],
    Ticket_Num:['',[Validators.required]],
    phone_no:['',[Validators.required, Validators.maxLength(10)]],
    Acc_No:['',[Validators.required]],
    Branch:['',[Validators.required]],
    Ifsc:['',[Validators.required]],
    Holder_Name:['',[Validators.required]],
    Upi_No:['',[Validators.required]]


  })
}
}
