import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SelectService {

    current_language: any = localStorage.getItem('current_language') === null ? 'en' : localStorage.getItem('current_language')
    current_token: any =  localStorage.getItem('current_token') === null ? 'All' : localStorage.getItem('current_token') 
    current_category: any = localStorage.getItem('current_category')  === null ? 'All' : localStorage.getItem('current_category') 
    public selectedValue = new BehaviorSubject<string>(this.current_language);
    private translateControl = new BehaviorSubject<any>(this.current_language);
    private activeClass = new BehaviorSubject<any>(this.current_token)
    private activeClass2 = new BehaviorSubject<any>(this.current_category)
    private pagination = new BehaviorSubject<any>(1)
    
    //selectedValue
    updateSelectedValue(value: string) {
        this.selectedValue.next(value);
    }
    getSelectedValue() {
        return this.selectedValue.asObservable();
    }
    //translateControl
    getSharedData() {
       return this.translateControl.asObservable();
    }
    updateSharedData(value: any) {
       this.translateControl.next(value);
    }

    updateActiveClass(value:any){
        this.activeClass.next(value);
    }

    getActiveClass(){
        return this.activeClass.asObservable();
    }

    updateActiveClass2(value:any){
        this.activeClass2.next(value);
    }

    getActiveClass2(){
        return this.activeClass2.asObservable();
    }
    paginationUpdated(value:any){
        this.pagination.next(value);
    }

    paginationGet(){
        return this.pagination.asObservable();
    }
    
}