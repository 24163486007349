import { provideCloudinaryLoader } from '@angular/common';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { SelectService } from 'src/app/service/translate/select.service';
// import { UserService } from 'src/app/service/user.service';
// import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import translate from 'translate';
import { UserService } from '../services/user.service';
import { SelectService } from '../services/translate/select.service';
import { environment } from '../../environments/environment.development';

@Component({
  selector: 'app-bankdetails',
  templateUrl: './bankdetails.component.html',
  styleUrls: ['./bankdetails.component.scss']
})
export class BankdetailsComponent implements OnInit {
  public translateControl: any;
  [x: string]: any;
  bank_id: any;
  data_id: any;
  detail: any
  add: any;
  data: any;
  userForm: any
  bank: any
  id: any
  searchText: any
  page = 1
  offlineEdit: any;
  dataID: any;
  form: any;
  ngForm: any
  action: any
  submitted: boolean = false
  bankSubmit: boolean = false
  bankdetails: any
  pageData: any = {};
  totalCount: any = {};
  siteKey: string = "6LeV0ZUpAAAAAJ88UInQ8MI-oF1xEfc_9voiNFCu"
  constructor(private dialog: MatDialog, private callApi: UserService, private fb: FormBuilder, public selectService: SelectService,) { }

  async ngOnInit() {

    this.selectService.getSharedData().subscribe((data: any) => {
      this.translateControl = data
    })

    this.createForm();
    this.getDetails();
    this.addBankAccount();
    // this.onlinebankForm();
  }

  addBankAccount() {
    this.userForm = this.fb.group({
      bank_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(60)]],
      account_type: [null, [Validators.required]],
      account_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(40)]],
      account_no: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(34)]],
      branch: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(100)]],
      ifsc_code: [null, [Validators.required, Validators.maxLength(11)]],
      bank_address: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(150)]],
      // primary_account: [false, [Validators.required]]
    })
  }
  recaptcha = new FormControl("" );
  get h() { return this.userForm.controls }
  keyPress(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 33 || charCode > 122 || charCode <= 45) {
      event.preventDefault();
      return false
    }
    else {
      return true
    }
  }


  addbankForm(templateRef: TemplateRef<any>) {
    this.userForm.reset()
    this.form.reset()
    this.bankSubmit = false
    this.submitted = false
    this.dialog.open(templateRef, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "560px",
      minHeight: "auto",
      maxHeight: "calc(100vh - 6rem)"
    })
  }
  userAddBankForm(form: any) {
    this.bankSubmit = true
    if (this.userForm.valid && this.recaptcha.valid) {
      let payload: any = {}
      payload = form.value
      payload.payment_method = '1'
      payload.payment_type = '2'
      payload = form.value
      this.callApi.addBankdDetails(payload).subscribe(async (res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(await translate(res.message, this.translateControl))
          this.dialog.closeAll()
          this.userForm.reset()
          this.getDetails()
          this.bankSubmit = false
        }
        else {
          this.callApi.showError(await translate(res.message, this.translateControl))
        }
      })
    }
  }

  get f() { return this.form.controls; }
  createForm() {
    this.form = this.fb.group({
      payment_id: [null, [Validators.required, Validators.pattern("[a-zA-Z0-9.\-_]{4,20}@[a-zA-Z._]{4,20}")]]
    })
  }
  onlinebankForm() {
    this.submitted = true
    if (this.form.valid) {
      let payload: any = this.form.value;
      payload.payment_method = '2'
      payload.payment_type = '2'

      this.callApi.addBankdDetails(payload).subscribe(async (res: any) => {
        if (res.success == true) {
          this.callApi.showSuccess(await translate(res.message, this.translateControl))
          this.dialog.closeAll()
          this.submitted = false
          this.getDetails()
        }
        else {
          this.callApi.showError(await translate(res.message, this.translateControl))
        }
      })
    }
  }

  getDetails() {
    const payload = {}
    this.callApi.getBankDetails(payload).subscribe((res: any) => {
      this.bankdetails = res?.data?.data
      this.totalCount = res.data.totalcount
      this.detail = this.bankdetails
      this.pageData.totalPage = Math.ceil(this.detail?.length / environment.PAGE_SIZE)
      this.pageData.sliceCount = this.detail?.slice((this.page * environment.PAGE_SIZE) - environment.PAGE_SIZE, this.page * environment.PAGE_SIZE).length
      if (this.page == this.pageData?.totalPage + 1 && this.pageData?.sliceCount == 0) {
        this.page = this.page - 1
      }


    })
  }

  async deleteDetails(id: any) {
    Swal.fire({
      title: await translate('Do you want to Delete this Account ?', this.translateControl),
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: await translate('Yes', this.translateControl),
      denyButtonText: await translate('No', this.translateControl),
    }).then((result) => {
      if (result.isConfirmed) {

        let payload = {

          bank_id: id

        }

        this.callApi.deleteBankDetails(payload).subscribe(async (res: any) => {
          if (res.success == true) {
            this.callApi.showSuccess(await translate(res.message, this.translateControl))

            this.getDetails();
          } else {
            this.callApi.showError(await translate(res.message, this.translateControl))
          }
        })

      }
    })
  }

  searchDetails() {
    let payload = {
      search: this.searchText
    }
    this.callApi.searchBankDetails(payload).subscribe((res: any) => {
      this.detail = res.data
    })
  }
  clearData() {
    this.searchText = "";
    this.getDetails();
  }

  edit(templateRef: TemplateRef<any>, id: any, method: any) {
    this.dialog.open(templateRef, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "560px",
      minHeight: "auto",
      maxHeight: "calc(100vh - 6rem)"
    })
    this.dataID = id
    this.action = method
    let payload = {
      id: id
    }
    this.callApi.getBankDetails(payload).subscribe((res: any) => {
      this.detail = res.data
      this.userForm.patchValue(res.data.data[0])
      this.form.patchValue({ payment_id: res.data.data[0].payment_id })
    })
  }

  updateDetails(data: any) {
    this.bankSubmit = true
    if (this.userForm.valid) {
      let payload: any = data;
      payload.bank_id = this.dataID
      this.callApi.updateBankDetails(payload).subscribe(async (res: any) => {

        if (res.success == true) {
          this.callApi.showSuccess(await translate(res.message, this.translateControl))
          this.dialog.closeAll()
          this.getDetails()
          this.bankSubmit = false
        }
        else {
          this.callApi.showError(await translate(res.message, this.translateControl))
        }
      })
    }
  }
  updateWirelessDetails(data: any) {
    this.submitted = true
    if (this.form.valid) {
      let payload: any = data
      payload.bank_id = this.dataID,
        this.callApi.updateBankDetails(payload).subscribe(async (res: any) => {
          if (res.success) {
            this.callApi.showSuccess(await translate(res.message, this.translateControl))
            this.dialog.closeAll()
            this.getDetails()
            this.submitted = false
          }
          else {
            this.callApi.showError(await translate(res.message, this.translateControl))
          }
        })
    }
  }
  alphabets(event: any) {
    if (!event.key.match(/^[a-zA-Z\s]*$/)) {
      return event.preventDefault()
    } else {
      return
    }
  }
  numberValidation(event: any) {
    if (!event.key.match(/^[0-9]\d*$/)) {
      return event.preventDefault()
    } else {
      return
    }
  }
  alphanumeric(event: any) {
    if (!event.key.match(/^[A-Z0-9]+$/)) {
      return event.preventDefault()
    } else {
      return
    }
  }
  addressValidation(event: any) {
    if (event.which == 46 || event.which == 44 || event.which == 32) {
      return
    } else if (!event.key.match(/^[a-zA-Z0-9\/\-]+$/)) {
      return event.preventDefault()
    } else {
      return
    }
  }
  serial(data:any){
    return Number(((this.page-1)*5) + data+1)
  }
}
