<section class="useremail-sec">
    <div class="container">
        <div class="row justify-content-center align-items-center m-2">
            <div class="col-lg-6 card rounded-4 ">
                <div class="p-4 night-bg ">
                    <div class="d-flex justify-content-between align-items-center mb-3 ">
                        <h3 class="mb-0 fw-bold fs-4">Email Verification</h3>
                        <div class="">
                            <button name="next" (click)="back()" type="button" class="btn_theme-grey fw-bold">
                                <i class="fa fa-long-arrow-left"></i> Back</button>
                        </div>
                    </div>
                    <div class="usertwofa_card_body">
                        <form [formGroup]="form">
                            <div class="mt-3 mb-3">
                                <label class="mb-1 text-dark fw-bold">Verification Code</label>
                                <div class="">
                                    <input (keypress)="keyPressNumbers($event)" maxlength="6" formControlName="otp"
                                        type="text" class="form-control rounded-3"
                                        [ngClass]="{'is-invalid' : submitted && f['otp'].errors}"
                                        placeholder="Enter Valid Code" aria-label="Recipient's username"
                                        aria-describedby="basic-addon2">
                                    <div class="invalid-feedback" *ngIf="submitted && f['otp'].errors">
                                        <div *ngIf="f['otp'].errors?.['required']">Otp Required</div>
                                        <div *ngIf="f['otp'].errors?.['pattern']">Invalid Otp</div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <div class="">
                                    <button name="next" (click)="resendOTP()" type="button" class="btn btn_theme-grey "
                                        [disabled]="isLoading">
                                        <i class="fa"
                                            [ngClass]="{'fa-spin fa-spinner me-2': isLoading, '': !isLoading}"></i>{{button}}</button>
                                </div>
                                <div class="">
                                    <button type="button" (click)="submit()" name="next"
                                        class="btn btn_theme-grey ">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- <ng-template #welcome>
    <div class="p-3">
        <div>
            <button class="close-button" [mat-dialog-close]="true" mat-icon-button>
                <mat-icon class="close-icon">close</mat-icon>
            </button>
            <div class="text-center mb-2">
                <img src="../../../assets/img/success.png" alt="success" class="img-fluid text-center">
            </div>
            <h5 class="text-center ">Successfull!</h5>
            <p class="p-2 text-center">To access all features, please complete identity verification first. Otherwise, the following features will be restricted.
            </p>
            <div class="welcome-list p-3 my-3">
                <div class="row">
                    <div class="col-md-6">
                        <ul>
                        <li>View Ticket</li>
                        <li>Wallet</li>
                         <li>Deposit</li> -->
<!-- </ul> -->
<!-- </div> -->
<!-- <div class="col-md-6">
                        <ul>
                            <li>Spot</li>
                            <li>P2P trading</li>
                            <li>Convert</li>
                        </ul>
                    </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- <div class="d-flex justify-content-center">
                <button class="btn btn-theme-button mt-3 d-none d-lg-block d-flex justify-content-center" [mat-dialog-close]="true" routerLink="/kyc-sub">Submit KYC</button>
            </div> -->
<!-- </div> -->
<!-- </div> -->
<!-- </ng-template> --> 

<ng-template #rewards>
    <div class="p-3 rewards-bg-img">
        <mat-icon class="float-end" [mat-dialog-close]="true">close</mat-icon>
        <div class="offer_details  my-4">
            <h4 class="offer_details-title mb-0 text-white">Save UPTO <span class="">25% offer</span> in CLT</h4>
            <div class="d-inline-block rounded-2 py-3 my-4">
                <div class="text-end reward-logo">
                    <img src="assets/img/coinlivret/logo/logo-white.png" alt="logo" class="">
                </div>

                <div class="element-1">
                    <img src="../../../assets/img/coinlivret/ribben.png" alt="ribben">
                </div>
                <img src="../../../assets/img/coinlivret/offer_img.png" alt="25% Offer" class="img-fluid mb-2" />
                <h4 class="text-white">DISCOUNT</h4>
                <h4 class="text-warning">CARD CLT</h4>
                <h5 class="mb-0 text-white">Use CLT to pay fees</h5>
            </div>
            <div>
                <a routerLink="/settings" [mat-dialog-close]="true" class="btn btn-warning">See details</a>
            </div>
        </div>
    </div>
</ng-template>