import { Component } from '@angular/core';
import { FormBuilder, FormGroup,Validators} from '@angular/forms';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-emailotp',
  templateUrl: './emailotp.component.html',
  styleUrl: './emailotp.component.scss'
})
export class EmailotpComponent {
  public translateControl:any
enter_code:any;
  constructor(
    private fb: FormBuilder,
    private callApi:UserService,
    private router:Router
    ) { }
  form: any;
  submitted: boolean = false;
  button='Resend Code'
  isLoading:boolean=false;
  ngOnInit(): void {
    this.createForm();
  }
  
  get f() { return this.form.controls; }
  createForm() {
    this.form = this.fb.group({
      twofa_otp: [null, [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{6}$")]],
      status :["1"]
    })
  }


  submit(){
    this.submitted = true
    if (this.form.valid) {
      this.callApi.otpEmailVerification(this.form.value).subscribe(async (res:any) => {
        if (res.success == true) {
          this.router.navigate(['/profile'])
          this.callApi.showSuccess(await (res.message))
          }else{
          this.callApi.showError(await (res.message))
        }
      },err => {
        this.callApi.serverError();
      })
    }
  }
//   resendOTP(){
//     this.button = 'Processing'
//     this.isLoading = true
//     this.callApi.tfaresendmailotp({}).subscribe(async (res:any) => {
//       if (res.success == true) {
//          this.callApi.showSuccess(res.message)
//          this.button = 'Resend Code'
//          this.isLoading = false
//       }
//       else{
//         this.callApi.showError(await (res.message))
//         this.button = 'Resend Code'
//         this.isLoading = false
//       }
//     },
//     //  err:any => {
//     //   this.callApi.serverError();
//     //   this.button = 'Resend Code'
//     //   this.isLoading = false
//     // }
//     )
//   }
}




