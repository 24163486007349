<section class="my-5 deposit-sec">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="col-lg-11 div_deposit-details rounded-4">
                    <div
                        class="div_deposit-details_title d-block d-xl-flex justify-content-between align-items-center mb-4">
                        <h5 class="fw-semibold mb-0">{{'Fiat Deposit' | translate:translateControl | async}}</h5>
                        <button class="btn_theme-grey fw-medium mt-3 mt-xl-0" routerLink="/fiat-withdraw">{{'Fiat Withdrawal' | translate:translateControl | async}} <i
                                class="fas fa-arrow-right"></i></button>
                    </div>
                    <div class="div_deposit-details_amount mb-4">
                        <div class="row">
                            <div class="col-sm-8">
                                <!-- <label class="d-block small fw-medium mb-2">{{'Amount' | translate:translateControl | async}}</label> -->
                                <input type="text" [placeholder]="amount_field" class="form-control"
                                    [(ngModel)]="fiatAmount" (keypress)="amountKeypress($event)" appCopypaste/>
                            </div>
                            <div class="col-sm-4">
                                <mat-form-field class="h-100" *ngIf="!showCoin">
                                    <mat-select class="" (selectionChange)="selectFiat($event)" [placeholder]="selected">
                                        <mat-select-trigger class="d-flex align-items-center">
                                            <img src="{{backendend+data?.image}}" width="20" height="20"
                                                alt="Selected Option Image">
                                            <span class="ms-2">{{data.slug}}</span>
                                        </mat-select-trigger>
                                        <mat-option *ngFor="let data of Fiatdata" [value]="data?._id">
                                            <img src="{{backendend + data?.image}}" width="20" height="20"><span
                                                class="ms-2">{{data.slug}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="showCoin" class="d-flex align-items-center h-100 bg-white justify-content-center">
                                    <img src="{{this.data?.image}}" width="20" height="20" alt="Selected Option Image">
                                    <span class="ms-2">{{this.data.slug}}</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="div_deposit-details_payment mb-3">
                        <h6 class="fs-16 fw-semibold">{{'Payment Method' | translate:translateControl | async}}</h6>
                        <ul class="list-unstyled mb-0">
                            <li class="mb-2" [ngClass]="title == 'payment' ? 'active' : ''">
                                <div class="d-flex align-items-center" (click)="selectType('payment',1)">
                                    <img src="assets/img/coins/payment-metnod.png" class="img-fluid"
                                        width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="">{{'Payment Method' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="mb-2" [ngClass]="title == 'paypal' ? 'active' : ''">
                                <div class="d-flex align-items-center" (click)="selectType('paypal',2)">
                                    <img src="assets/img/coins/paypal.png" class="img-fluid" width="48"
                                        alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="">{{'PayPal' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <li [ngClass]="title == 'stripe' ? 'active' : ''">
                                <div class="d-flex align-items-center" (click)="selectType('stripe',3)">
                                    <img src="assets/img/coins/stripe.png" class="img-fluid" width="48"
                                        alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="">{{'Stripe' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <div class="mt-3">
                                <button class="btn_theme-primary fw-medium w-100"
                                    (click)="submitFiatDeposit()">{{'Continue' | translate:translateControl |
                                    async}}</button>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-4 mt-lg-0 ">
                <h5 class="fw-semibold mb-4">{{'Deposit' | translate:translateControl | async}} {{data?.slug}}</h5>
                <h6 class="fs-16"><i class="fas fa-pencil-alt text_theme-primary me-1"></i> {{'Deposit Instructions' |
                    translate:translateControl | async}}</h6>
                <!-- <h6 class="fs-13">{{'Tips' | translate:translateControl | async}}</h6>
                <ol>
                    <li class="text-secondary">{{'Note: Please ensure your account details match the information
                        provided during Identity Verification. Inconsistent account information may result in
                        unsuccessful deposits, with funds returned within 7–14 working days for such cases.' |
                        translate:translateControl | async}}</li>
                    <li class="text-secondary">{{'Fiat Deposit and Fiat Balance currently do not support P2P Trading.' | translate:translateControl | async}}</li>
                </ol>
                <h6 class="fs-13">ETA</h6>
                <p class="text-secondary">{{'Please note that during national holidays or under special circumstances, the crediting of your fiat deposit to your account may be subject to a delay of up to four (4) working days' | translate:translateControl | async}}.
                </p>
                <div>
                    <h6 class="wallet_content_p">{{'Minimum Deposit Limit '| translate:translateControl | async}}:  {{data?.min_deposit_limit}} % {{data?.slug}} </h6>
                    <h6 class="wallet_content_p">{{'Maximum Deposit Limit' | translate:translateControl | async}} :  {{data?.max_deposit_limit}} %{{data?.slug}}</h6>
                    <h6 class="wallet_content_p">{{'Deposit Fee'| translate:translateControl | async}} :   {{data?.deposit_fee}} %  {{data?.slug}}</h6>
                </div> -->                
                <ol class="text-secondary">
                    <li class="mb-2">{{'Supported Currencies: AUD , USD , EUR fiat currencies that are accepted for deposits on the platform.'| translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Deposit Methods: Information on the different methods available for depositing fiat currency, such as bank transfers, credit/debit card payments, and other payment options.'| translate:translateControl | async}}
                    </li>
                    <li class="mb-2">{{'Deposit Limits: Details on the minimum and maximum deposit amounts for fiat currency deposits.'| translate:translateControl | async}}
                        <div class="fw-bold" *ngIf="isSelectionMade">
                            <span class="d-block">{{'Min.Limit'| translate:translateControl | async}} - {{data?.min_deposit_limit}} {{data?.slug |
                                uppercase }}</span>
                            <span>{{'Max.Limit'| translate:translateControl | async}} - {{data?.max_deposit_limit}} {{data?.slug |
                                uppercase| translate : translateControl | async}}</span>
                        </div>
                    </li>
                    <li class="mb-2">{{'Deposit Fees: Information on deposit fees that may be charged for depositing fiat currency into the account.'| translate:translateControl | async}}
                        <div class="fw-bold" *ngIf="isSelectionMade">
                           {{' Deposit Fees'| translate:translateControl | async}} - {{data?.deposit_fee|tofixed}}%
                           {{data?.slug | uppercase| translate : translateControl | async}} 
                        </div>
                    </li>
                    <li class="mb-2">{{'Security Measures: Information on the security measures in place to protect users fiat deposits and personal information & Google 2FA will protect user transaction securely & perfectly.'| translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Customer Support: Contact information for customer support in case users encounter any issues or have questions about fiat deposits.'| translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Disclaimer: A disclaimer outlining the risks associated with fiat deposits and advising users to conduct their own research before making a deposit.'| translate:translateControl | async}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>


<ng-template #wiredTransfer>
    <mat-dialog-content class="position-relative wal-pop-wdth-dep-grp">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <h2 class="fs-5">{{'Payment Method'| translate : translateControl | async}}</h2>
        <form (ngSubmit)="depositRequest()" #depositForm="ngForm" autocomplete="off">
            <div class="form-group">
                <h6 class="fs-16 mt-3 dark-dull-text">{{'Choose Payment Method'| translate : translateControl | async}}</h6>
                <div class="payment-tabbed">

                    <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="btn_theme-primary shadow-none active me-2" id="v-pills-wired-transfer-tab "
                            data-bs-toggle="pill" data-bs-target="#v-pills-wired-transfer" type="button" role="tab"
                            aria-controls="v-pills-wired-transfer" aria-selected="true" name="depositWiredTransfer"
                            (click)="setPaymentType(1)">{{'Wired Transfer'| translate : translateControl |
                            async}}</button>
                        <button class="btn_theme-primary shadow-none" id="v-pills-profile-tab" data-bs-toggle="pill"
                            (click)="setPaymentType(2)" data-bs-target="#v-pills-profile" type="button" role="tab"
                            aria-controls="v-pills-profile" aria-selected="false" name="depositOnlineTransfer">{{'UPI Pay'| translate : translateControl | async}}
                        </button>
                    </div>
                    <div
                        *ngIf="(transaction_method==1 && userBanks?.bank_count<1)||(transaction_method==2 && userBanks?.upi_count<1) || (userBanks?.bank_count<1 && userBanks?.upi_count<1)">
                        <div class="text-center mt-4 mt-lg-0">
                            <h6 class="mb-3">{{'Please Add Bank Account Details'| translate : translateControl | async}}
                            </h6>
                            <button class="btn_theme-primary" (click)="addbank()">{{'Click Here'| translate :
                                translateControl | async}}</button>
                        </div>
                    </div>
                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel"
                            aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                            <div class="bank-check-grp text-start">
                                <div _ngcontent-cag-c149="" class="mt-4 row text-start" *ngIf="userBanks?.bank_count>0">
                                    <div class="col-lg-6">
                                        <div class="from-details h-100">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Deposit From:'| translate :
                                                    translateControl | async}}</label><br>
                                                <select class="wallet-select form-control mb-3"
                                                    (change)="changeBank($event, 'user', 'bank')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank.payment_method==1">
                                                            {{bank.account_no }} - {{ bank.bank_name | translate :
                                                            translateControl | async }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="bank-detail-wallet" *ngIf="userBankInfo.account_no">
                                                <ul class="list-unstyled mb-0">
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Number :'|
                                                            translate : translateControl | async}}</span><span>{{
                                                            userBankInfo.account_no }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Name :'|
                                                            translate : translateControl | async}}</span><span>{{
                                                            userBankInfo.account_name | translate : translateControl |
                                                            async }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Bank Name :'|
                                                            translate : translateControl | async}}</span><span>{{
                                                            userBankInfo.bank_name | translate : translateControl |
                                                            async }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Type :'|
                                                            translate : translateControl | async}}</span><span>{{
                                                            userBankInfo.account_type }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'IFSC Code :'|
                                                            translate : translateControl | async}}</span><span>{{
                                                            userBankInfo.ifsc_code }}</span></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="col-lg-6 mt-3 mt-lg-0">
                                        <div class="to-details h-100">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Deposit To:'| translate :
                                                    translateControl | async}}</label><br>
                                                <select class="wallet-select form-control mb-3"
                                                    (change)="changeBank($event, 'admin', 'bank')">
                                                    <ng-container *ngFor="let bank of adminBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank.payment_method==1">
                                                            {{bank.account_no }} - {{ bank.bank_name | translate :
                                                            translateControl | async}}</option>
                                                    </ng-container>
                                                </select>
                                            </div>

                                            <div class="bank-detail-wallet" *ngIf="adminBankInfo.account_no">
                                                <ul class="list-unstyled mb-0">
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Number'|
                                                            translate : translateControl | async}}</span>: <span>{{
                                                            adminBankInfo.account_no }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Name'|
                                                            translate : translateControl | async}}</span>: <span>{{
                                                            adminBankInfo.account_name | translate : translateControl |
                                                            async }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Bank Name'|
                                                            translate : translateControl | async}}</span>: <span>{{
                                                            adminBankInfo.bank_name | translate : translateControl |
                                                            async}}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'Account Type'|
                                                            translate : translateControl | async}}</span>: <span>{{
                                                            adminBankInfo.account_type }}</span></li>
                                                    <li class="mb-2 pb-1"><span class="text-dark">{{'IFSC Code'|
                                                            translate : translateControl | async}}</span>: <span>{{
                                                            adminBankInfo.ifsc_code }}</span></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade" id="v-pills-profile" role="tabpanel"
                            aria-labelledby="v-pills-profile-tab" tabindex="0">
                            <div class="row bank-check-grp mt-4" *ngIf="userBanks?.upi_count>0">
                                <div class="col-lg-6">
                                    <div class="from-details">
                                        <div>
                                            <label class="label-text fw-bold mb-2">{{'Deposit From:'| translate :
                                                translateControl | async}}</label><br>
                                            <select class="wallet-select form-control mb-3"
                                                (change)="changeBank($event, 'upi', 'deposit')">
                                                <ng-container *ngFor="let bank of userBanks; let i = index">
                                                    <option [value]="i" *ngIf="bank?.payment_method==2">
                                                        {{bank.payment_id }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                        <div class="bank-detail-wallet" *ngIf="userUpiInfo?.payment_id">
                                            <ul class="list-unstyled mb-0">
                                                <li><span class="text-dark">{{'UPI ID'| translate : translateControl |
                                                        async}}</span>: <span>{{ userUpiInfo?.payment_id }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-6 mt-3 mt-lg-0">
                                    <div class="to-details">
                                        <div>
                                            <label class="label-text fw-bold mb-2">{{'Deposit To:'| translate :
                                                translateControl | async}}</label><br>
                                            <select class="wallet-select form-control mb-3"
                                                (change)="changeBank($event, 'admin', 'upi')">
                                                <ng-container *ngFor="let bank of adminBanks; let i = index">
                                                    <option [value]="i" *ngIf="bank.payment_method==2">{{bank.payment_id
                                                        }}</option>
                                                </ng-container>
                                            </select>
                                        </div>
                                        <div class="bank-detail-wallet" *ngIf="adminUpiInfo?.payment_id">
                                            <ul class="list-unstyled">
                                                <li><span class="text-dark">{{'UPI ID'| translate : translateControl |
                                                        async}}</span>: <span>{{ adminUpiInfo?.payment_id }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <!-- choose payment -->

                <div class="mt-2"
                    *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="text-start">
                                <label class=" col-form-label">{{'Transaction ID:'| translate : translateControl |
                                    async}}</label>

                                <input [placeholder]="eti" name="transaction_id" [(ngModel)]="transactionId" type="text"
                                    #transaction="ngModel" required class="form-control p-2 rounded-3"
                                    [ngClass]="{ 'is-invalid': amountSubmitted && transaction.errors }" appUpi
                                    pattern="[a-zA-Z0-9]*">

                                <div *ngIf="amountSubmitted" class="text-danger_1">
                                    <div *ngIf="transaction.errors?.['required']">{{'Transaction ID Required'| translate
                                        : translateControl | async}}</div>
                                </div>
                            </div>

                            <div class="text-start mt-2">
                                <label class=" col-form-label">{{'Enter Amount:'| translate : translateControl |
                                    async}}</label>
                                <input min="0" max="1000" name="amount" [placeholder]="amount_field"
                                    [(ngModel)]="amount" type="number" #enteramount="ngModel" required
                                    class="form-control p-2 rounded-3"
                                    [ngClass]="{ 'is-invalid': amountSubmitted && enteramount.errors }">

                                <div *ngIf="amountSubmitted" class="text-danger_1">
                                    <div *ngIf="enteramount.errors?.['required']">{{'Amount Required'| translate :
                                        translateControl | async}}</div>
                                </div>
                            </div>

                            <div class="text-start mt-2">
                                <label class="col-form-label">{{'Attach Your Document:'| translate : translateControl |
                                    async}}</label>
                                <input class="w-100 cursor-pointer attach-input" type='file' (change)="img($event)"
                                    name="imgs" [(ngModel)]="imgs" #image="ngModel" required
                                    [ngClass]="{ 'is-invalid': amountSubmitted && image.errors }">
                            </div>
                            <div *ngIf="amountSubmitted" class="text-danger">
                                <div *ngIf="image.errors?.['required']">{{'Image Proof Required'| translate :
                                    translateControl | async}}</div>
                            </div>
                        </div>
                        <div class="col-lg-6 text-center">
                            <img *ngIf="!fiatPreVieImg" style="object-fit: cover;" height="250px" width="250px"
                                src="assets/img/attached-document.jpg" class="img-fluid">
                            <img *ngIf="fiatPreVieImg" style="object-fit: cover;" (error)="brokeImage($event)"
                                height="250px" width="250px" [src]="fiatPreVieImg">
                        </div>
                    </div>
                </div>
            </div>
            <div class=" text-start"
                *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                <button type="submit" class="btn_theme-primary mt-3 p-2">{{'Submit'| translate : translateControl |
                    async}}</button>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>

<!-- paypal -->

<ng-template #paypal>
    <mat-dialog-content class="paypal stripcard">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <h2 class="fs-5">{{'Paypal'| translate : translateControl | async}}</h2>
        <div class="form-group">
            <label class="col-form-label">{{'Enter Amount:'| translate : translateControl | async}} </label>
            <div class="input-group mb-3">
                <input class="form-control border-end-0 border-0" [placeholder]="amount_field" 
                    name="amounts" [(ngModel)]="amount" type="number" min="0" (keypress)="keyPressNumbers($event)">
                <span class="slug_name input-group-text">{{data?.slug | uppercase}}</span>
            </div>
            <ngx-paypal class="paypalbtn" [config]="payPalConfig"></ngx-paypal>
        </div>
        <!-- <ngx-paypal class="paypalbtn" [config]="payPalConfig"></ngx-paypal> -->

    </mat-dialog-content>
</ng-template>

<!-- strip -->

<ng-template #stripe>
    <mat-dialog-content class="stripcard">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true">close</mat-icon>
        <div class="stripe-grp">
            <h2 class="fs-5 fw-bold text-dark">
                <i class="fa fa-credit-card"></i> {{'Credit Card'| translate : translateControl | async}}
            </h2>
            <div class="" id="nav-tab-card">
                <form role="form" [formGroup]="stripeform" (ngSubmit)="submitStripe()">
                    <div class="row mt-4">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="mb-2" for="username">{{'Full name (on the card)'| translate :
                                    translateControl | async}}</label>
                                <input type="text" class="form-control" formControlName="fullName"
                                    [placeholder]="Full_Name" name="fullName"
                                    [ngClass]="{ 'is-invalid': payingsubmitted && sf['fullName']?.errors }">
                                <div *ngIf="payingsubmitted && sf['fullName']?.errors" class="invalid-feedback">
                                    <span *ngIf="sf['fullName'].errors?.['required']">{{'FullName required'| translate :
                                        translateControl | async}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="mb-2" for="amount">{{'Amount'| translate : translateControl |
                                    async}}</label>
                                <input type="number" [min]="0" (keypress)="numberValidation($event)"
                                    class="form-control" formControlName="Amount" [placeholder]="amount_field"
                                    name="Amount" [ngClass]="{ 'is-invalid': payingsubmitted && sf['Amount']?.errors }">
                                <div *ngIf="payingsubmitted && sf['Amount']?.errors" class="invalid-feedback">
                                    <div *ngIf="sf['Amount'].errors?.['required']">{{'Amount is required'| translate :
                                        translateControl | async}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-4">
                        <label class="mb-2" for="cardNumber">{{'Card number'| translate : translateControl |
                            async}}</label>
                        <div class="input-group">
                            <input type="text" class="form-control" (ngModelChange)="onCardNumberChange($event)"
                                formControlName="cardNumber" maxlength="19" (keyup)="creditCheck($event)"
                                [placeholder]="Card_Number" name="cardNumber"
                                [ngClass]="{ 'is-invalid': payingsubmitted && sf['cardNumber']?.errors }">
                            <div class="input-group-append">
                                <span class="input-group-text text-muted">
                                    <span [ngClass]="{'Visa': cardType === 'Visa'}"><i
                                            class="fab fa-cc-visa fa-lg pr-1"></i></span>
                                    <span [ngClass]="{'Amex': cardType === 'Amex'}"><i
                                            class="fab fa-cc-amex fa-lg pr-1"></i></span>
                                    <span [ngClass]="{'mastercard': cardType === 'MasterCard'}"><i
                                            class="fab fa-cc-mastercard fa-lg"></i></span>
                                </span>
                            </div>
                            <div *ngIf="payingsubmitted && sf['cardNumber']?.errors" class="invalid-feedback">
                                <div *ngIf="sf['cardNumber'].errors?.['required']">{{'Card Number is required'|
                                    translate : translateControl | async}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-sm-8">
                            <div class="form-group">
                                <label class="mb-2"><span class="hidden-xs">{{'Expiration'| translate : translateControl
                                        | async}}</span> </label>
                                <div class="input-group">
                                    <select class="form-control" formControlName="month"
                                        [ngClass]="{ 'is-invalid': payingsubmitted && sf['month']?.errors }">
                                        <option value="" disabled selected>{{'MM'| translate : translateControl |
                                            async}}</option>
                                        <option *ngFor="let mon of months let i = index" value="{{1+i}}">{{mon |
                                            translate : translateControl | async}}</option>
                                    </select>
                                    <select class="form-control ms-3" formControlName="year"
                                        [ngClass]="{ 'is-invalid': payingsubmitted && sf['year']?.errors }">
                                        <option value="" selected disabled>{{'YYYY'| translate : translateControl |
                                            async}}</option>
                                        <option *ngFor="let y of years let i = index" value="{{y}}">{{y| translate :
                                            translateControl | async}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-4">
                            <div class="form-group">
                                <label class="mb-2" data-toggle="tooltip" title=""
                                    data-original-title="3 digits code on back side of the card">{{'CVV'| translate :
                                    translateControl | async}} <i class="fa fa-question-circle"></i></label>
                                <input type="number" [min]="0" class="form-control" placeholder="CVV" name="cvv"
                                    formControlName="cvv"
                                    [ngClass]="{ 'is-invalid': payingsubmitted && sf['cvv']?.errors }">
                                <div *ngIf="payingsubmitted && sf['cvv']?.errors" class="invalid-feedback">
                                    <div *ngIf="sf['cvv'].errors?.['required']">{{'CVV is required'| translate :
                                        translateControl | async}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="mt-4 btn btn_theme-primary w-100 py-2" type="submit">
                        <span *ngIf="!payingsubmitted">{{'PAY'| translate : translateControl | async}}</span>
                        <span *ngIf="payingsubmitted">{{'PAYING'| translate : translateControl | async}}</span>
                    </button>
                    <!-- <button *ngIf="!payingsubmitted">Pay</button>
                    <button>Paying</button>                          -->
                </form>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>