import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myorder',
  templateUrl: './myorder.component.html',
  styleUrl: './myorder.component.scss'
})
export class MyorderComponent implements OnInit {
  MyorderForm!: any;
  isSubmitted:boolean = false;



  constructor(
    private formBuilder: FormBuilder,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.createForm()
  }

  createForm(){
    this.MyorderForm = this.formBuilder.group({
      OrderId:['',[Validators.required]],
      Number:['',[Validators.required,Validators.minLength(10)]],
   })
  }

  
  onclick() {
    alert("Register Successfully");
  }

}
