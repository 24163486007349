import { Component, OnInit } from '@angular/core';
import { UserService } from '../services/user.service';
import { FormBuilder, Validators } from '@angular/forms';


@Component({
  selector: 'app-my-lottery',
  templateUrl: './my-lottery.component.html',
  styleUrls: ['./my-lottery.component.scss']
})
export class MyLotteryComponent implements OnInit {
  cartDetails:any=[];
  searchForm:any;
  payload:any = {};
constructor(private callApi:UserService,private fb:FormBuilder){}

  ngOnInit(){
  this.getCartDetails()
  this.createForm()

  }

  createForm(){
    this.searchForm = this.fb.group({
      ticketDrawName:[""],
      ticketStatus:[null]
    })
  }
  
  getCartDetails(){
    this.callApi.getMyLotteryDet(this.payload).subscribe((res:any) => {
      if(res.success){
        this.cartDetails = res.data
        const updatedData = this.cartDetails.map((each:any) => each.ticketDetails.ticket_no = each.ticketDetails.ticket_no.toString())    
      }
    })
  }

  onSearch(){
    if(this.searchForm.valid){
      this.payload = this.searchForm.value
      this.getCartDetails()
    }else{
      this.callApi.showError("Please give valid inputs")
    }
  }

  onClear(){
    this.searchForm.reset()
    this.payload = {}
    this.getCartDetails()
  }
  
}
