<div class="wave p-3">
  <div class="dashboard-section">
    <div class="container">
      <h1 class="text-dark text-center p-3 fw-bold">Cart Details</h1>
      <div class="row justify-content-center">
        <div class="col-lg-8">
          <div class="">
            <div class="table-responsive">
              <table class="table">
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Date</th>
                    <th>Ticket Name</th>
                    <th>Ticket Number</th>
                    <th>Price</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let details of tickets; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ currentDate }}</td>
                    <td>{{ details.ticketDrawName }}</td>
                    <td>{{ details.ticket_no }}</td>
                    <td>{{ details.costPerTicket | currency }}</td>
                    <td>
                      <button type="button" (click)="removeTicket(details.ticket_id)" class="btn btn-danger">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </button>
                    </td>
            
                  </tr>
                </tbody>
                <tr *ngIf="tickets.length == 0"> <td  class="text-center p-3"  colspan="12">-- No Items Added ---</td></tr>
                <tfoot *ngIf="tickets.length > 0">
                  <tr>
                    <td colspan="4" class="text-center p-3">Total</td>
                    <td colspan="2">{{ totalTicketsCost | currency }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
          <div class="text-center" *ngIf="tickets.length > 0">
            <button (click)="buyNow()" class="btn bg-success text-white">Buy Now</button>

          </div>
        </div>
      </div>
    </div>
  </div>
</div>
