import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from "../../services/user.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginComponent implements OnInit {

  loginForm: any;
  isSubmitted: boolean = false;
  button = "Login";
  isLoading: boolean = false;
  submitted: boolean = false;
  form: any;
  launch: any;
  showPassword: boolean = false;


  constructor(
    private fb: FormBuilder,
    private callApi: UserService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: [
        "",
        [
          Validators.required,
          Validators.pattern(
            "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,}$"
          ),
        ],
      ],
    });
  }
  onclick() {
    alert("Login successfully");
  }


  onSubmit() {
    this.isSubmitted = true
    // if(this.loginForm.valid){
    let payload = this.loginForm.value
    this.callApi.userLogin(payload).subscribe((res: any) => {
      let user_details: any = res?.data
      if (res.success) {
        localStorage.setItem('access_token', user_details?.token)
        this.callApi.getToken()
        console.log(res.data.userdata.authentic_status, "resssss")
        if (res.data.userdata.email_verify == "0") {

          this.callApi.showSuccess(res.message);
          this.loginForm.reset()
          localStorage.setItem('access_token', res.data?.token)
          this.callApi.getToken()
          this.router.navigate(["/usermail-otp"])
          this.isSubmitted = false
          return;
        }
        if (res.data.userdata.authentic_status != 0) {
          const mail = this.callApi.encryptData(user_details?.email)
          this.router.navigate(['/tfa-verification'], { queryParams: { mail: mail } })
          this.isSubmitted = false
        }
        else {
          this.router.navigate(["/welcome"]);
        }
      }
      else {
        this.callApi.showError(res.message)
      }
    })
  }
  keyPressNumbers(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode < 46 || charCode > 57 && charCode <= 63 || charCode > 64 && charCode < 65 || charCode > 90 && charCode < 95 || charCode > 122 || charCode == 47) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  // check two factor authentication


  // if(res.data.userdata.email_verify === "1"){
  // this.callApi.showSuccess(res.message)
  // this.loginForm.reset()
  // localStorage.setItem('access_token', res.data?.token)
  // this.callApi.getToken()
  // this.route.navigate(['/tfa-verification'])
  // this.isSubmitted = false
  // }else{
  // const mail = this.callApi.encryptData(user_details?.email)
  // this.route.navigate(['/tfa-verification'], { queryParams: { mail: mail } })
  // this.isSubmitted = false
  // }
  // }else{
  // this.callApi.showError(res.message)
  // }
  // })
  // }



  //   onSubmit() {
  //     this.isSubmitted = true;
  //     // if(this.loginForm.valid){
  //     let payload = this.loginForm.value;
  //     this.callApi.userLogin(payload).subscribe((res: any) => {
  //       let user_details: any = res?.data;
  //       if (res.success) {
  //         localStorage.setItem("access_token", user_details?.token);
  //         this.callApi.getToken();
  //         if (user_details?.email_verify == 0) {
  //           const mail = this.callApi.encryptData(user_details?.email);
  //           this.callApi.showInfo(res.message);
  //           // this.router.navigate(['/dashboard'])
  //           this.router.navigate(["/usermail-otp"], {
  //             queryParams: { mail: mail },
  //           });
  //           this.callApi.stopSpinner();
  //           return;
  //         }
  //         // check two factor authentication
  //         if (user_details?.userdata?.authentic_status != "0") {
  //           this.router.navigate(["/welcome"]);
  //         }

  //         // if (res.data.userdata.email_verify === "1") {
  //         //   this.callApi.showSuccess(res.message);
  //         //   this.loginForm.reset();
  //         //   localStorage.setItem("access_token", res.data?.token);
  //         //   this.callApi.getToken();
  //         //   this.route.navigate(["/tfa-verification"]);
  //         //   this.isSubmitted = false;
  //         // } else {
  //         //   const mail = this.callApi.encryptData(user_details?.email);
  //         //   this.route.navigate(["/tfa-verification"], {
  //         //     queryParams: { mail: mail },
  //         //   });
  //         //   this.isSubmitted = false;
  //         // }
  //       } 
  //       // else {
  //       //   this.callApi.showError(res.message);
  //       // }
  //     });
  //     // }
  //   }
}
