<header class="bg_theme sticky-md-top">
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg d-flex">
            <div class="mb-2 mb-md-0">
                <img src="assets/img/addus_logo_white.png" class="img-fluid img-add" width="200" alt="logo">
            </div>
            <div>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="collapse navbar-collapse justify-content-between                        "
                id="navbarSupportedContent">
                <ul class="navbar-nav mb-2 mb-lg-0">
                    <li><a routerLink="/welcome" routerLinkActive="active-link" class="nav-link px-2 fw-bold">Home</a>
                    </li>
                    <li><a routerLink="/result" routerLinkActive="active-link" target="_self" class="nav-link px-2 text-dark fw-bold ms-2 ms-lg-4">Result</a>
                    </li>
                    <li class="ms-2 ms-lg-4"><a routerLink="/my-lottery" routerLinkActive="active-link" target="_self"
                        class="nav-link px-2 text-dark fw-bold">My Lottery</a></li>
                        
                    <!-- <li class="ms-2 ms-lg-4"><a routerLink="/myorder" routerLinkActive="active-link" target="_self"
                            class="nav-link px-2 text-dark fw-bold">My order</a></li>
                    <li class="ms-2 ms-lg-4"><a routerLink="/claim" routerLinkActive="active-link" target="_self"
                            class="nav-link px-2 text-dark fw-bold">Claim</a></li> -->
                    <!-- <li class="ms-2 ms-lg-4"><a routerLink="/result" routerLinkActive="active-link" target="_self" class="nav-link px-2 text-dark fw-bold">Result</a></li> -->
                    
                    <li class="ms-2 ms-lg-4"><a routerLink="/wallet" routerLinkActive="active-link" target="_self"
                            class="nav-link px-2 text-dark fw-bold">Assets</a></li>
                </ul>
                <!-- <div class="d-xl-flex align-items-center d-inherit list-unstyled mt-3 mt-xl-0">
                    <a routerLink="/mycart" target="_self">
                        <span class="me-5">
                            <img src="./assets/img/cart.png" alt="cart" class="wobble">
                        </span>
                    </a> -->
                          <!-- Cart Icon with Notification Badge -->
        <div class="d-xl-flex align-items-center d-inherit list-unstyled mt-3 mt-xl-0">
            <a routerLink="/mycart" target="_self">
                <span class="me-5">
                    <img src="./assets/img/cart.png" alt="cart" class="wobble cart-img">
                    <!-- Notification Badge -->
                    <span class="notification-badge wobble">0</span>
                </span>
            </a>
        
                    

                    <!-- <a href="#" target="_self">
                        <span  class="nav-link text-decoration-none dropdown-toggle fw-bold">
                            <img src="../../../assets/img/avatar1.png" alt="profile" class="avatar1-size">
                        </span>
                    </a> -->
                    <li class="nav-item d-none d-xl-block mt-3 mx-0 mx-xl-3 m my-2 my-lg-0" *ngIf="token">
                        <div class="dropdown hover_show position-relative">
                            <div class="avatar-preview">
                                <div id="imagePreview">
                                </div>
                            </div>
                            <a class="nav-link text-decoration-none dropdown-toggle fw-bold" href="#" role="button"
                                data-bs-toggle="dropdown" aria-expanded="false" type="button">
                                <span *ngIf="!profileImage"><img src="../../../assets/img/avatar1.png" alt="profile-img"
                                        width="36px" height="36px"></span>
                                <span *ngIf="profileImage"><img [src]="base_url+profileImage" (error)="getImage($event)"
                                        data-toggle="tooltip" data-placement="bottom" title="Profile image"
                                        class="img-fluid rounded-circle user-profile-img" alt="" width="36"
                                        height="36"></span>
                            </a>
                            <ul class="dropdown-menu shadow border-0 w-m login-menu-details list-unstyled db-list">
                                <li>
                                    <a routerLink="/profile" routerLinkActive="active"
                                        class="d-flex align-items-center text-decoration-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                            fill="currentColor" class="bi bi-person-square" viewBox="0 0 16 16">
                                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0" />
                                            <path
                                                d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1v-1c0-1-1-4-6-4s-6 3-6 4v1a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z" />
                                        </svg>
                                        <h6 class="mb-0 profile-name">profile</h6>
                                    </a>
                                </li>
                                <li>
                                    <a target="_self"  (click)="logOut()" *ngIf="token" class="d-flex align-items-center text-decoration-none" routerLinkActive="active">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                                            <path fill-rule="evenodd" d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0z"/>
                                            <path fill-rule="evenodd" d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708z"/>
                                          </svg>
                                        <h6  class="mb-0 profile-name">Logout</h6>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    <a routerLink="/register" target="_self" *ngIf="!token">
                        <button type="button" class="login btn btn-outline-warning">Sign up</button>
                    </a>

                    <a routerLink="/login" target="_self" *ngIf="!token">
                        <button type="button" class="login btn btn-warning">Login</button>
                    </a>
             
                    <li class="btn-group nav-item d-none d-lg-block my-4 my-xl-0" *ngIf="token">
                        <a class="btn_theme-primary" routerLink="/fiat-deposit">Deposit</a>
                    </li>
                </div>
            </div>
        </nav>
        <!-- <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-md-around py-3 mb-4">
            <div class="col-md-3 mb-2 mb-md-0">
                <img src="assets/img/addus_logo_white.png" class="img-fluid" width="200" alt="logo">
            </div>
    
            <ul class="nav col-12 col-md-auto mb-2 justify-content-center mb-md-0">
                <li><a href="#" class="nav-link px-2 fs-5 border-bottom border-warning text-warning fw-bold">Home</a></li>
                <li class="ms-4"><a href="/register.html" target="_self" class="nav-link px-2 link-warning fs-5 text-dark fw-bold">My order</a></li>
                <li class="ms-4"><a href="/register.html" target="_self" class="nav-link px-2 link-warning fs-5 text-dark fw-bold">Claim</a></li>
                <li class="ms-4"><a href="/register.html" target="_self" class="nav-link px-2 link-warning fs-5 text-dark fw-bold">Result</a></li>
                <li class="ms-4"><a href="/register.html" target="_self" class="nav-link px-2 link-warning fs-5 text-dark fw-bold">My Lottery</a></li>
            </ul>
    
            <div class="col-md-3 text-end">
                <a href="#" target="_self">
                    <span  class="me-5">
                        <img src="./assets/img/cart.png" alt="cart" class="wobble">
                    </span>
                </a>
                <a href="#" target="_self">
                    <span  class="">
                        <img src="/assets/img/avatar.png" alt="cart" class="">
                    </span>
                </a>
                <a href="./register.html" target="_self">
                    <button type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal" class="login btn btn-outline-warning">Sign up</button>
                </a>
                <a href="/login.html" target="_self">
                    <button type="button"  data-bs-toggle="modal" data-bs-target="#exampleModal" class="login btn btn-warning">Login</button>
                </a>
            </div>
        </div>                -->
    </div>
</header>