<section class="section-padding d-flex align-items-center useremail-sec ">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-sm-10 card rounded-4 p-3 col-12 col-md-8 col-lg-6  mx-auto mt-4 mt-lg-0">
                <div class="login_div email-auth p-4 rounded-3  night-bg">
                    <div class="d-sm-flex justify-content-between align-items-center mb-4">
                        <h2 *ngIf="user_tfa?.authentic_status === '1'" class="fw-semibold mb-2 mb-sm-0">{{'Google Authenticate Code'}}</h2>
                        <h2 *ngIf="user_tfa?.authentic_status === '2'" class="fw-semibold mb-2 mb-sm-0">{{'Email Authenticate Code'}}</h2>
                        <div class="text-end">
                            <a class="btn btn-theme-button d-inline-block text-decoration-none cursor-pointer btn-btn"
                            (click)="logOut()">{{'Back?'}}</a>
                        </div>
                    </div>
                    <form [formGroup]="otp_form">
                        <div class="form-group add_form_group">
                            <input formControlName="otp" maxlength="6" type="text"
                                class="form-control rounded-3 p-2" id="exampleInputEmail1"
                                aria-describedby="emailHelp" [placeholder]="Enter_2FA_code">
                            <div *ngIf="isSubmited" class="text-danger mt-2">
                                <p *ngIf="formVerify['otp'].errors?.['required']"> {{'OTP is required'}} </p>
                                <p
                                    *ngIf="formVerify['otp'].errors?.['minlength']||formVerify['otp'].errors?.['maxlength']">
                                    {{'OTP Must be 6 Digits'}}</p>
                            </div>
                            <div class="btn_div">
                                <div class="d-flex justify-content-between align-items-center mt-3 ">
                                    <div>
                                     
                                        <!-- *ngIf="user_tfa?.authentic_status === '2'" type="button"
                                            (click)="emailAuthentication()"
                                            class="btn" -->
                                            <!-- <button type="button" class="btn btn-btn">Resend OTP</button> -->
                                    </div>
                                    <div>
                                        <button *ngIf="user_tfa?.authentic_status === '1'" type="submit" (click)="verifyOTP()"
                                            class="btn btn-btn ">Verify</button>
                                        <button *ngIf="user_tfa?.authentic_status === '2'" type="button"
                                            (click)="emailTfaVerify()" class="btn btn-btn">Verify</button>
                                    </div>                                    
                                </div>
                                <div class="add_right_forgot mt-3">
                                    <a class="theme-text-color-1" routerLink="/tfa-losting">{{'2FA Code Losting ?'}}</a>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>