<section class="dashboard_section">
    <div class="container py-5">
        <div class="row">
            <div class="col-lg-3 mt-4">
                <div class="card rounded-5 bg-white p-4 m-auto h-100">
                    <div class="profile-image">
                        <img src="../../assets/img/avatar1.png" alt="avatar1" class="img-fluid mb-3" />
                        <div>
                            <p class="mb-0 text-secondary">Name:</p>
                            <p>{{ data.user_name }}</p>
                        </div>
                        <div class="mt-3">
                            <p class="text-secondary mb-0">Mail:</p>
                            <p>{{ data.email }}</p>
                        </div>
                    </div>
                    <div>
                        <p class="text-secondary mb-0 mt-2">Kyc Verification</p>
                        <button *ngIf="data.kyc_verify == 0" routerLink="/kyc-sub" class="text-props btn  dis-btn">Kyc
                            yet to submitted</button>
                        <button *ngIf="data.kyc_verify == 1" class="text-props btn dis-btn" routerLink="/kyc-sub">Kyc
                            submitted waiting for admin approval</button>
                        <button *ngIf="data.kyc_verify == 2" class="text-props btn dis-btn" routerLink="/kyc-sub">Kyc
                            Verified</button>
                            <button *ngIf="data.kyc_verify == 3" routerLink="/kyc-sub" class="text-props btn  dis-btn">Kyc
                                Rejected</button>


                    </div>

                </div>
            </div>

            <div class="col-lg-9 mt-4">
                <div class="card rounded-5 bg-white p-4">
                    <div class="d-flex justify-content-between">
                        <h3>My Recent Lottery Draws</h3>
                        <button class="login btn mb-3" routerLink="/my-lottery">View All</button>
                    </div>
                    <div class="profile-table table-responsive">
                        <table class="w-100">
                            <thead class="text-center">
                                <tr>
                                    <th class="p-2">S.No</th>
                                    <th class="p-2">ProfileImage</th>
                                    <th class="p-2">Date</th>
                                    <th class="p-2">Ticket ID</th>
                                    <th class="p-2">TicketName</th>
                                    <th class="p-2">TicketPrice</th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr *ngFor="let cartDetail of cartDetails;let i = index">
                                    <td>{{i+1}}</td>
                                    <td><img src="../../assets/img/avatar1.png" alt="profile-image" class="profile-image"></td>
                                    <td>{{cartDetail.resultDate | date:'shortDate'}}</td>
                                    <td>{{cartDetail.ticketDetails.ticket_no}}</td>
                                    <td>{{cartDetail.ticketDrawName}}</td>
                                    <td>{{cartDetail.ticketDrawPrice}}</td>
                                </tr>
                                <!-- Repeat for other rows -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>


        <div class="row h-100">
            <div class="col-lg-6 mt-4">

                <div class="card rounded-5 h-100 d-flex align-items-center justify-content-center bgImage">
                    <h1>Wallet Balance</h1>
                    <div *ngIf="data.kyc_verify == 0">
                        <button class="btn btn-sm mb-2 me-2 is-btn" *ngIf="data.kyc_verify =='0'"
                            routerLink="/kyc-sub">Kyc Not Yet Submitted</button>
                    </div>
                    <div *ngIf="data.kyc_verify == 1">
                        <button class="btn btn-sm mb-2 me-2 is-btn" *ngIf="data.kyc_verify =='0'"
                            routerLink="/kyc-sub">Kyc submitted waiting for admin approval</button>
                    </div>
                    <div *ngIf="data.kyc_verify == 3">
                        <button class="btn btn-sm mb-2 me-2 is-btn" *ngIf="data.kyc_verify =='0'"
                            routerLink="/kyc-sub">Kyc Rejected</button>
                    </div>

                    <div *ngIf="data.kyc_verify == 2">
                        <table class="table mb-0" *ngIf="data.kyc_verify == 2">
                            <thead>
                                <tr>
                                    <th>Coin</th>
                                    <th>Name</th>
                                    <th>Balance</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let coin of walletDetails">
                                    <td>
                                        <img src="{{ coin.image }}" alt="img" class="wallet_coin_img" />
                                    </td>
                                    <td>{{ coin.slug }}</td>
                                    <td>{{ coin.balance }}</td>
                                </tr>
                            </tbody>
                        
                        </table>
                    </div>
                
                   
                </div>
            </div>

            <div class="col-lg-6 mt-4">
                <div class="card rounded-5  p-3 m-auto h-100 bg-color">
                    <h3 class="fw-semibold mb-0">{{ "2FA Verification" }}</h3>
                    <div
                        class="justify-content-between align-items-center border-bottom mt-3 pb-2 text-center text-md-start ms-2">
                        <div class="mb-2">
                            <h3 class="fw-bold mb-0">{{ "Google Authentication" }}</h3>
                            <p class="twofa_content">
                                2FA Code will be generated by your Google Authenticator app available for both iOS
                                and Android OS
                            </p>
                        </div>
                        <div class="">
                            <button class="btn btn-sm mb-2 me-2 is-btn" *ngIf="data.kyc_verify =='0'"
                                routerLink="/kyc-sub">Kyc Not Yet Submitted</button>
                            <button class="btn btn-sm mb-2 me-2 is-btn" *ngIf="data.kyc_verify == '1'"
                                routerLink="/kyc-sub">Waiting for KYC Approval</button>
                            <a *ngIf="data?.authentic_status === '0' && data?.kyc_verify == 2" routerLink="/two-fa"
                                class="btn-theme-button btn-sm mb-2 me-2 sub-btn">Enable</a>
                            <a *ngIf="data?.authentic_status === '1'" (click)="enable(disableTFA,'google')"
                                class="btn btn-sm sub-btn">Disable</a>

                            <a matTooltip="" *ngIf="data?.kyc_verify =='3' " routerLink="/kyc-sub"
                                class="btn btn-sm mb-2 me-2 is-btn">KYC Rejected Upload Again</a>
                        </div>
                    </div>
                    <div
                        clas
                        s=" ms-2 d-sm-flex justify-content-md-between justify-content-center align-items-center text-center text-lg-start">
                        <div class="align-items-center text-center text-md-start">
                            <div class="mt-2">
                                <h3 class="fw-bold mb-0">{{ "Email Authentication" }}</h3>
                                <p class="twofa_content mb-2">{{ "Send 2FA code via Email" }}</p>
                                <div *ngIf="data?.authentic_status === '0' || data?.authentic_status === '2'">
                                    <button class="btn btn-sm me-2 mb-2 is-btn" *ngIf="data.kyc_verify == '0'"
                                        routerLink="/kyc-sub">Kyc Not Yet Submitted</button>
                                    <button class=" btn-sm me-2 mb-2 is-btn" *ngIf="data.kyc_verify == '1'"
                                        routerLink="/kyc-sub">Waiting for KYC Approvel</button>
                                    <a *ngIf="data.authentic_status == '0' && data.kyc_verify == 2"
                                        (click)="getEmailOtp()" class="btn-theme-button btn btn-sm me-2 mb-2 sub-btn"><i
                                            class="loading-icon fa"
                                            [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{button
                                        }}</a>
                                    <a matTooltip="" *ngIf="data.kyc_verify =='3'" routerLink="/kyc-sub"
                                        class="btn btn-sm me-2 mb-2 is-btn">KYC Rejected Upload Again</a>
                                    <a *ngIf="data.authentic_status == '2'" (click)="enable(disableTFA,'Email')"
                                        class="btn btn-sm me-2 mb-2 sub-btn">Disable</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="col-lg-4 mt-4">
                <div class="card rounded-5 p-5 text-align-start m-auto h-100 meta-color">
                    <div class="d-flex align-items-center">
                        <div>
                            <img src="../../assets/img/metamask.png" alt="Metamask" class="img-fluid" width="100" />
                        </div>
                        <div class="ms-4">
                            <h2>Metamask</h2>
                            <h4>Wallet Address</h4>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 mt-4   ">
                    <img src="../../assets/img/img1.png" alt class="first-ad-img" />
                </div>
                <div class="col-lg-4 mt-4">
                    <img src="../../assets/img/img2.png" alt class="second-ad-img" />
                </div>
                <div class="col-lg-4 mt-4">
                    <img src="../../assets/img/img3.png" alt class="third-ad-img" />
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #disableTFA>
    <div class="p-4">
        <div>
            <mat-icon class="close-icon btn-closee" [mat-dialog-close]="true">close</mat-icon>
            <h2 class="fs-semibold mb-3">
                {{ "Disable" }} {{ workmail }} {{ "2FA" }}
            </h2>
        </div>
        <div class>
            <div class="form-group">
                <label class="profile_label fs-13 blk mb-2">{{ "Authenticate Code" }}
                    <span class="maditory_span">*</span></label>
                <form [formGroup]="enableForm" *ngIf="workmail == 'Google'" autocomplete="off">
                    <input (keypress)="keyPressNumbers($event)" maxlength="6" name="name" formControlName="otp"
                        type="text" class="form-control mb-3" placeholder="Enter Your OTP">
                    <a (click)="backEnable(workmail)" class="btn w-100 text-center dis-btn">Disable
                    </a>
                </form>
                <form *ngIf="workmail == 'Email'" autocomplete="off">
                    <input name="name" maxlength="6" (keypress)="keyPressNumbers($event)" [(ngModel)]="mailOTP"
                        type="text" class="form-control mb-3" placeholder="Enter Your OTP">
                    <a (click)="backEnable(workmail)" class="dis-btn w-100 p-2 text-center"> Disable
                    </a>
                </form>
            </div>
        </div>
    </div>
</ng-template>