<!-- header -->
<main class="py-5 mt-3">
    <div class="slide"></div>
    <div class="slide slide2"></div>
    <div class="slide slide3"></div>
    <div class="container">
        <div class="shadow rounded-5 bg-white box">
            <div class="color row align-items-center justify-content-center">
                <div class="col-6 col-auto text-center d-xl-block d-none rounded-start-5">
                    <div class="h-100">
                        <div>
                            <img src="assets/img/lottery-ticket-1.gif" class="img-fluid " alt="Lottery">
                        </div>
                        <div class="mt-3">
                            <img src="assets/img/Lottery-Numbers.gif" class="img-fluid " alt="Lottery">
                        </div>
                    </div>
                </div>
                <div class="col-md-9 col-lg-6">
                    <div class="card rounded-end-5 border-0 p-md-5 bg-transparent h-100">
                        <div class="card-body">
                            <h1 class="card-title text-center">Check Lottery Results</h1>
                            <p class="fw-semibold text-center">Updates every draw result at 5:00 PM, after
                                government officially publish</p>

                            <form [formGroup]="LotteryForm" (ngSubmit)="onSubmit()">
                                <div class="text-center">
                                    <label for="name" class="form-label mb-0">
                                        <h2 class="mb-0">Draw Date</h2>
                                    </label>
                                    <div>
                                        <input type="date" class="p-2" name="select" placeholder="dd-mm-yyyy" value=""
                                            [attr.max]="today" formControlName="Date" class="form-control" required>
                                        <span class="text-danger"
                                            *ngIf=" isSubmitted && controls['Date'].hasError('required')"> Result date
                                            is required</span>
                                        <div class="text-danger"
                                            *ngIf="isSubmitted && controls['Date']?.errors?.['maxDate'] && !controls['Date'].hasError('required')">
                                            The date must not exceed today.
                                        </div>
                                    </div>
                                </div>
                                <div class="text-center mt-3">
                                    <label for="name" class="form-label mb-0">
                                        <h2 class="mb-0">Ticket Draw Name</h2>
                                    </label>
                                    <div>
                                        <input type="text" class="p-2" id="name" placeholder="Enter Ticket Name"
                                            formControlName="ticketNumber" class="form-control" required>
                                        <span class="text-danger"
                                            *ngIf=" isSubmitted && controls['ticketNumber'].hasError('required')">Lottery
                                            ticket is required</span>
                                    </div>
                                </div>
                                <div class="button text-center mt-3">
                                    <button type="submit" class="submit btn btn-warning p-2">Submit</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<section>
    <div class="container py-4">
        <div class="row d-flex align-items-center bg-transparent ">
            <div class="col-xl-12 text-center">
                <img src="./assets/img/jackpot.png" alt="jackpot" class="hithere img-fluid">
            </div>
            <!-- <div class="col-xl-6 text-center text-xl-start mt-4 mt-xl-0">
                <h1 class="fw-bold">Play Next Jackpot Draw</h1>
                <p class="mt-4">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Consequatur, quidem eos,
                    temporibus molestiae sint sed quod pariatur inventore excepturi et aliquam fugiat nam, neque ex
                    perspiciatis quam aut voluptas itaque? inventore excepturi et aliquam fugiat nam, neque ex
                    perspiciatis quam aut voluptas itaque?</p>
                <div class="col">
                    <div class="bg-white shadow p-4 rounded-3 cart">
                        <div>
                            <div class="d-flex">
                                <h3 class="fw-bold">Next Jackpot. $6.5</h3>
                                <hr>
                            </div>
                            <div class="d-flex justify-content-between mt-3">
                                <div class="d-flex grid gap-4">
                                    <div class="text-center  mb-md-0">
                                        <div class="d-flex grid gap-1 justify-content-center justify-content-md-start">
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">0</h4>
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">3</h4>
                                        </div>
                                        <h6 class="text-secondary day">Day</h6>
                                    </div>
                                    <div class="text-center  mb-md-0">
                                        <div class="d-flex grid gap-1 justify-content-center justify-content-md-start">
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">0</h4>
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">7</h4>
                                        </div>
                                        <h6 class="text-secondary day .mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
                                            font: 400 calc(14px*.67)/20px Roboto, sans-serif;
                                            margin: 0 0 0px !important;
                                        }">Hrs</h6>
                                    </div>
                                    <div class="text-center  mb-md-0">
                                        <div class="d-flex grid gap-1 justify-content-center justify-content-md-start">
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">5</h4>
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">2</h4>
                                        </div>
                                        <h6 class="text-secondary day .mat-h6, .mat-typography .mat-h6, .mat-typography h6 {
                                            font: 400 calc(14px*.67)/20px Roboto, sans-serif;
                                            margin: 0 0 0px !important;
                                        }">Mins</h6>
                                    </div>
                                    <div class="text-center  mb-md-0">
                                        <div class="d-flex grid gap-1 justify-content-center justify-content-md-start">
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">4</h4>
                                            <h4 class="shadow rounded-3 border bg-white p-2 fw-bold">7</h4>
                                        </div>
                                        <h6 class="text-secondary day">sec</h6>
                                    </div>
                                </div>
                                <div>
                                    <a href="\register.html" target="_self">
                                        <button type="button" class="btn-1 btn btn-primary btn-lg border-0 ">Play
                                            Now</button>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</section>
<!-- header-end -->

<!-- <section class="py-4">
    <div class="container-fluid bg-transparent">
        <div class="row">
            <div class="d-xl-flex grid gap-5 justify-content-center">
                <div class="text-center">
                    <img src="./assets/img/sm-banner.png" alt="" class="drop-s img-fluid">
                </div>
                <div class="d-md-flex gap-5 justify-content-center">
                    <div class="text-center mt-1 ms-2">
                        <div>
                            <img src="./assets/img/hoosier.png" alt="hossier" class="pulse img-fluid">
                        </div>
                        <h4 class="text-secondary">Hoosier Lottery</h4>
                        <h3>$24 Million</h3>
                        <div>
                            <h5 class="glow text-theme-1 fw-bold border bg-dark p-2 rounded-2 mb-0 ">Play Now</h5>
                        </div>
                    </div>
                    <div class="text-center mt-1 ms-2">
                        <div>
                            <img src="./assets/img/mega.png" alt="hossier" class="pulse img-fluid">
                        </div>
                        <h4 class="text-secondary">Mega Millions</h4>
                        <h3>$33 Million</h3>
                        <div>
                            <h5 class="glow text-theme-1 fw-bold border bg-dark p-2 rounded-2 mb-0">Play Now</h5>
                        </div>
                    </div>
                    <div class="text-center mt-1 ms-2">
                        <div>
                            <img src="./assets/img/france.png" alt="hossier" class="pulse img-fluid">
                        </div>
                        <h4 class="text-secondary">Lotto France</h4>
                        <h3>$45 Million</h3>
                        <div>
                            <h5 class="glow text-theme-1 fw-bold border bg-dark p-2 rounded-2 mb-0">Play Now</h5>
                        </div>
                    </div>
                    <div class="text-center mt-1 ms-2">
                        <div>
                            <img src="./assets/img/hoosier.png" alt="hossier" class="pulse img-fluid">
                        </div>
                        <h4 class="text-secondary">Hoosier Lottery</h4>
                        <h3>$65 Million</h3>
                        <div>
                            <h5 class="glow text-theme-1 fw-bold border bg-dark p-2 rounded-2 mb-0">Play Now</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->

<!-- card-section-start -->
<section class="py-3">
    <div class="container">
        <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1 g-4">
            <div class="col " *ngFor="let details of ticketDrawDet">
                <div class="p-4 grow shadow rounded-5 bg-white text-center">
                    <div class="">
                        <img src="assets/img/pack_wed.png" alt="price" class="img-fluid">
                    </div>
                    <h3 class="">{{details.ticketDrawName}}</h3>
                    <!-- <h4 class="text-theme-1 fw-bold">Rs.{{details.costPerTicket}} USDT</h4> -->
                    <p>{{details.ticketDrawDate | date:"yyyy-MM-dd"}}</p>
                    <hr>
                    <p>{{details.description}}</p>
                    <a>
                        <button type="button" class="btn-1 btn btn-primary btn-lg border-0"
                            (click)="buyNow(details._id)">Buy Now</button>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- card-section-end -->

<!-- section-claim -->
<!-- <section class="py-4">
    <div class="container">
        <div class="row align-items-center text-lg-start text-center bg-white shadow rounded-5 p-4 ">
            <div class="col-lg-8 order-lg-1 order-2">
                <div>
                    <h3 class="fs-2 text-theme-1 fw-bold">How to Receive Price Money</h3>
                    <p class=" fs-5">Click the below Register <q>Claim Button</q> for claim information</p>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus aliquam sed odit veniam
                        exercitationem officiis porro at? Earum numquam pariatur beatae aliquid harum suscipit
                        reiciendis dolorum asperiores facilis consequuntur. Nemo.</p>
                    <div>
                        <a href="\register.html" target="_self">
                            <button type="button" class="btn-1 btn btn-primary btn-lg border-0">Register Claim</button>
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 order-lg-2 order-1">
                <img src="assets/img/receive money.png" alt="receive money" class="img-fluid">
            </div>
        </div>
    </div>
</section> -->
<!-- section-claim-end -->

<section class="py-5">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-xl-3 bg-poster hover05 text-center  mb-xl-0">
                <img src="/assets/img/poster1.png" alt="poster1" class="post">
               
            </div>
            <div class="col-md-6 col-xl-3 bg-poster hover05 text-center  mb-xl-0">
                <img src="/assets/img/poster2.png" alt="poster1" class="post">
               
            </div>
            <div class="col-md-6 col-xl-3 bg-poster hover05 text-center  mb-xl-0">
                <img src="/assets/img/poster1.png" alt="poster1" class="post">
               
            </div>
            <div class="col-md-6 col-xl-3 bg-poster hover05 text-center  mb-xl-0">
                <img src="/assets/img/poster2.png" alt="poster1" class="post">
             
            </div>
        </div>
    </div>
</section>