<section class="header-padding tfalost-sec useremail-sec ">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6">

                <div class="position-relative text-start box-effect card m-5 p-5">

                    <div class>
                        <form action [formGroup]="form">
                            <div>
                                <h2 class="mb-3">{{'For Security'}}</h2>
                            </div>
                            <div>
                                <label class="col-form-label label-form" for> {{'Name'}}
                                    <span class="text-danger">*</span></label>
                                <input type="text" id="exampleInputEmail1" formControlName="user_name"
                                    placeholder="{{'Enter Name'}}" class="form-control input-border" maxlength="30">
                                <div *ngIf="submitted && f['user_name']?.errors" class="text-danger">
                                    <div *ngIf="f['user_name'].errors?.['required']">
                                        {{'Username Field is Required'}}
                                    </div>
                                </div>
                            </div>
                            <div class="mt-3">
                                <label class="col-form-label label-form" for>{{'Date Of Birth'}} <span
                                        class="text-danger">*</span></label>
                                <input type="date" formControlName="dob" id="exampleInputEmail1"
                                    class="form-control input-border">
                                <div *ngIf="submitted && f['dob']?.errors" class="text-danger">
                                    <div *ngIf="f['dob'].errors?.['required']">
                                        {{'DOB Field is Required'}}</div>
                                </div>
                            </div>
                            <!-- <div class="mt-3">
                                <label class="col-form-label" for="">You Account User Name *</label>
                                <input formControlName="account_name" type="text" id="exampleInputEmail1"
                                    placeholder="Enter Account Name" class="form-control">
                            </div> -->
                            <div class="mt-3">
                                <label class="col-form-label label-form" for>{{'Phone Number'}} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="mobile_number" type="number" id="exampleInputEmail1"
                                    placeholder="Phone Number" class="form-control input-border">
                                <div *ngIf="submitted && f['mobile_number']?.errors" class="text-danger">
                                    <div *ngIf="f['mobile_number'].errors?.['required']">
                                        {{'phone number Field is
                                        Required '}}</div>
                                </div>
                            </div>
                            <div class="mt-3">

                                <div class>
                                    <label class="col-form-label label-form" for>{{'Upload Image'}} <span
                                            class="text-danger">*</span>
                                    </label>
                                    <div class="avatar-upload mb-2">
                                        <label for="image">
                                            <img *ngIf="!userimage" style="cursor: pointer;" class="img-fluid"
                                                src="../../assets/img/avatar1.png" width="100px" height="100px">
                                            <img *ngIf="userimage" style="cursor: pointer;" class="img-fluid"
                                                [src]="imagepath+userimage" width="100px" height="100px">
                                        </label>
                                        <input id="image" formControlName="image" hidden type="file"
                                            accept="application/*|image/*" (change)="uploadImage($event)">
                                        <div *ngIf="submitted && f['image']?.errors" class="text-danger">
                                            <div *ngIf="f['image'].errors?.['required']">
                                                {{'Image Field is Required '}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <h2>{{'Upload Your Kyc Document'}}
                                    *
                                    {{profileData?.id_type}}</h2>
                            </div>
                            <div class="mt-4">
                                <button type="submit" class="btn btn-btn" (click)="submit()">{{'Submit'}}</button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>