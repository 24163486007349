<div class="bg-img ">
    <div class="container py-5">
        <h1 class="text-center mb-0">{{ticketDrawName}}</h1>
        <div class="row">
            <div class="col-lg-3 mt-4">
                <div class="card p-2">
                    <h2 class="text-center">Ticket Categories</h2>
                    <ul class="radio-list price-select mb-0 ps-0" *ngFor="let option of selectedOption; let i = index">
                        <li (click)="onClickOption(option)" >
                            <label [ngClass]="{'custom-radio': true, 'd-flex': true, 'w-100': true, 'active': selectedOption === i}">
                                <div class="">
                                    <input type="radio" name="option" [value]="option.ticketDrawPrice"  >
                                    <span class="radiomark"></span>
                                </div>
                                <p class="mb-0 ms-4 ps-2 fs-6 fw-semibold">{{ option.ticketDrawPrice }} USDT</p>
                            </label>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-9 ticket-list-enable">
                <div class="row">
                    <div class="col-lg-4" *ngFor="let ticket of ticketsDet.ticketArr" >
                        <div class="card p-2 position-relative mt-4" [ngClass]="ticket.isSold ? 'sold' : 'unsold'" >
                  
                        
                            <div class="lottery-num-bg">
                                <p class="text-center">{{ticket.ticket_no}}</p>
                            </div>
                            <div
                                class="lottery-ticket d-flex align-items-center justify-content-center m-3">
                                <p class="text-sec mb-0">Price : </p>
                                <h6 class="ms-3 mb-0"><span class="me-2">USDT</span>{{ticketsDet.ticketDrawPrice}}</h6>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>
                                   
                                </div>
                                <div  class="d-flex ticket-amount-grp">
                                    <p class="mb-0 ticket-amount">{{ticketsDet.costPerTicket}} <span>USDT</span></p>
                                    <div class="">
                                        <button class="login btn btn-outline-warning btn-sm" (click)="selectTicket(ticket)"
                                        [disabled]="ticket.disabled">
                                        {{ ticket.disabled ? 'Added' : 'Add to Cart' }}
        
                                    </button>
                                    </div>
                                </div>  
                            </div>
                        
                        <div *ngIf="ticket.sold" class="sold-message position-absolute ">
                            <h1>Ticket Sold</h1>
                        </div>
                    
                </div>
            </div>
        </div>
       
    </div>
</div>

