<section class="bankdetails">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="rounded-2 border-2 shadow p-4 night-bg">
                    <ul class="ticket_ul">
                        <li class="ticket_ul_li_name fw-600"><h5>{{'Bank Details' | translate : translateControl | async}}</h5></li>
                        <li class="ticket_ul_li_btn" style="cursor: pointer;" (click)="addbankForm(addModule)"><a class="btn_theme-primary p-2">{{'Add Account Details' | translate : translateControl | async}}</a> </li>
                    </ul>

                    <div class="card-body pending_trade_card_body min-height_wallet mt-3">
                        <div class="table-responsive">
                            <table class="table sitetable table-responsive-stack" id="table1" style="white-space: nowrap;">
                                <thead>
                                    <tr>
                                        <th class="text-center fw-bold">{{'S.NO' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold ">{{'Payment Method' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Account Holder Name' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Bank Name' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Account No' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'UPI ID' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Account Type' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'IFSC Code' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Branch' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Bank Address' | translate : translateControl | async}}</th>
                                        <th class="text-center fw-bold">{{'Action' | translate : translateControl | async}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr
                                        *ngFor="let details of detail | paginate:{itemsPerPage:5,currentPage:page};let i=index; ">
                                        <td class="text-center">{{serial(i)}}</td>
                                        <td class="text-center">
                                            <span *ngIf="details?.payment_method==1">{{'Bank Account' | translate : translateControl | async}}</span>
                                            <span *ngIf="details?.payment_method==2">{{'UPI Account' | translate : translateControl | async}}</span>
                                        </td>
                                        <td class="text-center">{{details?.account_name || '-'}}</td>
                                        <td class="text-center" [title]="details?.bank_name">{{(details?.bank_name | slice:0:10) || '-'}}
                                            <span *ngIf="details?.bank_name?.length > 10 &&details?.bank_name">...</span></td>
                                        <td class="text-center">{{details?.account_no || '-'}}</td>
                                        <td class="text-center">{{details?.payment_id || '-'}}</td>
                                        <td class="text-center">{{details?.account_type || '-'}}</td>
                                        <td class="text-center">{{details?.ifsc_code || '-'}}</td>
                                        <td class="text-center" [title]="details?.branch">{{(details?.branch | slice:0:10) || '-'}}
                                            <span *ngIf="details?.branch?.length > 10 &&details?.branch">...</span>
                                        </td>
                                        <td class="text-center" [title]="details?.bank_address">{{(details?.bank_address | slice:0:10) || '-'}}
                                            <span *ngIf="details?.bank_address?.length > 10 &&details?.bank_address">...</span>
                                        </td>
                                        <td class="text-center">
                                            <a class="btn btn-success btn-sm fs-13"
                                                (click)="edit(editModule,details?._id,details.payment_method)"><i class="fa
                                                    fa-pencil-square-o"></i>{{'Edit' | translate : translateControl | async}}</a>
                                            <span data-toggle="tooltip" title="" data-original-title="Delete">
                                                <a class="ms-2 btn btn-sm btn-danger fs-13" (click)="deleteDetails(details?._id)">
                                                    <i class="fa fa-trash-o"></i>{{'Delete' | translate : translateControl | async}}
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div *ngIf="detail?.length >= 5" class="table-responsive search_result3">
                            <div class="pagination">
                                <pagination-controls (pageChange)="page= $event"></pagination-controls>
                            </div>
                        </div>
                        <p *ngIf="detail?.length == 0" colspan="7" class="text-center" style="flex-basis: 7.69231%;">
                            -- {{'No Records Found' | translate : translateControl | async}} !--</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<ng-template #addModule>
    <mat-dialog-content class="position-relative">
        <mat-icon class="close-icon end-0" [mat-dialog-close]="true" >close </mat-icon>
        <h5 class="fw-semibold">{{'Payment Method' | translate : translateControl | async}} </h5>
        <div class="payment-method-form-grp form-group row">
            <div class="col-sm-12">
                <h6 class="my-3">{{'Choose Payment Method' | translate : translateControl | async}}</h6>
                <div class="payment-tabbed align-items-start mt-2">
                    <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button class="btn btn_theme-primary active me-3 btn-theme" id="v-pills-wired-transfer-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-wired-transfer" type="button" role="tab"
                            aria-controls="v-pills-wired-transfer" aria-selected="true"> {{'Bank Account' | translate : translateControl | async}}</button>
                        <button class="btn btn_theme-primary btn-theme" id="v-pills-wireless-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-wireless" type="button" role="tab" aria-controls="v-pills-wireless"
                            aria-selected="false">{{'UPI Account' | translate : translateControl | async}}</button>
                    </div>

                    <div class="tab-content" id="v-pills-tabContent">
                        <div class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel"
                            aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                            <form class="form_payment-method" [formGroup]='userForm' (ngSubmit)="userAddBankForm(userForm)">
                                <div class="bank-check-grp">
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Name' | translate : translateControl | async}}<span class="maditory_span">*</span></label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="60" formControlName="bank_name" type="text" class="form-control mt-2 wallet_address_input" aria-label="Recipient's username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['bank_name'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['bank_name'].errors?.['required']">{{'Bank name is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit&&h['bank_name'].errors?.['minlength']">{{'Bank name should be atleast 3 character long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit&&h['bank_name'].errors?.['maxlength']">{{'Bank name must be atleast 60 character long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Select Account Type' | translate : translateControl | async}}<span class="maditory_span"> *</span></label>
                                        <div class="input-group  margin-top20">
                                            <select formControlName="account_type" type="text" class="form-select mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required>
                                                <option value="Saving">{{'Saving' | translate : translateControl | async}}</option>
                                                <option value="Current">{{'Current' | translate : translateControl | async}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['account_type'].invalid">
                                            <div *ngIf="bankSubmit&&h['account_type'].errors?.['required']" class="text-danger">{{'Account type is required' | translate : translateControl | async}}</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Account Holder Name' | translate : translateControl | async}} <span
                                                class="maditory_span">*</span></label>
                                        <div class="input-group margin-top20">
                                            <input maxlength="40" formControlName="account_name" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['account_name'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['account_name'].errors?.['required']">{{'Account holder name is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit&&h['account_name'].errors?.['minlength']">{{'Account holder name should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit&&h['account_name'].errors?.['maxlength']">{{'Account holder name must be atleast 40 characters long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'IBAN Number' | translate : translateControl | async}} <span class="maditory_span"> *</span></label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="34" formControlName="account_no" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="numberValidation($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['account_no'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['account_no'].errors?.['required']">{{'IBAN Number is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit&&h['account_no'].errors?.['minlength']">{{'IBAN Number should be atleast 3 numbers long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit&&h['account_no'].errors?.['maxlength']">{{'IBAN Number must be atleast 34 numbers long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Branch' | translate : translateControl | async}}<span class="maditory_span"> *</span></label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="100" formControlName="branch" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['branch'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['branch'].errors?.['required']">{{'Branch is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit&&h['branch'].errors?.['minlength']">{{'Branch should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit&&h['branch'].errors?.['maxlength']">{{'Branch must be atleast 100 characters long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'SWIFT Code' | translate : translateControl | async}} <span class="maditory_span">*</span></label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="11" formControlName="ifsc_code" type="text" class="form-control mt-2
                                                wallet_address_input" style="text-transform: uppercase;" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required>
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['ifsc_code'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['ifsc_code'].errors?.['required']">{{'SWIFT Code is required' | translate : translateControl | async}}</div>
                                            <!-- <div *ngIf="bankSubmit&&h['ifsc_code'].errors?.['minlength']">{{'IFSC code should be atleast 6 characters long' | translate : translateControl | async}}!</div> -->
                                            <div *ngIf="bankSubmit&&h['ifsc_code'].errors?.['maxlength']">{{'SWIFT Code must be atleast 11 characters long' | translate : translateControl | async}}!</div>
                                            <!-- <div *ngIf="bankSubmit&&h['ifsc_code'].errors?.['pattern']">{{'IFSC code must be in the format' | translate : translateControl | async}}: {{'three uppercase letters followed by digits'| translate : translateControl | async}}</div> -->
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Address' | translate : translateControl | async}}
                                            <span class="maditory_span">*</span></label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="150" formControlName="bank_address" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="addressValidation($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit&&h['bank_address'].invalid" class="text-danger">
                                            <div *ngIf="bankSubmit&&h['bank_address'].errors?.['required']">{{'Bank address is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit&&h['bank_address'].errors?.['minlength']">{{'Bank address should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit&&h['bank_address'].errors?.['maxlength']">{{'Bank address must be atleast 150 characters long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <!-- <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Set As Primary Account' | translate : translateControl | async }} ?</label>
                                        <div class="input-group margin-top20 mt-2">
                                            <input type="radio" class="me-1" formControlName="primary_account" [value]="true" id="age1" name="age">
                                            <label for="age1" class="me-3">{{'YES' | translate : translateControl | async}}</label>
                                            <input type="radio" class="me-1" formControlName="primary_account"  [value]="false" id="age2" name="age">
                                            <label for="age2">{{'NO' | translate : translateControl | async}}</label>  
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['primary_account'].invalid">
                                            <div *ngIf="bankSubmit && h['primary_account'].errors?.['required']">{{'primary account is required' | translate : translateControl | async}}</div>
                                        </mat-error>
                                        
                                    </div> -->
                                    <div >
                                        <!-- <ngx-recaptcha2 #captchaElem 
                                            [siteKey]="siteKey"  [formControl]="recaptcha" class="recaptcha_elem">
                                        </ngx-recaptcha2> -->
                                        <re-captcha [siteKey]="siteKey"  [formControl]="recaptcha" class="recaptcha_elem"></re-captcha>
                                        <div *ngIf="bankSubmit">
                                            <div *ngIf="recaptcha.errors?.['required']"> <span class="Captcha_color">{{'Captcha Field is Required' | translate : translateControl | async}}</span></div>
                                        </div>

                                    </div>
                                    <div class="col-md-12 mt-3">
                                        <button class="btn btn_theme-primary w-100" type="submit">
                                            {{'Add Bank Details' | translate : translateControl | async}}
                                        </button>
                                        <!-- <button class="button-secondary text-center" type="button" *ngIf="dataID"
                                            (click)="updateDetails(userForm.value)">
                                            Update Bank Details
                                        </button> -->
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="tab-pane fade" id="v-pills-wireless" role="tabpanel"
                            aria-labelledby="v-pills-wireless-tab" tabindex="0">
                            <form class="form_payment-method" [formGroup]="form" (ngSubmit)="onlinebankForm()">
                                <div class="bank-check-grp">
                                    <div class="mt-3 mb-3">
                                        <label for="upiId" class="wallet_label">{{"UPI ID (or) Number" | translate : translateControl | async }}<span class="maditory_span"> *</span></label>
                                        <div class="input-group
                                                margin-top20">
                                            <input appCopypaste  formControlName="payment_id" maxlength="20" minlength="4"  
                                                [ngClass]="{'is-invalid': submitted && f['payment_id'].errors}"
                                                type="text" class="form-control mt-2
                                                    wallet_address_input" aria-label="Recipient's
                                                    username" aria-describedby="basic-addon2"
                                                required appUpi>
                                            <div *ngIf=" submitted && f['payment_id'].invalid" class="invalid-feedback">
                                                <div *ngIf="f['payment_id'].errors?.['required']">{{'UPI Id Required' | translate : translateControl | async}}</div>
                                                <div *ngIf="f['payment_id'].errors?.['minlength']">{{'UPI Id should be atleast 4 characters long' | translate : translateControl | async}}!
                                                </div>
                                                <div *ngIf="f['payment_id'].errors?.['maxlength']">{{'UPI Id can be atmax 20 characters long' | translate : translateControl | async}}!
                                                </div>
                                                <div *ngIf="f['payment_id'].errors?.['pattern']">{{'Invalid UPI ID
                                                    format' | translate : translateControl | async}}!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <button class="btn btn_theme-primary  w-100"  type="submit">{{'Add UPI Details' | translate : translateControl | async }}
                                    </button>
                                    <!-- <button class="button-primary text-center" type="button" *ngIf="dataID"
                                        (click)="updateWirelessDetails(form.value)">
                                        Update Bank Details
                                    </button> -->
                                </div>
                            </form>
                            
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </mat-dialog-content>
</ng-template>
<ng-template #editModule>
    <mat-dialog-content class="position-relative">
        <mat-icon class="close-icon end-0" [mat-dialog-close]="true" >close</mat-icon>
        <h5 class="fw-semibold">{{'Payment Method' | translate : translateControl | async}}</h5>
        <div class="payment-method-form-grp form-group">
            <div class="">
                <!-- <h6 class="mt-3 gradient-text-color">Choose Payment Method</h6> -->
                <div class="payment-tabbed align-items-start mt-3">
                    <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <button *ngIf="action ==1" class="btn btn_theme-primary active me-3" id="v-pills-wired-transfer-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-wired-transfer" type="button" role="tab"
                            aria-controls="v-pills-wired-transfer" aria-selected="true"> {{'Bank Account' | translate : translateControl | async}}</button>
                        <button *ngIf="action == 2" class="btn btn_theme-primary me-3" id="v-pills-wireless-tab" data-bs-toggle="pill"
                            data-bs-target="#v-pills-wireless" type="button" role="tab" aria-controls="v-pills-wireless"
                            aria-selected="false">{{'UPI Account' | translate : translateControl | async}}</button>
                    </div>

                    <div class="tab-content" id="v-pills-tabContent">
                        <div *ngIf="action ==1" class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel"
                            aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                            <form class="form_payment-method" [formGroup]='userForm' (ngSubmit)="userAddBankForm(userForm)">
                                <div class="bank-check-grp">

                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Name' | translate : translateControl | async}} </label>
                                        <div class="input-group
                                            margin-top20 mt">
                                            <input maxlength="60" formControlName="bank_name" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['bank_name'].invalid">
                                            <div *ngIf="bankSubmit && h['bank_name'].errors?.['required']">{{'Bank name is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit && h['bank_name'].errors?.['minlength']">{{'Bank name should be atleast 3 character long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit && h['bank_name'].errors?.['maxlength']">{{'Bank name must be atleast 60 character long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Account Type' | translate : translateControl | async}}</label>
                                        <div class="input-group
                                            margin-top20">
                                            <select formControlName="account_type" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required>
                                                <option value="Saving">{{'Saving' | translate : translateControl | async}}</option>
                                                <option value="Current">{{'Current' | translate : translateControl | async}}</option>
                                            </select>
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['account_type'].invalid">
                                            <div *ngIf="bankSubmit && h['account_type'].errors?.['required']">{{'Account type is required' | translate : translateControl | async}}</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Account Name' | translate : translateControl | async}} </label>
                                        <div class="input-group margin-top20">
                                            <input maxlength="40" formControlName="account_name" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['account_name'].invalid">
                                            <div *ngIf="bankSubmit && h['account_name'].errors?.['required']">{{'Account name is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit && h['account_name'].errors?.['minlength']">{{'Account name should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit && h['account_name'].errors?.['maxlength']">{{'Account holder name must be atleast 40 characters long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'IBAN Number' | translate : translateControl | async}}</label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="34" formControlName="account_no" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="numberValidation($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['account_no'].invalid">
                                            <div *ngIf="bankSubmit && h['account_no'].errors?.['required']">{{"IBAN Number is required" | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit && h['account_no'].errors?.['minlength']">{{'IBAN Number should be atleast 3 numbers long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit && h['account_no'].errors?.['maxlength']">{{'IBAN Number must be atleast 34 numbers long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Branch' | translate : translateControl | async}}</label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="100" formControlName="branch" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="alphabets($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['branch'].invalid">
                                            <div *ngIf="bankSubmit && h['branch'].errors?.['required']">{{'Branch is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit && h['branch'].errors?.['minlength']">{{'Branch should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit && h['branch'].errors?.['maxlength']">{{'Branch must be atleast 100 characters long' | translate : translateControl | async}}!</div>
                                            
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'SWIFT Code' | translate : translateControl | async}} </label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="11" formControlName="ifsc_code" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" style="text-transform: uppercase;" required>
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['ifsc_code'].invalid">
                                            <div *ngIf="bankSubmit && h['ifsc_code'].errors?.['required']">{{'SWIFT Code is required' | translate : translateControl | async}}</div>
                                            <!-- <div *ngIf="bankSubmit && h['ifsc_code'].errors?.['minlength']">IFSC {{'code should be atleast 6 characters long' | translate : translateControl | async}}!</div> -->
                                            <div *ngIf="bankSubmit && h['ifsc_code'].errors?.['maxlength']">{{'SWIFT Code must be atleast 11 characters long' | translate : translateControl | async}}!</div>
                                            <!-- <div *ngIf="bankSubmit&&h['ifsc_code'].errors?.['pattern']">{{'IFSC code must be in the format' | translate : translateControl | async}}: {{'three uppercase letters followed by digits'| translate : translateControl | async}}</div> -->
                                        </mat-error>
                                    </div>
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'Bank Address' | translate : translateControl | async}}</label>
                                        <div class="input-group
                                            margin-top20">
                                            <input maxlength="150" formControlName="bank_address" type="text" class="form-control mt-2
                                                wallet_address_input" aria-label="Recipient's
                                                username" aria-describedby="basic-addon2" required (keypress)="addressValidation($event)">
                                        </div>
                                        <mat-error *ngIf="bankSubmit && h['bank_address'].invalid">
                                            <div *ngIf="bankSubmit && h['bank_address'].errors?.['required']">{{'Bank address is required' | translate : translateControl | async}}</div>
                                            <div *ngIf="bankSubmit && h['bank_address'].errors?.['minlength']">{{'Bank address should be atleast 3 characters long' | translate : translateControl | async}}!</div>
                                            <div *ngIf="bankSubmit && h['bank_address'].errors?.['maxlength']">{{'Bank address must be atleast 150 characters long' | translate : translateControl | async}}!</div>
                                        </mat-error>
                                    </div>
                                    <div class="col-md-12 text-center mt-4">
                                        <button class="btn btn_theme-primary  w-100" type="button" *ngIf="dataID"
                                            (click)="updateDetails(userForm.value)">
                                            {{'Update Bank Details' | translate : translateControl | async}}
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div *ngIf="action ==2" class="tab-pane fade show active" id="v-pills-wireless" role="tabpanel"
                            aria-labelledby="v-pills-wireless-tab" tabindex="0">
                            <form class="form_payment-method" [formGroup]="form" (ngSubmit)="onlinebankForm()">
                                <div class="bank-check-grp">
                                    <div class="mt-3 mb-3">
                                        <label class="wallet_label">{{'UPI ID (or) Number' | translate : translateControl | async}}</label>
                                        <div class="input-group margin-top20">
                                            <input appUpi formControlName="payment_id" minlength="4" maxlength="20" 
                                                [ngClass]="{'is-invalid': submitted && f['payment_id'].errors}"
                                                type="text" class="form-control mt-2
                                                    wallet_address_input" aria-label="Recipient's
                                                    username" aria-describedby="basic-addon2"
                                                   required>
                                            <div *ngIf=" submitted && f['payment_id'].invalid" class="invalid-feedback">
                                                <div *ngIf="f['payment_id'].errors?.['required']">{{'UPI Id Required' | translate : translateControl | async}}</div>
                                                <div *ngIf="f['payment_id'].errors?.['minlength']">{{'UPI Id should be atleast 4 characters long' | translate : translateControl | async}}!
                                                </div>
                                                <div *ngIf="f['payment_id'].errors?.['maxlength']">{{'UPI Id can be atmax 20 characters long' | translate : translateControl | async}}!
                                                </div>
                                                <div *ngIf="f['payment_id'].errors?.['pattern']">{{'Invalid UPI ID
                                                    format' | translate : translateControl | async}}!
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                            <div class="col-md-12 text-center mt-4">
                                <button class="btn btn_theme-primary w-100" type="button" *ngIf="dataID"
                                    (click)="updateWirelessDetails(form.value)">
                                    {{'Update UPI Details' | translate : translateControl | async}}
                                </button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </mat-dialog-content>
</ng-template>