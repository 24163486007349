import { Component } from '@angular/core';
import { TicketManagementService } from '../../services/ticket-management.service';
import { UserService } from '../../services/user.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-my-cart',
  templateUrl: './my-cart.component.html',
  styleUrl: './my-cart.component.scss',
  providers: [DatePipe]

})
export class MyCartComponent {
  selectedTicket:any;
  tickets: any[] = [];
  totalTicketsCost:any = 0;
  isCardDisabled = false; 
  currentDate: string;
  datePipe: any;

  constructor(
    private ticketService:TicketManagementService,
    public callApi:UserService,
    private datepipe : DatePipe
    ){
      this.currentDate = this.datepipe.transform(new Date(), 'shortDate') || '';
    }
  
  ngOnInit(): void {
    this.getCartDetails()
  }  

  getCartDetails(){
    this.callApi.getPurchasedDet({}).subscribe((res:any) => {
      if(res.success){
        this.tickets = res.data.ticketDetails
        this.calculatingCost()
      }
    })
  }

  calculatingCost(){
    this.totalTicketsCost = 0
    this.tickets.map((each) =>  this.totalTicketsCost +=each.costPerTicket)    
  }

  removeTicket(id:any){
    let payload={
      id:id
    }
    this.callApi.getPurchasedDet(payload).subscribe((res:any) => {
      if(res.success){
        this.tickets = res.data.ticketDetails
        this.calculatingCost()
      }
      
    })
    
  }

  buyNow(){
    const payload = {
      tickets:this.tickets,
      totalCost:this.totalTicketsCost,
      slug:"USDT"
    }
   
    this.callApi.updateTicketDet(payload).subscribe((res:any)=>{
      if(res.success){
        this.callApi.showSuccess(res.message)
        this.tickets=[]
        this.totalTicketsCost = 0
      }
      else{
        this.callApi.showError(res.message)
      }
    })
    
  }

}
