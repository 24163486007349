<section class="section_fiat-body">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="col-lg-11 div_deposit-details rounded-4 my-4 my-lg-0">
                    <div class="div_deposit-details_title d-block d-xl-flex justify-content-between align-items-center mb-4">
                        <h5 class="fw-semibold mb-0">{{'Fiat Withdraw' | translate:translateControl | async}}</h5>
                        <button class="btn_theme-grey fw-medium mt-3 mt-xl-0" routerLink="/fiat-deposit">{{'Fiat Deposit' | translate:translateControl | async}}<i class="fas fa-arrow-right"></i></button>
                    </div>
                    <div class="div_deposit-details_amount mb-4">
                        <div class="row">
                            <div class="col-sm-8">
                                <!-- <label class="d-block small fw-medium mb-2">{{'Amount' | translate:translateControl | async}}</label> -->
                                <input type="text" class="form-control" [placeholder]="amount_field" [(ngModel)]="fiatAmount" (keypress)="amountKeypress($event)" appCopypaste/>
                            </div>
                            <div class="col-sm-4">
                                <!-- <select name="fiat_type" class="form-control h-100" [(ngModel)]="fiatSlug" (change)="selectFiat($event)">
                                    <option [value]="data?._id" *ngFor="let data of fiatData">{{data.slug}}</option>
                                </select> -->
                                <mat-form-field class="h-100" *ngIf="!showCoin">
                                    <mat-select class="" (selectionChange)="selectFiat($event)" [placeholder]="selected">
                                        <mat-select-trigger class="d-flex align-items-center">
                                            <img src="{{backendend+data?.image}}" width="20" height="20" alt="Selected Option Image">
                                            <span class="ms-2">{{data.slug}}</span>
                                        </mat-select-trigger>                                        
                                        <mat-option *ngFor="let data of Fiatdata" [value]="data?._id">
                                            <img src="{{backendend + data?.image}}" width="20" height="20"><span
                                                class="ms-2">{{data.slug}}</span>
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="showCoin" class="d-flex align-items-center h-100 bg-white justify-content-center">
                                    <img src="{{this.data?.image}}" width="20" height="20" alt="Selected Option Image">
                                    <span class="ms-2">{{this.data.slug}}</span>
                                </div> 
                            </div>
                        </div>
                    </div>
                    <div class="div_deposit-details_payment mb-3">
                        <h6 class="fs-6 fw-semibold">{{'Payment Method' | translate:translateControl | async}}</h6>
                        <ul class="list-unstyled mb-0">
                            <li class="active mb-2"  [ngClass]="title == 'payment' ? 'active' : ''">
                                <div class="d-flex align-items-center" (click)="selectType('payment',1)">
                                    <img src="assets/img/coins/payment-metnod.png" class="img-fluid" width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="fs-16">{{'Payment Method' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="active mb-2 not-visible">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/coins/payment-metnod.png" class="img-fluid" width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="fs-16">{{'Payment Method' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <li class="active mb-2 not-visible">
                                <div class="d-flex align-items-center">
                                    <img src="assets/img/coins/payment-metnod.png" class="img-fluid" width="48" alt="Card Image" />
                                    <div class="ms-2">
                                        <h6 class="fs-16">{{'Payment Method' | translate:translateControl | async}}</h6>
                                        <p class="mb-0">{{'Real Time' | translate:translateControl | async}}</p>
                                    </div>
                                </div>
                            </li>
                            <div class="mt-3">
                                <button class="btn_theme-primary fw-medium w-100" (click)="submitWithdraw()">{{'Continue' | translate:translateControl | async}}</button>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 mt-4 mt-lg-0">
                <h5 class="fw-semibold mb-4">{{'Withdraw' | translate:translateControl | async}} {{data?.slug}}</h5>
                <h6 class="fs-16"><i class="fas fa-pencil-alt text_theme-primary me-1"></i> {{'Withdraw Instructions' | translate:translateControl | async}}</h6>
                <!-- <h6 class="fs-13 ">{{'Tips' | translate:translateControl | async}}</h6>
                <ol>
                    <li class="text-secondary">{{'Note: Please ensure your account details match the information provided during Identity Verification. Inconsistent account information may result in unsuccessful deposits, with funds returned within 7–14 working days for such cases.' | translate:translateControl | async}}</li>
                    <li class="text-secondary">{{'Fiat Deposit and Fiat Balance currently do not support P2P Trading.' | translate:translateControl | async}}</li>
                </ol>
                <h6 class="fs-13">ETA</h6>
                <p class="text-secondary">{{'Please note that during national holidays or under special circumstances, the crediting of your fiat deposit to your account may be subject to a delay of up to four (4) working days.' | translate:translateControl | async}}
                </p> -->
                <ol class="text-secondary">
                    <li class="mb-2">{{'Supported Currencies: AUD , USD , EUR fiat currencies that are accepted for withdrawals on the platform.' | translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Withdraw Methods: Information on the different methods available for withdraw fiat currency, such as bank transfers, credit/debit card payments, and other payment options.' | translate:translateControl | async}}
                    </li>
                    <li class="mb-2">{{'Withdraw Limits: Details on the minimum and maximum withdraw amounts for fiat currency withdrawals.' | translate:translateControl | async}}
                        <div class="fw-bold" *ngIf="isSelectionMade">
                            <span class="d-block" >{{'Min.Limit'| translate:translateControl | async}} - {{data?.min_withdraw_limit}} {{data?.slug |uppercase }}</span>
                            <span>{{'Max.Limit'| translate:translateControl | async}} - {{data?.max_withdraw_limit}} {{data?.slug |
                                uppercase| translate : translateControl | async}}</span>
                        </div>
                    </li>
                    <li class="mb-2">{{'Withdraw Fees: Information on withdraw fees that may be charged for withdrawal fiat currency into the account.' | translate:translateControl | async}}
                        <div class="fw-bold" *ngIf="isSelectionMade">
                            {{'Withdraw Fees' | translate:translateControl | async}} - {{data?.withdraw_fee|tofixed}}%
                            {{data?.slug | uppercase| translate : translateControl | async}} 
                        </div>
                    </li>
                    <li class="mb-2">{{'Security Measures: Information on the security measures in place to protect users fiat withdrawals and personal information & Google 2FA will protect user transaction securely & perfectly.'|translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Customer Support: Contact information for customer support in case users encounter any issues or have questions about fiat withdrawals.' | translate:translateControl | async}}</li>
                    <li class="mb-2">{{'Disclaimer: A disclaimer outlining the risks associated with fiat withdrawals and advising users to conduct their own research before making a withdraw.'| translate:translateControl | async}}</li>
                </ol>
            </div>
        </div>
    </div>
</section>


<ng-template #withDraw>
    <mat-dialog-content class="deposit-payment-popup">
        <mat-icon class="close-icon float-end" [mat-dialog-close]="true" >close</mat-icon>
        <h2 class="fs-5"> {{'Payment Method'| translate : translateControl | async}}</h2>
        <form (ngSubmit)="withdrawRequest()" autocomplete="off">
            <div class="form-group">
                <div class="">
                    <h6 class="mt-3">{{'Choose Payment Method'| translate : translateControl | async}}</h6>
                    <div class="payment-tabbed mt-2">
                        <div class="nav nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <button class="btn_theme-primary me-2 active" id="v-pills-wired-transfer-tab" data-bs-toggle="pill" data-bs-target="#v-pills-wired-transfer" type="button" role="tab" aria-controls="v-pills-wired-transfer" aria-selected="true" (click)="setPaymentType(1)">{{'Wired Transfer'| translate : translateControl | async}}</button>
                            <button class="btn_theme-primary" id="v-pills-profile-tab" data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button" role="tab" aria-controls="v-pills-profile" aria-selected="false" name="withDrawOnlineTranser" value="2" (click)="setPaymentType(2)">{{'UPI Pay'| translate : translateControl | async}}</button>
                        </div>
                        <div class="mt-4" *ngIf="(transaction_method==1 && userBanks?.bank_count<1)||(transaction_method==2 && userBanks?.upi_count<1) || (userBanks?.bank_count<1 && userBanks?.upi_count<1)">
                            <div class="row align-items-center">
                                <div class="col-lg-12 text-center mt-4 mt-lg-0">
                                    <h6 class="fs-16 mb-3">{{'Please Add Bank Account Details'| translate : translateControl | async}}</h6>
                                    <button class="btn_theme-primary" (click)="addbank()">{{'Click Here'| translate : translateControl | async}}</button>
                                </div>
                               
                            </div>
                        </div>
                        <div class="tab-content" id="v-pills-tabContent">
                            <div class="tab-pane fade show active" id="v-pills-wired-transfer" role="tabpanel" aria-labelledby="v-pills-wired-transfer-tab" tabindex="0">
                                <div class="mt-4 bank-check-grp text-start" *ngIf="userBanks?.bank_count>0">
                                    <div class="">
                                        <div class="bank-check-inner from-details">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Withdraw Account:'| translate : translateControl | async}}</label><br>
                                                <select class="form-control wallet-select cursor-pointer mb-3" (change)="changeBank($event, 'user', 'bank')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank?.payment_method==1">
                                                        {{bank?.account_no }} - {{ bank?.bank_name | translate : translateControl | async }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="bank-detail-wallet" *ngIf="userBankInfo?.account_no">
                                                <ul class="list-unstyled mb-0">
                                                    <li class="pb-1 mb-2"><span class="text-dark">{{'Account Number :' | translate : translateControl | async}}</span><span>{{ userBankInfo?.account_no }}</span></li>
                                                    <li class="pb-1 mb-2"><span class="text-dark">{{'Account Name :'| translate : translateControl | async}}</span><span>{{ userBankInfo?.account_name }}</span></li>
                                                    <li class="pb-1 mb-2"><span class="text-dark">{{'Bank Name :'| translate : translateControl | async}}</span><span>{{ userBankInfo?.bank_name | translate : translateControl | async }}</span></li>
                                                    <li class="pb-1 mb-2"><span class="text-dark">{{'Account Type :'| translate : translateControl | async}}</span><span>{{ userBankInfo?.account_type }}</span></li>
                                                    <li class="pb-1 mb-2"><span class="text-dark">{{'IFSC Code :'| translate : translateControl | async}}</span><span>{{ userBankInfo?.ifsc_code }}</span></li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="v-pills-profile" role="tabpanel" aria-labelledby="v-pills-profile-tab" tabindex="0">
                                <div class="bank-check-grp" *ngIf="userBanks?.upi_count>0">
                                    <div class="">
                                        <div class="from-details mt-3 bank-check-inner">
                                            <div>
                                                <label class="label-text fw-bold mb-2">{{'Withdraw UPI:'| translate : translateControl | async}}</label><br>
                                                <select class="wallet-select form-control w-100 cursor-pointer mb-3" (change)="changeBank($event, 'user', 'upi')">
                                                    <ng-container *ngFor="let bank of userBanks; let i = index">
                                                        <option [value]="i" *ngIf="bank?.payment_method==2">{{ bank?.payment_id }}</option>
                                                    </ng-container>
                                                </select>
                                            </div>
                                            <div class="bank-detail-wallet" *ngIf="userUpiInfo?.payment_id">
                                                <ul class="list-unstyled mb-0">
                                                    <li><span class="text-dark">{{'UPI ID'| translate : translateControl | async}}</span>: <span>{{ userUpiInfo?.payment_id }}</span>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- choose payment -->
                </div>
                <div class="text-start mt-3 rounded-3" *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                    <label class="col-form-label d-block">{{'Enter Amount:'| translate : translateControl | async}}</label>
                    <mat-form-field  class="example-full-width">
                        <input matInput  name="amounts" min="0" [(ngModel)]="amount" type="number" [placeholder]="amount_field"
                        #upiamount="ngModel" required [ngClass]="{ 'is-invalid': upiSubmit && upiamount.errors }">
                    </mat-form-field>
                    <div *ngIf="upiSubmit" class="text-danger">
                        <div *ngIf="upiamount.errors?.['required']">{{'Transaction Amount is Required'| translate : translateControl | async}}</div>
                    </div>
                </div>
            </div>
            <div class="" *ngIf="(transaction_method==1 && userBanks?.bank_count>0)||(transaction_method==2 && userBanks?.upi_count>0)">
                <button type="submit" class="button-secondary mt-3 p-2">{{'Submit'| translate : translateControl | async}}</button>
            </div>
        </form>
    </mat-dialog-content>
</ng-template>
