import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.development';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Observable, firstValueFrom } from 'rxjs';
import * as CryptoJS from "crypto-js";
import { NgxSpinnerService } from 'ngx-spinner';
// import { NgxSpinnerService } from '../ngx-spinner.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  publishEvent: any;
  public url = environment.base_url
  public token: any = this.getToken();
  public headers = new HttpHeaders().set('_token', (this.token || null))


  twofaLostingRequest(payload: any) {
    return this.http.post(this.url + 'user/twofaLostingRequest', payload, { headers: this.headers })
  }
  twofaLostImage(payload: any) {
    return this.http.post(this.url + 'user/twofaLostImage', payload, { headers: this.headers })
  }

  tfaStatus() {
    return this.http.get(`${this.url}user/userTwofaDetails`, { headers: this.headers })
  }
  startSpinner() {
    this.spinner.show();
  }
  stopSpinner() {
    this.spinner.hide();
  }
  getToken() {
    this.token = localStorage.getItem('access_token');
    this.headers = new HttpHeaders().set('_token', (this.token || false))
    return this.token
  }
  public decimal: any;
  decimalValue(value: any) {
    this.decimal = value
  }
  returnDecimal() {
    return this.decimal
  }
  private subject = new BehaviorSubject<any>('');

  constructor(private http: HttpClient,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
  ) {
  }
  showSuccess(message: any) {
    this.toastr.success(message)
  }
  showError(message: any) {
    this.toastr.error(message)
  }
  subscribeEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  // startSpinner() {
  //   this.spinner.show();
  // }
  // stopSpinner() {
  //   this.spinner.hide();
  // }

  userReg(payload: any) {
    return this.http.post(this.url + 'user/register', payload)
  }
  userLogin(payload: any) {
    return this.http.post(this.url + 'user/login', payload)
  }
  uploadProfileImage(image: any) {
    return this.http.post(this.url + 'user/uploadKycImage', image, { headers: this.headers })
  }
  getProfile() {
    return this.http.get(this.url + 'user/profile', { headers: this.headers })
  }
  kycSubmit(payload: any) {
    return this.http.post(this.url + 'user/kyc/create', payload, { headers: this.headers })
  }

  logOut() {
    return this.http.get(this.url + "user/logout", { headers: this.headers })
  }

  getTicketDraw(payload: any) {
    return this.http.post(this.url + 'user/getTicketDraw', payload, { headers: this.headers })
  }

  getSpecificTickets(payload: any) {
    return this.http.post(this.url + 'user/getSpecificTicket', payload, { headers: this.headers })
  }

  updateTicketDet(payload: any) {
    return this.http.post(this.url + 'user/updateTicketDet', payload, { headers: this.headers })
  }

  getWallet(payload: any) {
    return this.http.post(this.url + 'user/wallet', payload, { headers: this.headers })
  }

  createWallet(payload: any) {
    return this.http.post(this.url + 'user/createWalletAddress', payload, { headers: this.headers })
  }
  getAllCountries() {
    return this.http.get(this.url + 'user/countries', { headers: this.headers })
  }

  public async getKycAccessToken() {
    const data = await firstValueFrom(this.http.get<{ accessToken: string; }>(`${this.url}user/getKycAccessToken`, { headers: this.headers }));
    return data.accessToken;
  }
  checkTfa(payload: any) {
    return this.http.put(this.url + 'user/verifyGoogleAuthentication', payload, { headers: this.headers })
  }

  serverError() {
    this.toastr.error('Something Went Wrong Please Try again')
  }
  getTfa() {
    return this.http.get(this.url + 'user/requestGoogleAuthentication', { headers: this.headers })
  }
  getKycParams() {
    return this.http.get(this.url + 'user/getKycParams', { headers: this.headers })
  }

  uploadKycImage(payload: any) {
    return this.http.post(this.url + 'user/uploadKycImage', payload, { headers: this.headers })
  }

  getProfileAvatar() {
    return "../assets/img/coinlivret/avatar.png"
  }

  submitKyc(payload: any) {
    return this.http.post(this.url + 'user/kyc/create', payload, { headers: this.headers })
  }
  verifyOtp(payload: any) {
    return this.http.post(this.url + 'user/accountverify', payload)
  }
  resendmailotp(payload: any) {
    return this.http.post(this.url + 'user/resendmailotp', payload)
  }
  emailAuthentication() {
    return this.http.get(this.url + 'user/requestmailauthentication', { headers: this.headers })
  }
  emailAuthenticationOtp() {
    return this.http.get(this.url + `user/requestmailauthentication?status=4`, { headers: this.headers })
  }
  otpEmailVerification(payload: any) {
    return this.http.post(this.url + 'user/verifytwofaotp', payload, { headers: this.headers })
  }
  // tfaresendmailotp(payload: any) {
  //   return this.http.get(this.url + 'user/requestmailauthentication', { headers: this.headers })
  // }

disablemailauthentication(payload: any) {
  return this.http.post(this.url + 'user/verifytwofaotp', payload, { headers: this.headers })
}
getUserDet(){
  return this.http.get(this.url+'user/getUsersDet',{headers:this.headers})
}
getTickectDate(payload:any){
  return this.http.post(this.url+'user/getTicketDrawDate',payload,{headers:this.headers})
}



  encryptData(data: any) {
    const encrypted = CryptoJS.AES.encrypt(data, environment.encrypt_decrypt_key).toString()
    return encrypted
  }

  decryptData(data: any) {
    const decrypted = CryptoJS.AES.decrypt(data, environment.encrypt_decrypt_key).toString(CryptoJS.enc.Utf8)
    return decrypted
  }
  showInfo(message: any) {
    this.toastr.info(message);
  }
  login(payload: any) {
    return this.http.post(this.url + 'user/login', payload)
  }

getMyLotteryDet(payload:any){ 
 return this.http.post(this.url+'user/getCartDetails',payload,{headers:this.headers})
}

getUserResult(payload:any,page:any){
  return this.http.post(this.url+'user/getLotteryResults?page='+page,payload,{headers:this.headers})
}
choosingTicket(payload:any){
  return this.http.post(this.url+'user/updateTicketStatus',payload,{headers:this.headers})
}

  getWalletCoins(payload: any, page: any) {
    return this.http.post(this.url + 'user/walletCoins?page=' + page, payload, { headers: this.headers })
  }
  userFiatBalances() {
    return this.http.get(this.url + 'user/userFiatBalance', { headers: this.headers })
  }
  getOpenOrders(payload: any, page: any) {
    return this.http.post(this.url + 'ob/getOpenOrders?page=' + page, payload, { headers: this.headers })
  }

  withdrawReq(payload: any) {
    return this.http.post(this.url + 'user/withdrawRequest', payload, { headers: this.headers })
  }


  getWalletDetailsWithId(payload: any) {
    return this.http.post(this.url + 'user/getWalletDetailsWithId', payload, { headers: this.headers })
  }
  createAddress(payload: any) {
    return this.http.post(this.url + 'user/createWalletAddress', payload, { headers: this.headers })
  }

  walletCoinPnlCalculation(payload: any) {
    return this.http.post(this.url + 'user/singleCoinPNL', payload, { headers: this.headers })
  }
  walletCoinTotalPnlCalculation() {
    return this.http.get(this.url + 'user/allCoinPnl', { headers: this.headers })
  }

  getWallet2(payload: any, page: any) {
    return this.http.post(this.url + 'user/wallet?page=' + page, payload, { headers: this.headers })
  }

  getCoinDetails() {
    return this.http.get(this.url + 'user/allActiveTradePair', { headers: this.headers })
  }
  getCoinAndPairDetails() {
    return this.http.get(this.url + 'user/activeCoinsAndPairs')
  }
  paypalTransaction(payload: any) {
    return this.http.post(this.url + 'user/paypalTransaction', payload, { headers: this.headers })
  }
  createStripe(payload: any) {
    return this.http.post(this.url + 'user/stripePayment', payload, { headers: this.headers })
  }
  userBankDetails() {
    return this.http.get(this.url + 'user/getUserWithUserId', { headers: this.headers })
  }

  tokenAddressList(token_id: any, datas: any) {
    return this.http.post(this.url + `userWithdrawAddressBook/listWithdrawAddressBook?coin_id=${token_id}`, datas, { headers: this.headers })
  }

  adminBankDetails(payload: any) {
    return this.http.post(this.url + 'user/getbankdetails', payload, { headers: this.headers })
  }
  depositApproveRequest(payload: any) {
    return this.http.post(this.url + 'user/fiatDepositRequest', payload, { headers: this.headers })
  }
  fiatDepositImaget(payload: any) {
    return this.http.post(this.url + 'user/fiatDepositImage', payload, { headers: this.headers })
  }

  getBankDetails(payload: any) {
    return this.http.post(this.url + 'user/getUserBankDetails', payload, { headers: this.headers })
  }

  fiatWithdrawRequest(payload: any) {
    return this.http.post(this.url + 'user/fiatWithdrawRequest', payload, { headers: this.headers })
  }
  // add bank datails
  addBankdDetails(payload: any) {
    return this.http.post(this.url + 'user/addbankdetails', payload, { headers: this.headers })
  }
  searchBankDetails(payload: any) {
    return this.http.post(this.url + 'user/searchBankDetails', payload, { headers: this.headers })
  }
  withdrawAddressSearch(payload: any) {
    return this.http.post(this.url + 'userWithdrawAddressBook/SearchWithdrawAddressBook', payload, { headers: this.headers })
  }
  walletCoinHistory(payload: any) {
    return this.http.post(`${this.url}ob/usercoindata`, payload, { headers: this.headers })
  }

  getUserPreference() {
    return this.http.get(this.url + 'user/getUserSettings', { headers: this.headers })
  }
  getTradeHistoryList() {
    return this.http.get(this.url + 'user/getHistoryParams', { headers: this.headers })
  }
  deleteBankDetails(id: any) {
    return this.http.put(this.url + 'user/getDeleteById', id, { headers: this.headers })
  }
  updateBankDetails(payload: any) {
    return this.http.put(this.url + 'user/getUpdateById', payload, { headers: this.headers })
  }
  tfaresendmailotp(payload: any) {
    return this.http.get(this.url + 'user/requestmailauthentication', { headers: this.headers })
  }
  

  getPurchasedDet(payload:any){
    return this.http.post(this.url+'user/getPurchasedData',payload,{headers:this.headers})
  }

}
