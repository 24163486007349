import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators ,AbstractControl, ValidationErrors} from '@angular/forms';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';


function emailDomainValidator(control: AbstractControl): ValidationErrors | null {
  const email = control.value?.trim(); // Trim any whitespace
  if (email && !email.endsWith('.com')) {
    return { 'emailDomain': 'Email must end with .com' };
  }
  return null;
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit{
 
regForm:any;
isSubmitted:boolean = false;
showPassword:boolean=false;
showConfirmPassword:boolean=false;

constructor(private fb:FormBuilder,private callApi:UserService,private route:Router){}

ngOnInit(){
  this.createForm()
}

createForm(){
  let emailregex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  this.regForm = this.fb.group({
    user_name:['',[Validators.required]],
    // phone_no:['',[Validators.required,Validators.minLength(10)]],
    email:['',[Validators.required,Validators.pattern(emailregex)]],
    password:['',[Validators.required,Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&]{8,}$')]],
    confirmPassword:['',[Validators.required]]
  },
  {
    validator : this.MustMatch('password','confirmPassword')
 })
}


// onEnterNumber(event:any){
  
//  let regExp = /^[0-9]*$/
// if(!regExp.test(event.key)){
//   event.preventDefault()
// }
// }

MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
      const control = formGroup.controls[controlName];
      const matchingControl = formGroup.controls[matchingControlName];

      if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
          // return if another validator has already found an error on the matchingControl
          return;
      }

      // set error on matchingControl if validation fails
      if (control.value !== matchingControl.value) {
          matchingControl.setErrors({ mustMatch: true });
      } else {
          matchingControl.setErrors(null);
      }
  }
}
togglePassword():void {
  this.showPassword = !this.showPassword;
}
toggleConfirmPassword(): void {
  this.showConfirmPassword = !this.showConfirmPassword;
}
onSubmit(){
  this.isSubmitted = true
  if(this.regForm.valid){
    let payload = this.regForm.value
    this.callApi.userReg(payload).subscribe((res:any) => {
      if(res.success){
        this.callApi.showSuccess(res.message)
        this.regForm.reset()
        this.isSubmitted = false
        // this.route.navigate(['/login'])
        const encrypted_mail = this.callApi.encryptData(res?.data?.email)
        this.route.navigate(['/email-otp-verification'],{queryParams:{mail:encrypted_mail}})
      }else{
       this.callApi.showError(res.message)
      }
    })
  }
 
}


}
