import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../user.service';

@Pipe({
  name: 'tofixed'
})
export class TofixedPipe implements PipeTransform {

  decimal: any;
  constructor(private callApi:UserService){}

  transform(value: unknown, decimalPlaces: number = 0): unknown {
    this.decimal = this.callApi.returnDecimal()
    if(this.decimal != null || this.decimal != undefined)
      localStorage.setItem('DecimalValue',this.decimal,)
    const exponentialNum = String(value)
    let floatNum:any = Number(exponentialNum);
    floatNum = floatNum.toFixed(Number(decimalPlaces ? decimalPlaces : (this.decimal == null || undefined ? localStorage.getItem('DecimalValue'):this.decimal)));
    return floatNum;
  }

}
