import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';
import snsWebSdk from '@sumsub/websdk';
import { UserService } from '../services/user.service';
import { ImageValidationService } from '../services/image-validation.service';
import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-kyc-sub',
  templateUrl: './kyc-sub.component.html',
  styleUrls:['./kyc-sub.component.scss']
})
  export class KycSubComponent implements OnInit {
    base_url: any = environment.IMAGE_URL;
    isEditable: any = true;
    acInfoForm: FormGroup | any;
    idCredentialForm: FormGroup | any;
    uploadDocForm: FormGroup | any;
    country: any;
    idTypeList: any;
    image: any = {}
    dialCodeData: any;
    id_type: boolean = false;
    minDate: any = new Date();
    maxDate: any = this.minDate.getY;
    data: any;
    submitted: boolean = false
    countryName: any;
    kyc_verify: any;
    button: string = 'Submit'
    isLoading: boolean = false;
    today: any;
    selfieImg: any;
    siteKey: string = "6LeV0ZUpAAAAAJ88UInQ8MI-oF1xEfc_9voiNFCu";
    showSumbContainer: boolean = false;
    userEmail: any;
  countriesData: any;
    constructor(private callApi: UserService,
      private datePipe: DatePipe,
      private router: Router,
      private fb: FormBuilder, private validimage: ImageValidationService) {
    }
  
    async ngOnInit() {
      this.today = new Date();
      this.maxDate = new Date(this.today.getTime() - (157680.24 * 60 * 60 * 1000)).toISOString().split('T')[0];
      this.minDate = new Date(this.today.getFullYear() - (157680.24 * 145 * 60 * 1000)).toISOString().split('T')[0]
      this.acInfoForm = this.fb.group({
        first_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
        last_name: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(30)]],
        date: [null, [Validators.required]],
        city: [null, [Validators.required, Validators.pattern('[a-zA-Z ]*'), Validators.minLength(3), Validators.maxLength(60)]],
        country: [null, [Validators.required]],
        phoneNumber: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(15)]],
      })
  
      this.idCredentialForm = this.fb.group({
        id_type: [null, [Validators.required]],
        id_number: [null, [Validators.required, Validators.minLength(5), Validators.maxLength(20)]],
        address: [null, [Validators.required, Validators.minLength(3), Validators.maxLength(150), Validators.pattern(/^[a-zA-Z0-9 ]*$/)]],
        expiry_date: [null],
      })
  
      this.uploadDocForm = this.fb.group({
        id_front_image: [null, [Validators.required]],
        id_back_image: [null, [Validators.required]],
        selfie_holding: [null]
      })
  
      this.callApi.getAllCountries().subscribe((res: any) => {
        this.country = res?.data;
        
      })
      this.getKycParams()
      this.getProfileDetails()
    }
    async getProfileDetails() {
      this.callApi.getProfile().subscribe(async (res: any) => {
        this.kyc_verify = res?.data.userDetails[0].kyc_verify
        this.userEmail = res?.data?.userDetails[0].email
        this.data = res?.data?.kycDetails
        this.countriesData = res?.data?.countriesData
        // console.log("🚀 ~ KycuserComponent ~ this.callApi.getProfile ~  this.countriesData:",  this.countriesData)
        if (this.kyc_verify != 3) {
          this.dialCodeData = this.data?.country_code
          this.acInfoForm.patchValue(this.data)
          this.idCredentialForm.patchValue(this.data)
          this.acInfoForm.patchValue({ date: this.datePipe.transform(this.data?.dob, ('yyyy-MM-dd')) })
          this.kyc_verify == 0 ? null : this.image.front = this.data?.id_front_image;
          this.kyc_verify == 0 ? null : this.image.back = this.data?.id_back_image;
          this.selfieImg = this.data?.selfie_holding
          // this.showSumbContainer = true;
          // const accessToken = await this.callApi.getKycAccessToken();
          // console.log(accessToken,`+9123423423423`, this.userEmail);
          // this.launchWebSdk(accessToken,`+9123423423423`, this.userEmail)
          let event: any = { target: { value: this.data?.country } }
          this.dialCode(event);
          let idevent: any = { target: { value: this.data?.id_type } }
          this.idchange(idevent)
        }
      })
    }
    get f() { return this.uploadDocForm.controls; }
  
    getKycParams() {
      this.callApi.getKycParams().subscribe((res: any) => {
        this.idTypeList = res.data
        this.idTypeList.forEach((element: any, index: any) => {
          if (this.countryName != 'India' && element.name == 'Aadhar Card') {
            this.idTypeList.splice(index, 1);
            // let event = element.name 
            // this.idchange(event)
          }
        });
      })
    }
  
    idchange(event: any) {
      if (event?.target?.value == 'National ID' || event?.target?.value == 'Adhar Card' || event == 'National ID' || event == 'Adhar Card') {
        this.id_type = true
        this.idCredentialForm.controls["expiry_date"].clearValidators()
        this.idCredentialForm.controls["expiry_date"].updateValueAndValidity()
      } else {
        this.idCredentialForm.controls["expiry_date"].setValidators(Validators.required)
        this.idCredentialForm.controls["expiry_date"].updateValueAndValidity()
        this.id_type = false
      }
      // this.idTypeList.map((item:any)=>{
      //   if(item.name==event){
      //     this.id_type = item.id_type_status==0? true:false
      //   }
      // })
    }
  
    async uploadImg(event: any, type: any) {
      let image = event.target.files[0];
      let front = new FormData();
      if (event.target.files[0].size > 9000000) {
        this.callApi.showError('File upload size is less than 10MB')
      }
      if (type == 1) {
        let data = this.validimage.fileUploadValidator(event)
        if (data == false) {
          this.callApi.showError('File Format Like JPG,PNG,JPEG')
        } else {
          front.append('id_front_image', image)
          this.callApi.uploadKycImage(front).subscribe(async (res: any) => {
            if (res.success == true) {
              this.uploadDocForm.patchValue({ id_front_image: res.data.id_front_image })
              this.image.front = res.data.id_front_image;
            } else {
              this.callApi.showError(res.message)
            }
          })
        }
      }
      else {
        let data = this.validimage.fileUploadValidator(event)
        if (event.target.files[0].size > 9000000) {
          this.callApi.showError('File upload size is less than 10MB')
        }
        if (data == false) {
          this.callApi.showError('File Format Like JPG,PNG,JPEG')
        } else {
          front.append('id_back_image', image)
          this.callApi.uploadKycImage(front).subscribe(async (res: any) => {
            if (res.success == true != event.target.files[0].size > 2000000) {
              this.uploadDocForm.patchValue({ id_back_image: res.data.id_back_image });
              this.image.back = res.data.id_back_image;
            } else {
              this.callApi.showError(res.message)
            }
          })
        }
      }
    }
  
    submit() {
      this.submitted = true
      if (this.uploadDocForm.valid) {
      let form_data: FormData = new FormData()
      this.button = 'Processing'
      this.isLoading = true
      let payload: any = {
        ...this.acInfoForm.value,
        // ...this.uploadDocForm.value,
        ...this.idCredentialForm.value,
        country_code: this.dialCodeData
      }
      payload.date = this.datePipe.transform(payload.date, 'yyyy-MM-dd');
      if (payload.expiry_date && payload.expiry_date !== "null") {
        payload.expiry_date = this.datePipe.transform(payload.expiry_date, 'yyyy-MM-dd');
      }
      form_data.append('first_name', payload?.first_name)
      form_data.append('country_code', payload?.country_code)
      form_data.append('last_name', payload?.last_name)
      form_data.append('date', payload?.date)
      form_data.append('city', payload?.city)
      form_data.append('country', payload?.country)
      form_data.append('phoneNumber', payload?.phoneNumber)
      form_data.append('id_type', payload?.id_type)
      form_data.append('id_number', payload?.id_number)
      form_data.append('address', payload?.address)
      form_data.append('expiry_date', payload?.expiry_date)
      // form_data.append('selfie_holding', payload?.selfie_holding)
      this.callApi.submitKyc(form_data).subscribe(async (res: any) => {
        if (res.success) {
          this.showSumbContainer = true;
          const accessToken = await this.callApi.getKycAccessToken();
          console.log(accessToken, `${payload?.country_code}${payload?.phoneNumber}`, this.userEmail);
          this.launchWebSdk(accessToken, `${payload?.country_code}${payload?.phoneNumber}`, this.userEmail)
          this.router.navigate(['/profile'])
          this.callApi.showSuccess(res.message)
          localStorage.removeItem('uploadImg')
          this.submitted = false
          this.button = 'Submit'
          this.isLoading = false
        }
        else {
          this.button = 'Submit'
          this.isLoading = false
          this.callApi.showError(res.message)
        }
      })
      }
    }
    dialCode(event: any) {
      this.countryName = event
      this.country.map((item: any) => {
        if (item.name == event) {
          this.dialCodeData = item.dial_code
          // console.log("🚀 ~ KycuserComponent ~ this.country.map ~  this.dialCodeData:", this.dialCodeData)
        }
      })
      this.getKycParams()
    }
  
    stringValidation(event: any) {
      if (!event.key.match(/^[a-zA-Z]+$/)) {
        event.preventDefault();
      }
    }
  
    numberValidation(event: any) {
      if (!event.key.match(/^[a-zA-Z0-9]/)) {
        event.preventDefault();
      }
    }
  
    userNameValidation(event: any) {
      if (!event.key.match(/^[a-zA-Z0-9._]+$/)) {
        event.preventDefault();
      }
    }
    addressValidators(event: any) {
      if (!event.key.match(/^[A-Za-z0-9/-]+(?:,[A-Za-z0-9/-]+)*$/)) {
        event.preventDefault();
      }
    }
    validation(event: KeyboardEvent) {
      if (!event.key.match(`^[a-zA-Z]*$`)) {
        event.preventDefault()
      }
    }
    validateInput(event: KeyboardEvent) {
      const allowedCharacters = (/[a-zA-Z0-9]/);
      if (!allowedCharacters.test(event.key)) {
          event.preventDefault();
      }
    }
    
  
    public showWebcam = true;
    public allowCameraSwitch = true;
    public multipleWebcamsAvailable = false;
    public deviceId: any;
    public videoOptions: MediaTrackConstraints = {
      // width: {ideal: 1024},
      // height: {ideal: 576}
    };
    public errors: WebcamInitError[] = [];
  
    // latest snapshot
    public webcamImage: any;
  
    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
  
    public triggerSnapshot(): void {
      this.trigger.next();
    }
  
    public toggleWebcam(): void {
      this.showWebcam = !this.showWebcam;
    }
  
    public handleInitError(error: WebcamInitError): void {
      this.errors.push(error);
    }
  
    public showNextWebcam(directionOrDeviceId: boolean | string): void {
      // true => move forward through devices
      // false => move backwards through devices
      // string => move to device with given deviceId
      this.nextWebcam.next(directionOrDeviceId);
    }
  
    public handleImage(webcamImage: WebcamImage): void {
      this.webcamImage = webcamImage;
      // let blob = new Blob([this.webcamImage?._imageAsDataUrl], {type:this.webcamImage?._mimeType});
      let base64String = webcamImage!.imageAsDataUrl;
  
      // Remove the data URL prefix
      let base64Data = base64String.split(',')[1];
  
      // Convert base64 to raw binary data held in a string
      let byteString = window.atob(base64Data);
      // Separate into a byte array
      let byteArray = new Uint8Array(byteString.length);
  
      for (let i = 0; i < byteString.length; i++) {
        byteArray[i] = byteString.charCodeAt(i);
      }
      let blob = new Blob([byteArray], { type: "image/jpeg" });
      // let blob = new File([this.webcamImage?._imageAsDataUrl], "Webcamimage" , { type: 'image/png' });
      // let blob = new File([this.webcamImage?._imageAsDataUrl], "Webcamimage" , { type: 'image/png' });
      this.uploadDocForm.patchValue({ selfie_holding: blob })
    }
  
    public cameraWasSwitched(deviceId: string): void {
      this.deviceId = deviceId;
    }
  
    public get triggerObservable(): Observable<void> {
      return this.trigger.asObservable();
    }
  
    public get nextWebcamObservable(): Observable<boolean | string> {
      return this.nextWebcam.asObservable();
    }
  
    launchWebSdk(accessToken: string, phone: string, email: string): void {
      let snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        () => this.getNewAccessToken()
      )
        .withConf({
          lang: localStorage.getItem('current_language') || 'en',
          email,
          phone
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on('idCheck.onStepCompleted', async (payload: any) => {
          // console.log('onStepCompleted', payload);
          if (payload.idDocSetType == 'IDENTITY') {
            this.callApi.showSuccess('Your ID submitted')
          }
          else if (payload.idDocSetType == 'SELFIE') {
            this.router.navigate(['/dashboard'])
            this.callApi.showSuccess('Your Kyc request submitted')
          }
  
        })
        .on('idCheck.onApplicantLoaded', (payload: any) => {
          // console.log('onApplicantLoaded', payload);
  
          // this.callApi.createApplicant(payload).subscribe(async (res: any) => {
          //   console.log(res)
          // })
        })
        .on('idCheck.onApplicantSubmitted', () => {
          console.log('onApplicantSubmitted');
        })
        .on('idCheck.onApplicantResubmitted', () => {
          console.log('onApplicantResubmitted');
        })
        .on('idCheck.onApplicantSubmitted', (payload: any) => {
          console.log('onApplicantSubmitted', payload);
        })
        .on('idCheck.onError', (error: any) => {
          console.log('onError', error);
        })
        .build();
  
      snsWebSdkInstance.launch('#sumsub-websdk-container');
    }
    async getNewAccessToken(): Promise<string> {
      // Implement logic to get a new access token from your backend
      let token = this.callApi.getKycAccessToken();
      return Promise.resolve(token);
    }
  }
    