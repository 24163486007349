
<mat-dialog-content>
    <div class="position-relative popup">
        <mat-icon class="close-icon" [mat-dialog-close]="true" color="warn">close</mat-icon>
        <div class="text-center text-success">
            <h2 class="fs-3 mb-1">{{data.Amt}} USDT</h2>
            <h3>Winning Price</h3>
        </div>
        <div class="d-flex">
            <div class="vertical-hr">
                <div>
                    <label for="">Ticket Name</label>
                    <h4 class="f-bold primary">{{data.ticketDrawName}}</h4>
                </div>
                <div>
                    <label for="">Ticket Number</label>
                    <h4 class="f-bold primary">{{data.ticketDetails.ticket_no}}</h4>
                </div>
                
            </div>
         <div class="ps-3">
            <div>
                <label for="">Draw Date</label>
                <h3 class="fw-bold primary">Ticket Draw date</h3>
            </div>
           <div>
                <label for="">Draw Date</label>
                <h3 class="fw-bold primary">{{data.resultDate | date:'shortDate'}}</h3>
           </div>
            
         </div>
        </div>
        <div class="d-flex justify-content-between mt-4">
            <button class="btn btn-back" [mat-dialog-close]="true">Back</button>
            <button class="btn btn-back">Withdraw</button>
        </div>
    </div>
</mat-dialog-content>


