import { Component, OnInit, TemplateRef, ElementRef, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ICreateOrderRequest, IPayPalConfig } from 'ngx-paypal';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import translate from 'translate';
declare var jQuery: any;

import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from '../../environments/environment.development';
import { UserService } from '../services/user.service';
import { SelectService } from '../services/translate/select.service';
import { ImageValidationService } from '../services/image-validation.service';

// HPie(Highcharts);
@Component({
  selector: 'app-wallet',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss']

})
export class WalletComponent implements OnInit, AfterViewInit {
  // moment = require('moment-timezone');
  @ViewChild('viewHistory') viewHistory: ElementRef | any;
  showError: boolean = false
  showSuccess: boolean = false
  showCancel: boolean = false
  data: any = [1, 2, 3, 4, 5, 6]
  public imagePath = environment.IMAGE_URL;
  wallet: any;
  transactionmethod: any;
  coinId: any;
  profileImg: any;
  depositMin: any;
  fiatId: boolean = false;
  submitted: boolean = false;
  balanceof: any;
  form: any;
  value: any;
  myform: any;
  stripe: any;
  card: any;
  error: any;
  stackInput: any;
  public payPalConfig?: IPayPalConfig;
  @ViewChild('cardInfo') cardInfo: any = ElementRef;
  cardHandler = this.onChange.bind(this);
  rangevalue = 0;
  percentSubmitted: boolean = false;
  copyTxt: any = 'Copy'
  adminBankDetail: any;
  wiredDepositFiatForm: any;
  wirelessDepositFiatForm: any;
  previewImg: any;
  previewImg1: any;
  token: any;
  localFiatDepData: any = {
    frontImage: '',
    backImage: ''
  }
  kyc: any
  kyStatus: boolean = false;
  upiSubmit: boolean = false
  transaction_method: any;
  amount: any;
  transactionId: any;
  fiatDocImg: any;
  amountSubmitted: boolean = false;
  fiatPreVieImg: any;
  payingsubmitted: boolean = false;
  stripeform: any;
  year: any
  month: any
  userBanks: any = []
  userBankInfo: any;
  userUpiInfo: any;
  adminBanks: any;
  adminBankInfo: any = {};
  adminUpiInfo: any;
  foundhistory: any
  imgs: any
  years: any;
  months: any
  paypalClientId: any = environment.paypal_client_id
  currencyImg: any;
  total: any;
  search: string = '';
  searchText: any
  originalId: any;
  allHistory: any;
  depositHistory: any;
  withdrawHistory: any;
  buyHistory: any;
  sellHistory: any;
  avaiableBalance: any;
  percentage: any;
  pnl: any;
  returns: any = 0;
  interest_value: any = 0;
  currentAmount: any;
  marginFundHistory: any;
  borrow: any;
  repaydata: any;
  transfertype: any = ["", 'Spot', 'Margin', 'Future']
  transferto: any = ["", 'Cross', 'Isolated']
  walletData: any;
  user_tfa: any
  otp_form: any
  isSubmited: boolean = false
  page_history: any = { pageNo: 1, page_index: 0 };
  twofaDialog: any;
  findedData: any = [];
  tokenData: any = [];
  fiatData: any = [];
  coin_type: any;
  cointype: any = "null";
  walletDatas: any;
  cointypeEvent: any;
  isSearched: boolean = false;
  public translateControl: any;
  searching!: string;
  Address!: string;
  Amount!: string;
  eti!: string;
  ea!: string;
  Full_Name!: string;
  Enter_2FA_code!: string;
  Card_Number!: string;
  Clear!: string;
  Filter!: string;
  deposit!: string;
  withdraw!: string;
  cardType: any;
  cardNumber: any;
  addressList: any;
  add: string = '';
  coinName: any;
  tokenId: any;
  tradeData: any;
  dataSource: any;
  coinValues: any;
  isIconClicked: boolean = false
  searchaddress: any
  settingsData: any;
  ResponseTime: any;
  ResponseDate: any
  type: any;
  quantity: any;
  slug: any;
  earn: any;
  payPalShow: boolean = false;
  selectImage: any;
  selectSlug: any;
  coinPer: any;
  color = ["#FAA300", "#F4538A", "#0C359E", "#7f3e83", "#812900", "#2078b6", "#df7f2e", "#C738BD", "#40A578"]
  day_pnl_percentage: any;
  mnth_pnl_percentage: any;
  day_pnl: any;
  pnl_per: any;
  singlecoinPnl: any;
  constructor(private fb: FormBuilder,
    private dialog: MatDialog,
    private callApi: UserService,
    private ref: ChangeDetectorRef, private router: Router, public selectService: SelectService, private validimage: ImageValidationService) { }
  ngAfterViewInit(): void {
    this.userFiatBalancePieChart()
    throw new Error('Method not implemented.');
  }

  paymentForm: any
  totalBalance: any;
  chartData: any;
  chartOptions: any;
  // Highcharts = Highcharts;
  // chart!: Highcharts.Chart;
  popularcoins: any = []
  ngOnInit(): void {
    this.getWallet({}, 1);
    this.filterType(1)
    this.getProfile()
    this.createForm();
    // this.paypal();
    this.getAddressList()
    this.getSettings()
    this.userFiatBalancePieChart()
    this.pnlCalculation();
    this.totalPnlCalculation()
    this.paymentForm = this.fb.group({
      amout: ['', [Validators.required]]
    });
    this.stripeForm();
    this.selectService.getSharedData().subscribe(data => {
      this.translateControl = data
      if (this.translateControl == 'es') {
        this.searching = 'Buscar moneda',
          this.Address = 'DIRECCIÓN',
          this.Amount = 'Cantidad',
          this.eti = 'Ingrese el ID de la transacción',
          this.ea = 'Ingrese el monto',
          this.Full_Name = 'Nombre completo',
          this.Card_Number = 'Número de tarjeta',
          this.Enter_2FA_code = 'Ingrese el código 2FA'
        this.Clear = 'Claro'
        this.Filter = 'Filtrar'
        this.deposit = 'Depósito',
          this.withdraw = 'Retirar'
      } else if (this.translateControl == 'fr') {
        this.searching = 'Rechercher une pièce',
          this.Address = 'Adresse',
          this.Amount = 'Montant',
          this.eti = 'Entrez l identifiant de la transaction',
          this.ea = 'Entrer le montant',
          this.Full_Name = 'Nom et prénom',
          this.Card_Number = 'Numéro de carte'
        this.Enter_2FA_code = 'Entrez le code 2FA'
        this.Clear = 'Clair'
        this.Filter = 'Filtre'
        this.deposit = 'Dépôt',
          this.withdraw = 'Retirer'
      } else {
        this.searching = 'Search Coin',
          this.Address = 'Address',
          this.Amount = 'Amount',
          this.eti = 'Enter Transaction Id',
          this.ea = 'Enter Amount',
          this.Full_Name = 'Full Name',
          this.Card_Number = 'Card Number'
        this.Enter_2FA_code = 'Enter 2FA code'
        this.Clear = 'Clear'
        this.Filter = 'Filter'
        this.deposit = 'Deposit',
          this.withdraw = 'Withdraw'
      }
    })
    this.callApi.userFiatBalances().subscribe((res: any) => {
      this.coinValues = res?.data
      this.coinPer = res?.data?.data
    })
  }
  stripeForm() {
    this.stripeform = this.fb.group({
      fullName: ['', Validators.required],
      Amount: ['', Validators.required],
      cardNumber: ['', Validators.required],
      cvv: ['', Validators.required],
      month: ['', Validators.required],
      year: ['', Validators.required]
      // parentesco: [''],
      // estado: [null, [Validators.required]]
    });
  }
  getProfile() {
    this.callApi.startSpinner();
    this.callApi.getProfile().subscribe(async (res: any) => {
      this.callApi.stopSpinner();

      this.kyc = res?.data?.userDeatils

      if (this.kyc?.kyc_verify == 2) {
        this.kyStatus = true
        // this.getWallet(1);
      }
      if (this.kyc?.kyc_verify == 0) {
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('Oops...', this.translateControl),
          text: await translate('Please submit Your Kyc!', this.translateControl),
          footer: `
          <html>
          <style>
            .swal2-footer {
              border-top: 0;
              margin-top: 0;
            }
            </style>
          <a href="kyc-user" class="link-warning fs-13">Submit your Kyc?</a>
          </html>`
        })

      }
      else if (this.kyc?.kyc_verify == 1) {
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('Please wait!', this.translateControl),
          text: await translate('Your KYC is waiting for admin approval.', this.translateControl),
          footer: `
          <html>
          <style>
          .swal2-footer {
            border-top: 0;
            margin-top: 0;
          }
          </style>
          <a href="kyc-user" class="link-warning fs-13">View Submitted KYC Details</a>
          </html>`,
        })
      }
      else if (this.kyc?.kyc_verify == 3) {
        this.callApi.stopSpinner();
        this.router.navigate(['/dashboard'])
        Swal.fire({
          icon: 'error',
          title: await translate('KYC Rejected!', this.translateControl),
          text: await translate('Your KYC has been Rejected. Please contact our helpdesk.', this.translateControl),
          footer: `
          <html>
          <style>
          .swal2-footer {
            border-top: 0;
            margin-top: 0;
          }
          </style>
          <a href="kyc-user" class="link-warning fs-13">Resubmit Kyc?</a>
          </html>`,
        })
      }

    }, async err => {
      this.callApi.stopSpinner();

      Swal.fire({
        icon: 'error',
        title: await translate('Oops...', this.translateControl),
        text: await translate('Some error occurred. Please try again later! For more info contact our helpdesk!', this.translateControl)
      }).then(() => {
        this.router.navigate(['/dashboard'])
      })
    })

  }

  pnlCalculation() {
    let payload = {
      coin_id: this.coinId,
    }
    this.callApi.walletCoinPnlCalculation(payload).subscribe((res: any) => {
      this.singlecoinPnl = res.data
      // day_pnl,pnl_per
      this.day_pnl = this.singlecoinPnl.day_pnl
      this.pnl_per = this.singlecoinPnl.pnl_per
      console.log("🚀 ~ WalletComponent ~ this.callApi.walletCoinPnlCalculation ~ res:", res)
    })
  }

  totalPnlCalculation() {
    this.callApi.walletCoinTotalPnlCalculation().subscribe((res: any) => {
      console.log("🚀 ~ WalletComponent ~ this.callApi.walletCoinPnlCalculation ~ res:", res)
      this.day_pnl_percentage = res.data.dayUSDPNL
      console.log("🚀 ~ WalletComponent ~ this.callApi.walletCoinTotalPnlCalculation ~  this.day_pnl_percentage:",  this.day_pnl_percentage)
      this.mnth_pnl_percentage = res.data.mnthUSDPNL
    })
  }

  get amout() {
    return this.paymentForm.get('amout')
  }

  openBalance(data: any) {
    if (data.wallet_address != null) {
      this.getWalletDetailsWithId(data._id)
    } else {
      this.createAddress(data._id)
    }
  }

  createAddress(id: any) {
    console.log(id, 'id');
    this.callApi.startSpinner();
    this.callApi.createAddress({ coin_id: id }).subscribe((res: any) => {
      if (res.success == true) {
        this.getWalletDetailsWithId(id)
        this.callApi.stopSpinner();
      }
      else {
        this.callApi.stopSpinner();
      }
    }, err => {
      this.callApi.serverError();
    })
    this.getAddressList()

  }

  getWalletDetailsWithId(payload: any) {
    this.coinId = payload
    this.callApi.getWalletDetailsWithId({ coin_id: payload }).subscribe((res: any) => {
      if (res.success == true) {
        this.depositMin = res.data?.walletDetails?.coins[0]
        this.walletData = res?.data
        this.callApi.stopSpinner();
        this.paypal()
      }
      this.callApi.stopSpinner();
      if (res?.data?.fiatDeposit == true) {
        this.fiatId = true
        this.getBankList();
      } else {
        this.callApi.stopSpinner();
        this.fiatId = false
      }
      this.currencyImg = res.data?.walletDetails?.coins[0]?.image
      this.balanceof = res.data?.walletDetails
      this.total = (this.balanceof?.balance + this.balanceof?.escrow_balance)
      this.form.patchValue({ coin_id: this.balanceof?.coin_id })

      if (this.walletData?.walletDetails?.token) {
        this.value = this.walletData?.walletDetails?.wallet_details[0].wallet_address
        this.form.patchValue({ tokenNetwork: this.walletData?.walletDetails?.wallet_details[0].network })
      } else {
        this.value = res.data?.walletDetails?.wallet_address
      }
      this.callApi.stopSpinner();
    }, err => {
      this.callApi.stopSpinner();
    })
  }
  getWallet(payload: any, page: any) {
    payload = {
      coin_type: Number(this.cointypeEvent)
    }
    this.page_history.pageNo = page
    this.callApi.getWallet2(payload, page).subscribe((res: any) => {
      this.earn = res?.data?.earn
      if (res?.success) {
        if (page == 1) {
          this.wallet = res?.data?.data
        }
        else {
          this.wallet = this.wallet.concat(res?.data?.data)
        }
        this.page_history.pageNo = page;
        this.page_history.totalPage = res?.data?.totalcount

        this.isSearched = true
        this.getWalletDetails();
        if (this.page_history?.pageNo == 1) {
          if (this.wallet[0]?.wallet_address != null) {
            this.getWalletDetailsWithId(this.wallet[0]._id)
          } else {
            this.createAddress(this.wallet[0]._id)
          }
        }
      }
    })
  }

  filterType(page: any) {
    let payload = {
      coin_type: Number(this.cointype)
    }
    this.callApi.getWallet2(payload, page).subscribe((res: any) => {
      if (res.success) {
        this.wallet = res?.data?.data
        this.getWallet(payload, page);
      }
    })
  }

  getWalletDetails() {
    this.callApi.getCoinDetails().subscribe((res: any) => {
      if (res.success == true) {
        this.callApi.stopSpinner();
      } else {
        this.callApi.stopSpinner();
      }
      this.balanceof = res.data[0]?.wallets
    }, err => {
      this.callApi.stopSpinner();
      this.callApi.serverError();
    })
  }
  openDialog(tempref: TemplateRef<any>, id: any, details: any) {
    this.tokenId = id
    this.dialog.open(tempref, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "75rem",
      minHeight: "auto",
      maxHeight: "calc(100vh - 6rem)"
    });
    this.dialog.afterAllClosed.subscribe((res) => {
      if (this.payPalShow && details?.coin_type == 0) {
        window.location.reload()
      }
    })
    this.getWalletDetailsWithId(id)
    this.createAddress(id)
    this.getAddressList()
  }
  openPopup(tempref: TemplateRef<any>) {
    this.dialog.open(tempref, {
      width: "100%",
      height: "auto",
      minWidth: 'auto',
      maxWidth: '45rem'
    });
  }

  getAddressList() {
    let datas = {
      coin_id: this.wallet?._id,
      tokenNetwork: this.value

    }

    this.callApi.tokenAddressList(datas, {}).subscribe((res: any) => {
    })
  }
  getAddress(token: any) {
    this.isIconClicked = true
    if (token) {
      this.add = token?.wallet_address
    }
    setTimeout(() => {
      jQuery('#address-id').click()
    }, 1000)

  }

  payopenDialog(tempref: TemplateRef<any>) {
    this.dialog.open(tempref, {
      height: "auto", width: "90%"
    });
    this.localFiatDepData.frontImage = '';
    this.localFiatDepData.backImage = '';
    this.fiatWirelessDepositForm();
    this.fiatWiredDepositForm();
    this.callApi.adminBankDetails({}).subscribe((res: any) => {
      this.adminBankDetail = res.data.result
    })
  }
  get f() { return this.form.controls; }
  createForm() {
    this.form = this.fb.group({
      coin_id: [],
      to_address: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      tokenNetwork: ['']
    })
  }


  ngOnDestroy(): void {
    this.dialog.closeAll();
    this.card?.destroy();
  }
  onChange({ error }: any) {
    this.error = error ? error.message : null;
    this.ref.detectChanges();
  }
  copyText(val: string) {
    if (val) {
      this.copyTxt = 'Copied!';
      setTimeout(() => {
        this.copyTxt = 'Copy';
      }, 3000);
    }
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

  }

  selectPercentage(percentagevalue: any) {
    this.rangevalue = percentagevalue.target.value;
    this.form.patchValue({ amount: percentagevalue.target.value });
    let data: any = ((this.balanceof.balance * this.rangevalue) / 100).toFixed(3)
    let divide = Number(data * this.depositMin?.withdraw_fee / 100)
    let result: any = (Number(data) - divide).toFixed(3)
    if (data) {
      let patch = this.form.patchValue({ amount: result })
      if (patch) {
        this.createForm();
      }
    }
  }

  keyPressNumbers(event: any) {
    this.percentSubmitted = false;
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 46 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  keyPressSpace(event: any) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if ((charCode < 46 || charCode > 122)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  fiatWirelessDepositForm() {
    this.wirelessDepositFiatForm = this.fb.group({
      payment_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      document_proof: ['', [Validators.required]]
    })
  }
  fiatWiredDepositForm() {
    this.wiredDepositFiatForm = this.fb.group({
      bank_id: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      document_proof: ['', [Validators.required]],
    })
  }

  uploadImage(event: any, type: any) {
    let img = event.target.files[0]
    let formData = new FormData()
    formData.append('document_proof', img)
    this.callApi.fiatDepositImaget(formData).subscribe((res: any) => {
      if (res.success == true) {
        type == 1 ? this.localFiatDepData.frontImage = res.data.document_proof : '';
        type == 1 ? this.previewImg = res.data.document_proof : ''
        type == 2 ? this.localFiatDepData.backImage = res.data.document_proof : '';
        type == 2 ? this.previewImg1 = res.data.document_proof : ''
        type == 1 ? this.wiredDepositFiatForm.patchValue({ document_proof: this.localFiatDepData.frontImage }) : '';
        type == 2 ? this.wirelessDepositFiatForm.patchValue({ document_proof: this.localFiatDepData.backImage }) : '';
      }
    })

  }

  open2fa() {
    this.router.navigate(['/profile'])
  }
  openwith(templateRef: TemplateRef<any>) {
    this.upiSubmit = false
    this.transaction_method = 1
    this.amount = '';
    this.dialog.open(templateRef, {
      width: "50%",
      height: "auto%",
    })
  }

  open(templateRef: TemplateRef<any>) {
    this.transaction_method = 1
    this.amount = '';
    this.transactionId = '';
    this.fiatDocImg = '';
    this.fiatPreVieImg = '';
    this.amountSubmitted = false;
    this.dialog.open(templateRef, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "40rem",
      maxHeight: "calc(100vh - 5rem)",
      minHeight: "100%"
    })
  }

  submitStripe() {
    this.payingsubmitted = true;
    this.stripeform.value.slug = this.depositMin?.slug;
    this.stripeform.value.fee = this.depositMin?.deposit_fee
    this.stripeform = this.stripeform.group({
      parentesco: [''],
      estado: [null, [Validators.required]]

    });
    if (this.stripeform.invalid) {
      return;
    }
    let payload: any = this.stripeform.value;
    this.callApi.createStripe(payload).subscribe(async (res: any) => {
      if (res.success == true) {
        this.getWallet({}, 1);
        this.callApi.showSuccess(await translate(res.message, this.translateControl))
        this.dialog.closeAll()
      } else {
        this.callApi.showError(await translate(res.message, this.translateControl))
        this.dialog.closeAll()
      }
      this.payingsubmitted = false;
    })

  }
  get sf() { return this.stripeform.controls; }

  creditCheck(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    let val = input.value?.toUpperCase();
    let trimmed = val.replace(/[^0-9A-Z]+/g, '');
    let numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }
    input.value = numbers.join(' ');

  }

  changeBank(event: any, on: string, type: string) {
    let index = event.target.value
    if (type == 'bank') {
      if (on == 'admin') {
        this.adminBankInfo = this.adminBanks[index]
      } else {
        this.userBankInfo = this.userBanks[index]
      }
    } else {
      if (on == 'admin') {
        this.adminUpiInfo = this.adminBanks[index]
      } else {
        this.userUpiInfo = this.userBanks[index]
      }
    }
  }
  addbank() {
    this.router.navigate(['/bank-details'])
    this.dialog.closeAll();
  }

  getBankList() {
    this.callApi.getBankDetails({}).subscribe((res: any) => {
      this.userBanks = res?.data?.data
      let user_bank: any = []
      let user_upi: any = []
      if (this.userBanks.length > 0) {
        this.userBanks.map((item: any) => {
          if (item.payment_method == 1) {
            user_bank.push(item)
          }
          else {
            user_upi.push(item)
          }
        })
      }
      this.userBanks.bank_count = user_bank.length

      this.userBanks.upi_count = user_upi.length

      let bankArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 1;
      });

      let upiArr = this.userBanks.filter(function (item: any) {
        return item.payment_method == 2;
      });

      this.userBankInfo = bankArr[0]

      this.userUpiInfo = upiArr[0]

    })
    this.callApi.adminBankDetails({}).subscribe((res: any) => {


      this.adminBanks = res.data.data
      if (Array.isArray(this.adminBanks)) {
        let bankArr = this.adminBanks.filter(function (item: any) {
          return item.payment_method == 1;
        });

        let upiArr = this.adminBanks.filter(function (item: any) {
          return item.payment_method == 2;
        });

        this.adminBankInfo = bankArr[0]

        this.adminUpiInfo = upiArr[0]
      }
    })
  }

  depositRequest() {
    this.amountSubmitted = true
    if ((this.transactionId == "") || (this.amount == "") || (this.fiatDocImg == "")) {
      return
    }
    let payload = {
      coin_id: this.coinId,
      user_bank_id: this.transaction_method == 1 ? this.userBankInfo._id : this.userUpiInfo._id,
      admin_bank_id: this.transaction_method == 1 ? this.adminBankInfo._id : this.adminUpiInfo._id,
      amount: this.amount,
      transaction_type: 1,
      transaction_id: this.transactionId,
      transaction_method: this.transaction_method,
      document_proof: this.fiatDocImg
    }

    this.callApi.depositApproveRequest(payload).subscribe(async (res: any) => {
      if (res.success) {
        this.amountSubmitted = false
        Swal.fire({
          icon: 'success',
          title: await translate('Success!', this.translateControl),
          text: await translate(res.message, this.translateControl)
        })
        this.dialog.closeAll();
      } else {
        Swal.fire({
          icon: 'error',
          title: await translate('Opps.....!', this.translateControl),
          text: await translate('Maximum Deposite Limit 1000', this.translateControl)
        })
      }
    })
  }

  withdrawRequest() {
    this.upiSubmit = true
    if (this.amount != '') {
      let payload = {
        coin_id: this.coinId,
        user_bank_id: this.transaction_method == 1 ? this.userBankInfo._id : this.userUpiInfo._id,
        amount: this.amount,
        transaction_type: 2,
        transaction_method: this.transaction_method,
      }

      this.callApi.fiatWithdrawRequest(payload).subscribe(async (res: any) => {
        if (res.success) {
          Swal.fire({
            icon: 'success',
            title: await translate('Success!', this.translateControl),
            text: await translate(res.message, this.translateControl)
          })
          this.dialog.closeAll();
        } else {
          Swal.fire({
            icon: 'error',
            title: await translate('Opps.....!', this.translateControl),
            text: await translate(res.message, this.translateControl)
          })
        }
      })
    }
  }
  async img(event: any) {
    let data = this.validimage.fileUploadValidator(event)
    if (data == false) {
      this.callApi.showError(await translate('File Format Like JPG,PNG,JPEG', this.translateControl))
    } else {
      this.imgs = event.target.files[0]
      let formData = new FormData()
      formData.append('document_proof', this.imgs)
      this.callApi.fiatDepositImaget(formData).subscribe((res: any) => {
        if (res.success == true) {
          this.fiatDocImg = res?.data?.document_proof
          let reader = new FileReader();
          reader.readAsDataURL(event.target.files[0]);
          reader.onload = (event: any) => {
            this.fiatPreVieImg = event.target.result;
          }
        }
      })
    }
  }
  setPaymentType(type: number) {
    this.transaction_method = type
  }
  brokeImage(event: any) {
    event?.target?.src
  }
  paypalPopup(templateRef: TemplateRef<any>) {
    this.dialog.open(templateRef, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "35rem"
    })
  }
  StripePopup(templateRef: TemplateRef<any>) {
    this.clearStripFrom();
    this.years = this.generateArrayOfYears();
    this.months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    this.dialog.open(templateRef, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "30rem"
    })
  }
  clearStripFrom() {
    this.stripeform?.get('fullName').reset();
    this.stripeform?.get('Amount').reset();
    this.stripeform?.get('cardNumber').reset();
    this.stripeform?.get('cvv').reset();
  }
  generateArrayOfYears() {
    var min = new Date().getFullYear()
    var max = min + 9
    var years = []
    for (var i = min; i <= max; i++) {
      years.push(i)
    }
    return years
  }
  getMonthDays(daysCount: number): Array<number> {
    const resultArray = [];
    for (let i = 1; i <= daysCount; ++i) {
      resultArray.push(i);
    }
    return resultArray;
  }
  async onSubmit(form: NgForm) {
    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
    } else {
    }
  }
  paypal() {
    this.payPalConfig = {
      currency: this.depositMin?.slug,
      clientId: this.paypalClientId,
      createOrderOnClient: (data) => <ICreateOrderRequest>{
        intent: 'CAPTURE',
        purchase_units: [{
          amount: {
            currency_code: this.depositMin?.slug,
            value: this.amount,
            breakdown: {
              item_total: {
                currency_code: this.depositMin?.slug,
                value: this.amount
              }
            }
          }
        }]
      },
      advanced: {
        commit: 'true'
      },
      style: {
        label: 'paypal',
        layout: 'vertical'
      },
      onApprove: (data: any, actions: any) => {
        actions.order.get().then((details: any) => {
        });

      },
      onError: async err => {
        if (this.amount == null) {
          this.callApi.showError(await translate('Amount Field is Required', this.translateControl))
        } else {
          this.callApi.showError(await translate(err, this.translateControl))
        }
      },
      onClientAuthorization: (data: any) => {
        let payload = {
          'trxid': data.id,
          'slug': this.depositMin?.slug,
          'fee': this.depositMin?.deposit_fee
        }
        this.callApi.paypalTransaction(payload).subscribe(async (response: any) => {
          if (response.success == true) {
            this.showSuccess = true;
            this.getWallet({}, 1);
            this.callApi.showSuccess(await translate(response.message, this.translateControl));
            this.dialog.closeAll()
          } else {
            this.callApi.showError(await translate(response.message, this.translateControl))
          }
        })
        this.showSuccess = true;
      },
      onClick: () => {
        this.payPalShow = true
      },
      onCancel: (data, actions) => {
        this.showCancel = true;

      },
    };
  }
  filterData() {

    let payload = {
      coin_type: this.coin_type

    }
    this.callApi.getWallet2({ slug: this.search?.toUpperCase(), }, 1).subscribe((res: any) => {
      this.wallet = res?.data?.data
    })
  }
  filterAddress() {
    let payload = {
      wallet_address: this.searchaddress
    }
    this.callApi.withdrawAddressSearch(payload).subscribe((res: any) => {
    })
  }

  getCoinHistory(id: any, image: any, slug: any) {
    this.dialog.open(this.viewHistory, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "20rem"
    })
    let payload = {
      coinid: id,
      currencyImg: image,
      slugs: slug
    }
    this.selectImage = payload?.currencyImg
    this.selectSlug = payload?.slugs
    this.callApi.walletCoinHistory(payload).subscribe((res: any) => {
      this.allHistory = res.data
    })


  }


  getCoinPnlHistory(id: any, image: any, slug: any) {
    this.dialog.open(this.viewHistory, {
      width: "100%",
      height: "auto",
      minWidth: "auto",
      maxWidth: "20rem"
    })
    let payload = {
      coin_id: id,
      currencyImg: image,
      slugs: slug
    }
    this.selectImage = payload?.currencyImg
    this.selectSlug = payload?.slugs
    this.callApi.walletCoinPnlCalculation(payload).subscribe((res: any) => {
      this.allHistory = res.data
    })


  }
  setTimeChecking() {

  }
  getDepositHistory() {
    let payload = {
      coinid: this.coinId,
      input: 'Deposit',
      inputs: 'Admin (ADD)'
    }
    this.callApi.walletCoinHistory(payload).subscribe((res: any) => {
      this.depositHistory = res.data
    })
  }
  getWithdrawHistory() {
    let payload = {
      coinid: this.coinId,
      input: 'Withdraw',
      inputs: "Admin (REDUCE)"
    }
    this.callApi.walletCoinHistory(payload).subscribe((res: any) => {
      this.withdrawHistory = res.data
    })
  }
  getBuyHistory() {
    let payload = {
      coinid: this.coinId,
      input: 'Buy'
    }
    this.callApi.walletCoinHistory(payload).subscribe((res: any) => {
      this.buyHistory = res.data
    })
  }
  getSellHistory() {
    let payload = {
      coinid: this.coinId,
      input: 'Sell'
    }
    this.callApi.walletCoinHistory(payload).subscribe((res: any) => {
      this.sellHistory = res.data
    })
  }
  //

  numberValidation(event: any) {
    if (!event.key.match(/^[0-9]\d*$/)) {
      return event.preventDefault()
    } else {
      return
    }
  }

  // rePay(){
  //   this.marginApi.preSaleTokenBuyCoin().subscribe((res:any)=>{

  //   })
  // }

  networkChange(data: any) {
    this.walletData?.walletDetails?.wallet_details?.map((item: any) => {
      if (data?.value == item?.network) {
        this.value = item?.wallet_address
      }
    })
  }

  submit(tempref: TemplateRef<any>) {
    this.submitted = true;
    this.isSubmited = false;
    if (!this.form.invalid) {
      this.otp_form = this.fb.group({
        otp: ['', [Validators.required]]
      })
      this.callApi.tfaStatus().subscribe((res: any) => {
        this.user_tfa = res?.data
        if (this.user_tfa?.authentic_status === '2') {
          this.emailAuthentication()
        }
        this.twofaDialog = this.dialog.open(tempref, {
          height: "auto", width: "auto"
        });
      })

    }
  }

  emailAuthentication() {
    this.callApi.emailAuthentication().subscribe(async (res: any) => {
      this.callApi.showInfo(await translate(res?.message, this.translateControl))
    })
  }

  get formVerify() { return this.otp_form.controls }

  submitWithdraw() {
    this.callApi.withdrawReq(this.form.value).subscribe(async (res: any) => {
      this.callApi.stopSpinner();
      if (res.success == true) {
        this.callApi.showSuccess(await translate(res.message, this.translateControl))
        this.form.reset();
        this.submitted = false;
        this.dialog.closeAll();
      }
      else {
        this.callApi.showError(await translate(res.message, this.translateControl))
      }

    }, err => {
      this.callApi.serverError();
    })

  }
  verifyOTP() {
    this.isSubmited = true;
    if (this.otp_form.valid) {
      let payload = {
        status: "3",
        ...this.otp_form.value
      }
      this.callApi.checkTfa(payload).subscribe(async (res: any) => {
        if (res.success) {
          this.twofaDialog.close();
          this.submitWithdraw();
        } else {
          this.callApi.showError(await translate(res.message, this.translateControl));
          this.isSubmited = false;

        }
      })
    }
  }
  emailTfaVerify() {
    this.isSubmited = true;
    if (this.otp_form.valid) {
      let payload = {
        twofa_otp: this.otp_form.get('otp').value,
        status: "3"
      }

      this.callApi.otpEmailVerification(payload).subscribe(async (res: any) => {
        if (res.success) {
          this.twofaDialog.close();
          this.submitWithdraw();
        } else {
          this.callApi.showError(await translate(res.message, this.translateControl));
          this.isSubmited = false;
        }
      })
    }
  }

  logOut() {
    this.twofaDialog.close();
  }
  changeEvent(event: any) {
    this.cointypeEvent = event?.target?.value
  }
  clear() {
    this.cointype = "null"
    this.callApi.getWallet2({}, 1).subscribe((res: any) => {
      if (res.success) {
        this.wallet = res?.data?.data
      }
    })
  }

  cardPatterns: any = {
    Visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    MasterCard: /^5[1-5][0-9]{14}$/,
    Amex: /^3[47][0-9]{13}$/
  };

  onCardNumberChange(cardNumber: any): void {
    this.cardType = this.getCardType(cardNumber);
  }
  getCardType(cardNumber: any): any {
    const numberFormatted = cardNumber.replace(/\D/g, '');
    for (const cardType in this.cardPatterns) {
      if (this.cardPatterns[cardType].test(numberFormatted)) {
        return cardType;
      }
    }
    return 'Unknown';
  }

  WalletHandlePageEvent(event: any) {
    this.page_history?.page_index + 1 == event?.pageIndex ?
      this.page_history.pageNo = this.page_history.pageNo + 1 : this.page_history.pageNo = this.page_history.pageNo - 1;

    this.page_history.page_index = event?.pageIndex;
    this.callApi.getWallet2({}, this.page_history.pageNo).subscribe(async (res: any) => {
      if (res.success == true) {
        this.wallet = res?.data?.data;
        // this.dataSource= new MatTableDataSource(this.wallet);
        // this.page_history.totalPage = res?.data?.totalCount
      } else {
        this.callApi.showError(await translate(res.message, this.translateControl))
      }
    }, async err => {
      this.callApi.showError(await translate(err, this.translateControl))
    })
  }
  createWalletAddress(id: any) {
    this.tokenId =
      this.getWalletDetailsWithId(id)
    this.createAddress(id)
    this.getAddressList()
  }
  createDepositAddress(id: any) {
    this.tokenId =
      this.getWalletDetailsWithId(id)
    this.createAddress(id)
    this.getAddressList()
  }

  getSettings() {
    this.callApi.getUserPreference().subscribe((res: any) => {
      this.settingsData = res?.data
    })
  }

  clearText() {
    this.search = ''
    this.getWallet({}, 1);

  }
  alphabets(event: any) {
    const maxLength = 16;
    const currentLength = event.target.value.length;
    if (!event.key.match(/^[a-zA-Z\s]*$/) || currentLength >= maxLength) {
      return event.preventDefault()
    } else {
      return
    }
  }
  userFiatBalancePieChart() {
    this.callApi.userFiatBalances().subscribe((res: any) => {

      if (res.success == true) {


        this.chartData = res?.data?.walletBalance
        let coinData = res?.data?.data

        this.totalBalance = res?.data?.totalBalance

        coinData.forEach((coin: any) => {
          coin.y = (coin.y / this.totalBalance) * 100;
        });
        this.chartOptions = {
          colorSet: 'customColorSet',
          responsive: true,
          animationEnabled: true,
          backgroundColor: "transparent",
          title: {
            text: "Wallet",
            fontColor: "#81858c",
            fontSize: 30,
            fontFamily: "sans-serif",
          },
          "Doughnut Hole Size": 20,
          data: [{
            type: "doughnut",
            yValueFormatString: "#,###.##' %'",
            dataPoints: coinData,
            startAngle: 45,
            innerRadius: "50%",
          }]
        }


        this.popularcoins = coinData.filter((item: any) => {
          if (item.name === 'BTC' || item.name === 'ETH' || item.name === "USDT") {
            let percent = (item.y) / (this.totalBalance) * 100;
            item['percent'] = percent.toFixed(2)
            return item
          }
        })



        // this.chartOptions = {
        //   chart: {
        //     type: 'pie',
        //     bacgrouncolor: 'transparent'
        //   },
        //   lang: {
        //     thousandsSep: '.',
        //     decimalPoint: ','
        //   },
        //   credits: {
        //     enabled: false
        //   },
        //   accessibility: {
        //     description: ''
        //   },
        //   title: {
        //     text: ''
        //   },

        //   tooltip: {
        //     percentageDecimals: 2,
        //     pointFormat: '<span style="color:{point.color}">\u25CF</span> <b> {point.name}</b><br/>' +
        //       'Balance: <b>{point.x}</b><br/>' +
        //       'USD Price: <b>${point.y:.f}</b><br/>'
        //   },
        //   series: [{
        //     minPointSize: 10,
        //     innerSize: '10%',
        //     zMin: 0,
        //     name: 'currency',
        //     data: coinData
        //   }]

        // }
      }
    })

  }
}

