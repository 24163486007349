<!-- footer -->
<footer class="py-3 border-top border-warning mt-5">
    <div class="text-center">
        <ul class="foot align-items-center ps-0">
          <li class="point"><a href="">
            <i class="fa-brands fa-twitter fa-2x"></i></a></li>
          <li class="point"><a href=""><i class='fab fa-facebook fa-2x'></i></a></li>
          <li class="point"><a href=""><i class="fa-solid fa-envelope fa-2x"></i></a></li>
          <li class="point"><a href=""><i class="fa-brands fa-square-whatsapp fa-2x"></i></a></li>
          <li class="point"><a href="">
            <i class="fa-brands fa-telegram fa-2x"></i></a></li>
          <!-- <li><a href="https://twitter.com/julesforrest">Twitter</a></li>
          <li><a href="https://codepen.io/julesforrest">Codepen</a></li>
          <li><a href="mailto:julesforrest@gmail.com">Email</a></li>
          <li><a href="https://dribbble.com/julesforrest">Dribbble</a></li>
          <li><a href="https://github.com/julesforrest">Github</a></li> -->
         
        </ul>
      </div>
</footer>
<!-- footer-end -->
 <!-- Modal -->
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
        <div class="modal-header">
        <h1 class="modal-title fs-5" id="exampleModalLabel">Login</h1>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
            <form>
                <div class="mb-3">
                  <label for="exampleInputEmail1" class="form-label">Email address</label>
                  <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                </div>
                <div class="mb-3">
                  <label for="exampleInputPassword1" class="form-label">Password</label>
                  <input type="password" class="form-control" id="exampleInputPassword1">
                </div>
                <div class="mb-3 form-check">
                  <input type="checkbox" class="form-check-input" id="exampleCheck1">
                  <label class="form-check-label" for="exampleCheck1">Check me out</label>
                </div>
                <button type="button" class="btn-1 btn btn-primary btn-lg border-0">Submit</button>
            </form>
        </div>
    </div>
    </div>
</div>