 <div class="winner p-5 text-center">
        <h3 class="text-light fw-bold mb-0">1st price winner amount</h3>
        <h1 class="size text-light fw-bold">1,00,00,000</h1>
        <h3 class="text-light">Draw No:33</h3>
        <h5 class="text-light mb-0">15/08/2024</h5>
        <div class="row grid gap-4 text-center d-flex justify-content-center mt-4">
            <h2 class="circle mb-0">1</h2>
            <h2 class="circle mb-0">2</h2>
            <h2 class="circle mb-0">3</h2>
            <h2 class="circle mb-0">4</h2>
            <h2 class="circle mb-0">5</h2>
            <h2 class="circle mb-0">6</h2>   
        </div>
    </div>
<section>
    <div class="container">
        <div class="py-5">
            <div class="">
                <div class="row">
                    <div class="col-md-3 d-flex justify-content-center">
                        <img src="/assets/img/price-2.png" alt="price.2">
                    </div>
                    <div class="col-md-3 d-flex justify-content-start">
                        <h2 class="mb-0">₹50,00,000</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                        <div class="col">
                            <h5>684273</h5>
                            <h5 class="mb-0">581397</h5>
                       </div>
                       <div class="col">
                           <h5>854397</h5>
                           <h5 class="mb-0">354917</h5>
                      </div>
                      <div class="col">
                           <h5>327598</h5>
                           <h5 class="mb-0">873218</h5>
                      </div>
                    </div>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-3 d-flex justify-content-center">
                        <img src="/assets/img/price-3.png" alt="price.3">

                    </div>
                    <div class="col-md-3 d-flex justify-content-start">
                        <h2 class="mb-0">₹1,00,000</h2>
                    </div>
                    <div class="col-md-6 d-flex justify-content-center">
                        <div class="col">
                            <h5>327598</h5>
                            <h5 class="mb-0">873218</h5>
                       </div>
                       <div class="col">
                            <h5>684273</h5>
                            <h5 class="mb-0">581397</h5>
                      </div>
                      <div class="col">
                            <h5>854397</h5>
                            <h5 class="mb-0">354917</h5>
                      </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</section>


