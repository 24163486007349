<div class="container">
    <h2 class="text-theme-1 text-center fs-1 m-3">Results</h2>
    <div class="row justify-content-between pb-3">
        <form [formGroup]="searchForm" >
        <div class="d-flex  search-wrapper">
            <div class="input-group position-relative">
                <div class="form-outline text-end">
                    <input type="text" id="form1" class="form-control " placeholder="Search" formControlName="searchEl" />
                </div>
                <button type="submit " class="btn position-absolute end-0" (click)="onSearch()" > 
                    <span class="input-group-text border-0" id="basic-addon2"><svg xmlns="http://www.w3.org/2000/svg"
                            width="16" height="25" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                            <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                        </svg></span>
                </button>
            </div>
        </div>
    </form>
        <div class="shadow rounded-5 bg-white p-4">
            <div class=" table-responsive">
                <table class="table table-hover">
                    <thead class="">
                        <tr>
                            <th scope="col">SI.No</th>
                            <th scope="col">Lottery Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Result Draw Date</th>
                            <th scope="col">View</th>
                        </tr>
                    </thead>
                    <tbody class="">
                        <tr *ngFor="let resultData of result | paginate:{itemsPerPage:page?.itemsPerPage,currentPage:page?.page_no,totalItems:page?.total_page };let i=index " >
                            <td>{{page.s_no + i + 1}}</td>
                            <td>{{resultData.ticketDrawName}}</td>
                            <td>

                                <span class="{{resultData.isWin ? 'win' : 'loss'}} win_loss">
                                    {{resultData.isWin? 'win' : 'loss'}}</span>

                            </td>
                            <td>{{ resultData.resultDate | date:'shortDate' }}</td>

                            <td>
                                <a class="viewBox" (click)="openDialog(resultData)">View</a>
                            </td>
                        </tr>
                      
                      <tr *ngIf="result.length == 0" class="text-center"> 
                        <td colspan="12" >-- No Records Found ! --</td>
                      </tr>
                      <div *ngIf="page.total_page >= 5">
                        <pagination-controls
                    (pageChange)="handlePageEvents(page.page_no=$event)"></pagination-controls>
                      </div>

                </table>
            </div>
    </div>
</div>