<section class="kycuser-sec bg-inner-image">
  <div class="container" *ngIf="showSumbContainer==false">
      <h6 class="fw-semibold username mb-5 ">KYC User <span
          *ngIf="data?.status==2">Rejected</span></h6>
      <div class="kyc-grp">
          
          <!-- <div class="marquee text-danger" *ngIf="data?.status==2">
              <marquee  direction="left"> {{'Reason'| translate : translateControl | async}} : {{data?.reason}}</marquee>
          </div> -->
          <mat-horizontal-stepper linear #stepper>

              <mat-step [stepControl]="acInfoForm" [editable]="isEditable">
                  <form [formGroup]="acInfoForm" autocomplete="off">
                      <ng-template matStepLabel>
                          <h6 class="fw-semibold mb-0 mt-1 user-name">Account Information
                              </h6>
                      </ng-template>
                      <div class="row mt-4 ">
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0">
                                  <label for="firstName" class="label-text">First Name
                                     <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input maxlength="30" class="mt-3" (keypress)="stringValidation($event)" matInput
                                          placeholder="enter first name" formControlName="first_name"
                                          [readonly]="kyc_verify==2 || kyc_verify==1">
                                      <mat-error *ngIf="acInfoForm.get('first_name').hasError('required')">First
                                          name Required</mat-error>
                                      <mat-error *ngIf="acInfoForm.get('first_name').hasError('minlength')"> First
                                          name should be atleast 3 characters long!
                                          </mat-error>
                                      <mat-error *ngIf="acInfoForm.get('first_name').hasError('maxlength')"> First
                                          name can be atmax 30 characters long!
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0">
                                  <label for="lastname" class="label-text">Last Name
                                     <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input maxlength="30" class="mt-3" (keypress)="stringValidation($event)" matInput
                                          placeholder="enter last name" formControlName="last_name"
                                          [readonly]="kyc_verify==2 || kyc_verify==1">
                                      <mat-error *ngIf="acInfoForm.get('last_name').hasError('required')">Last name
                                          Required</mat-error>
                                      <mat-error *ngIf="acInfoForm.get('last_name').hasError('minlength')"> Last
                                          name should be atleast 3 characters long!
                                          </mat-error>
                                      <mat-error *ngIf="acInfoForm.get('last_name').hasError('maxlength')"> Last
                                          name can be atmax 30 characters long!
                                         </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0 mt-3">
                                  <label for="date" class="label-text">Date of Birth
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input readonly formControlName="date" class="mt-3" matInput placeholder="MM/DD/YYYY"
                                          (focus)="dob.open()" [matDatepicker]="dob" [min]="minDate" [max]="maxDate"
                                          [disabled]="kyc_verify==2 || kyc_verify==1">
                                      <mat-datepicker-toggle matIconSuffix [for]="dob"
                                          (focus)="dob?.open()"></mat-datepicker-toggle>
                                      <mat-datepicker #dob></mat-datepicker>
                                      <mat-error>Date of birth is Required
                                         </mat-error>
                                  </mat-form-field>

                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0 mt-3">
                                  <label for="city" class="label-text">City
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input matInput placeholder="enter city" class="mt-3" type="text" maxlength="50"
                                          formControlName="city" (keypress)="validation($event)"
                                          [readonly]="kyc_verify==2 || kyc_verify==1">
                                      <mat-error *ngIf="acInfoForm.get('city').hasError('required')">City name
                                          Required</mat-error>
                                      <mat-error *ngIf="acInfoForm.get('city').hasError('minlength')"> City name
                                          should be atleast 3 characters long!
                                          </mat-error>
                                      <mat-error *ngIf="acInfoForm.get('city').hasError('maxlength')"> City name
                                          can be atmax 50 characters long!
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0 mt-3">
                                  <label for="country" class="label-text">Country
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>

                                      <mat-select (valueChange)="dialCode($event)" class="mt-3" formControlName="country"
                                          type="text" name="country" placeholder="select country">
                                          <mat-option *ngFor="let data of country" [value]="data?.name"
                                              [disabled]="kyc_verify==2 || kyc_verify==1"><img class="me-2"
                                                  src="{{data?.currency_image}}" width="24">{{data?.name}}
                                          </mat-option>
                                      </mat-select>
                                      <mat-error>Country Required
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0 mt-3">
                                  <label for="phoneNumber" class="label-text">Phone Number
                                     <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input (keypress)="numberValidation($event)" class="mt-3" minlength="5" maxlength="15"
                                          matInput placeholder="phone no" formControlName="phoneNumber"
                                          [readonly]="kyc_verify==2 || kyc_verify==1">
                                      <span *ngIf="!dialCodeData" matTextPrefix class="border-end me-2 text-dark"> +00
                                          &nbsp;</span>
                                      <span *ngIf="dialCodeData" matTextPrefix class="border-end me-2 text-dark">
                                          <span><img class="me-2" src={{countriesData?.currency_image}}
                                                  width="24"></span>{{dialCodeData}}&nbsp;&nbsp;</span>
                                      <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('required')">Phone
                                          number Required</mat-error>
                                      <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('minlength')">Phone
                                          number should be atleast 5 numbers long!
                                          </mat-error>
                                      <mat-error *ngIf="acInfoForm.get('phoneNumber').hasError('maxlength')">Phone
                                          number can be atmax 15 numbers long!
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                      <div>
                          <button mat-button type="button" matStepperNext class="button-secondary btn-theme-button mt-4">Next
                              </button>
                      </div>
                  </form>
              </mat-step>

              <mat-step [stepControl]="idCredentialForm" [editable]="isEditable">
                  <form [formGroup]="idCredentialForm" autocomplete="off">
                      <ng-template matStepLabel>
                          <h6 class="fw-semibold mb-0 mt-1 user-name">ID Credentials'
                          </h6>
                      </ng-template>
                      <div class="row mt-4">
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0">
                                  <label for="id_type" class="label-text">ID Type
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <mat-select formControlName="id_type" class="mt-3" type="text" name="id_type"
                                          placeholder="Select ID"
                                          (selectionChange)="idchange($event.value)"
                                          [disabled]="kyc_verify==2 || kyc_verify==1">
                                          <mat-option class="pt-2" *ngFor="let data of idTypeList"
                                              [value]="data?.name">{{data?.name }}
                                              </mat-option>
                                      </mat-select>
                                      <mat-error>ID type required
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0">
                                  <label for="id_number" class="label-text">ID Number
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input type="text" class="mt-3" (keypress)="validateInput($event)" matInput
                                        placeholder="enter id no" formControlName="id_number"
                                        [readonly]="kyc_verify==2 || kyc_verify==1">
                                        
                                      <mat-error *ngIf="idCredentialForm.get('id_number').hasError('required')">ID
                                          number Required</mat-error>
                                      <mat-error *ngIf="idCredentialForm.get('id_number').hasError('minlength')">
                                          ID number should be atleast 5 numbers long!
                                          translateControl </mat-error>
                                      <mat-error *ngIf="idCredentialForm.get('id_number').hasError('maxlength')">
                                          ID number can be atmax 20 numbers long!\
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div class="mb-3 mb-sm-0 mt-3">
                                  <label for="address" class="label-text">Address
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <textarea maxlength="150" class="mt-3" (keypress)="addressValidators($event)" rows="3"
                                          matInput placeholder="enter addr" formControlName="address"
                                          [readonly]="kyc_verify==2 || kyc_verify==1"></textarea>
                                      <mat-error
                                          *ngIf="idCredentialForm.get('address').hasError('required')">Address
                                          Required</mat-error>
                                      <mat-error *ngIf="idCredentialForm.get('address').hasError('minlength')">
                                          Address should be atleast 3 characters long!
                                          </mat-error>
                                      <mat-error *ngIf="idCredentialForm.get('address').hasError('maxlength')">
                                          Address can be atmax 150 characters long!
                                          </mat-error>
                                      <mat-error
                                          *ngIf="idCredentialForm.get('address').hasError('pattern')">Special
                                          characters are not allowed.
                                          </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-lg-6">
                              <div *ngIf="!id_type">
                                  <label for="expiry_date" class="label-text mt-3">Expiry Date
                                      <span class="text-danger ms-1">*</span></label>
                                  <mat-form-field>
                                      <input readonly matInput formControlName="expiry_date" class="mt-3" (focus)="picker.open()"
                                          [min]="today" [disabled]="kyc_verify==2 || kyc_verify==1"
                                          placeholder="MM/DD/YYYY" [matDatepicker]="picker">
                                      <mat-datepicker-toggle matIconSuffix [for]="picker"
                                          (focus)="picker.open()"></mat-datepicker-toggle>
                                      <mat-datepicker #picker></mat-datepicker>
                                      <mat-error>Expiry date is Required
                                      </mat-error>
                                  </mat-form-field>
                              </div>
                          </div>
                      </div>
                      <div class="mt-3">
                          <button mat-button type="button" matStepperNext class="btn-theme-button border-0 ms-2  d-flex">Next
                              </button>
                          <button mat-button matStepperPrevious type="button" class="button-secondary d-flex">Back
                              </button>
                      </div>
                  </form>
              </mat-step>
              <!-- <ng-container *ngIf="showSumbContainer">
                  
              </ng-container> -->

               <mat-step [stepControl]="uploadDocForm" [editable]="isEditable">
                  <form [formGroup]="uploadDocForm" autocomplete="off">

                      <ng-template matStepLabel>
                          <h6 class="fw-semibold mb-0 mt-1 user-name">ID Document
                             </h6>
                      </ng-template>

                      <div class="row mt-4">
                           <div class="col-lg-6">
                              <label for="id_front_image"><h5 class="gradient-text-color fw-semibold">Document Front Image</h5></label>
                              <input *ngIf="kyc_verify==0 || kyc_verify==3" (change)="uploadImg($event,1)" type="file" placeholder="id_front_image" required>
                              <mat-error *ngIf="submitted && f['id_front_image'].errors?.['required']">Front image Required</mat-error>
                              <div>
                                  <img *ngIf="image?.front" class="front_image img-fluid mt-3" [src]="base_url+image?.front">
                              </div>
                          </div>
                          <div class="col-lg-6 pt-4 pt-lg-0">
                              <label for="id_back_image"><h5 class="gradient-text-color fw-semibold">Document Back Image</h5></label>
                              <input *ngIf="kyc_verify == 0 || kyc_verify == 3"  (change)="uploadImg($event,2)" type="file" placeholder="id_back_image" required>
                              <mat-error *ngIf="submitted && f['id_back_image'].errors?.['required']">Back image Required</mat-error>
                              <div>
                                  <img *ngIf="image?.back" class="back_image img-fluid mt-3" [src]="base_url+image?.back">
                              </div>
                          </div>
                          <!-- <h5 class="mb-2 mt-4">Selfie with ID :</h5>
                          <div class="col-lg-6"  *ngIf="!selfieImg">
                              <webcam [height]="300" [width]="300" [trigger]="triggerObservable" (imageCapture)="handleImage($event)" 
                                  (initError)="handleInitError($event)"
                              ></webcam>
                          <br/>
                          <button class="button-primary text-dark mb-3" (click)="triggerSnapshot();">Take A Snapshot</button>
                          </div> -->
                          <!-- <div class="col-lg-6">
                              <img *ngIf="webcamImage?.imageAsDataUrl" class="img-fluid" [src]="webcamImage?.imageAsDataUrl"/>
                              <img class="img-fluid" *ngIf="selfieImg" [src]="selfieImg">
                          </div> -->
                      </div>
                      <div class="mt-2">
                          <button mat-button type="button" class="button-secondary ms-2  d-flex" (click)="submit()" *ngIf="kyc_verify == '0' || kyc_verify == '3'">
                              <i class="loading-icon fa me-2" [ngClass]="{'fa-spin fa-spinner': isLoading, '': !isLoading}"></i>{{button}}</button>
                          <button mat-button matStepperPrevious type="button" class="button-secondary d-flex">Back</button>
                      </div>
                  </form>
              </mat-step> 
          </mat-horizontal-stepper>
      </div>

  </div>
  <div class="container" id="sumsub-websdk-container" *ngIf="showSumbContainer==true"></div>
</section>