
import { Injectable } from '@angular/core';
import { io } from 'socket.io-client';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class SocketServiceService {
  // public socket: any;
  // token :any
  // status :any
  // constructor() {
  //   if(this.socket?.connected!==true){
  //     this.socket = io(environment?.socket_url)
  //     this.initialEventConnect()
  //   }
  // }
  // initialEventConnect(){
  //   this.token = localStorage.getItem('access_token')
  //   if(this.token){
  //     let socketData ={
  //       "params" :'connectSocket',
  //       "_token" : this.token
  //     }
  //     this.sendMessage(socketData)
  //   }
  // }
  // public sendMessage(message: any) {
  //   this.socket.emit(message.params, message);
  // }

  // public getMessages = () => {
  //   return Observable.create((observer: any) => {
  //     this.socket.on('receive', (message: any) => {
  //       observer?.next(message);
  //     })
  //     return () => {
  //       this.socket.disconnect();
  //     }
  //   });
  // }
  // public receiveMessage = (event:string) => {
  //   return Observable.create((observer:any) => {
  //       this.socket.on(event, (message:any) => {
  //           observer.next(message);
  //       })
  //       return () => {
  //         this.socket.disconnect();
  //       }
  //   });
  // }
  // public eventComplete(event:string){
  //   this.socket.off(event)
  // }

  
}
