import { Injectable } from '@angular/core';
import { CanActivate, CanActivateFn } from '@angular/router';
import { ActivatedRouteSnapshot,RouterStateSnapshot,UrlTree,Router } from '@angular/router';
import { UserService } from './services/user.service';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class authGuard implements CanActivate {
  constructor(private router: Router, private callApi: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = localStorage.getItem('access_token')
    if (!token) {
      this.callApi.getToken();
      this.callApi.showError('Please Login First !');
      this.router.navigate(['/login']);
    } 
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})
export class VerifiedauthGuard implements CanActivate {
  constructor(private router: Router, private callApi: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let token = localStorage.getItem('access_token')
    if (token) {
      this.callApi.getToken();
      // this.callApi.showError('Please Login First !');
      this.router.navigate(['/welcome']);
    } 
    return true;
  }
}

@Injectable({
  providedIn: 'root'
})

export class kycCheck implements CanActivate {
  kyc: any;
  kyStatus: boolean = false;
  constructor(private router: Router, private callApi: UserService) { }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    this.callApi.getProfile().subscribe((res: any) => {
      // this.callApi.stopSpinner();
      this.kyc = res?.data?.userDetails[0]
      if (this.kyc?.kyc_verify == 2) {
        this.kyStatus = true
      }
      if (this.kyc?.kyc_verify == 0) {        
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/welcome'])
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Please submit Your Kyc!',
          footer: `
              <html>
              <style>
              .swal2-footer {
                border-top: 0;
                margin-top: 0;
              }
              </style>
              <a href="kyc-sub" class="text-props fs-13">Submit your Kyc</a>
              </html>`
        })

      }
      else if (this.kyc?.kyc_verify == 1) {
        this.kyStatus = false
        this.callApi.stopSpinner();
        this.router.navigate(['/welcome'])
        Swal.fire({
          icon: 'error',
          title: 'Please wait!',
          text: 'Your KYC is waiting for admin approval.',
          footer: `
              <html>
              <style>
              .swal2-footer {
                border-top: 0;
                margin-top: 0;
              }
              </style>
              <a href="kyc-sub" class="text-props fs-13">View Submitted KYC Details</a>
              </html>`,
        })
      }
      else if (this.kyc?.kyc_verify == 3) {
        this.callApi.stopSpinner();
        this.router.navigate(['/welcome'])
        Swal.fire({
          icon: 'error',
          title: 'KYC Rejected!',
          text: 'Your KYC has been Rejected. Please contact our helpdesk.',
          footer: `
              <html>
              <style>
              .swal2-footer {
                border-top: 0;
                margin-top: 0;
              }
              </style>
              <a href="kyc-sub" class="text-props fs-13">Resubmit Kyc?</a>
              </html>`,
        })
      }

    }, err => {
      this.callApi.stopSpinner();

      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Some error occurred. Please try again later! For more info contact our helpdesk!',
      }).then(() => {
        this.router.navigate(['/welcome'])
      })
    })
    return true;
  }
}


