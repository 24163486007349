export const environment = {
    base_url:'https://lottery.breedcoins.com/base/',
    socket_url:'wss://lottery.breedcoins.com/',
    IMAGE_URL : "https://firebee.sgp1.digitaloceanspaces.com/",
    encrypt_decrypt_key:'walletex@123488&&*',
    paypal_client_id :'AUv8rrc_P-EbP2E0mpb49BV7rFt3Usr-vdUZO8VGOnjRehGHBXkSzchr37SYF2GNdQFYSp72jh5QUhzG', // Paypal sandbox ID
    paypal_client_secret : 'EM3owQrQWpQgCFpxOAEmz7Tw7xHRrYpMdWYvUH1MmkcZQjCALg2PTuBUoiNFG6V5_cB1bMEiQza3Q7-9', // Paypal sandbox SECRET
    firebase: {
      apiKey: "AIzaSyD7uhfej3R538UihHAhDEhrV3fsB962Xig",
      authDomain: "seedsofcryptov1.firebaseapp.com",
      projectId: "seedsofcryptov1",
      storageBucket: "seedsofcryptov1.appspot.com",
      messagingSenderId: "26741325958",
      appId: "1:26741325958:web:13c7bf683c5a05f6e19107",
      measurementId: "G-JZM36CJ34G",
    },
    PAGE_SIZE :5,
};
